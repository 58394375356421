/* Style of blog */
.blog {
  header {
    .AU_animation1 {
      transform: translateX(-650px);
    }
    .AU_animation2 {
      transition: all 2s ease-in-out;
      opacity: 0;
    }
    position: relative;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 82px 0 0 0;
      width: 1700px;
      margin: 0 auto;
      position: relative;
      z-index: 100;
      .text,
      a {
        transition: 0.5s ease-in-out all;

        @media (max-width: 667px) {
          img {
            max-width: 125px;
          }
        }
      }
      @media (max-width: 1700px) {
        width: 95%;
      }
      @media (max-width: 1300px) {
        width: 95%;
        padding-top: 45px;
      }
      @media (max-width: 900px) {
        width: 90%;
        padding: 30px 0 30px 0;
      }
      @media (max-width: 768px) {
        .line_one {
          width: 450px;
        }
      }
      @media (max-width: 720px) {
        width: 90%;
      }
      @media (max-width: 667px) {
        width: 100%;
      }
      @media (max-width: 500px) {
        display: block;
      }
      .left {
        color: #fff;
        font-weight: bold;
        height: 50px;
        cursor: pointer;
        @media (max-width: 992px) {
          padding-left: 15px;
        }
        span {
          font-weight: 200;
          display: block;
        }
      }
      .right {
        color: #fff;
        font-weight: bold;
        cursor: pointer;
        @media (max-width: 500px) {
          margin-top: 20px;
        }
      }
    }
    .header-container {
      width: 1240px;
      margin: 90px auto 0 auto;
      @media (max-width: 1450px) {
        width: 1000px;
      }
      @media (max-width: 1240px) {
        width: 85%;
      }
      @media (max-width: 1050px) {
        margin-top: 50px;
      }
      @media (max-width: 900px) {
        width: 90%;
      }

      @media (max-width: 950px) {
        & {
          margin: 0 auto;
          text-align: center;
          width: 95%;
          padding-left: 0px;
        }
      }
      .service {
        color: $headline;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        font: {
          style: normal;
          weight: 600;
          size: 14px;
        }
        line-height: 18px;
      }
      .text {
        width: 638px;
        @media (max-width: 900px) {
          width: 90%;
          margin: 15px auto 0 auto;
        }
        h1 {
          margin: {
            top: 15px;
          }
          color: #fff;
          font: {
            style: normal;
            weight: 600;
            size: 52px;
          }
          line-height: 59px;
        }
      }
      @media (max-width: 1440px) {
        .text h1 {
          font: {
            size: 32px;
          }
          line-height: 39px;
        }
      }
      @media (max-width: 950px) {
      }
    }
    .header-container_2 {
      margin: {
        top: 92px;
        left: auto;
        right: auto;
      }
      width: 1240px;
      @media (max-width: 1450px) {
        width: 1000px;
      }
      @media (max-width: 1240px) {
        width: 85%;
      }
      @media (max-width: 900px) {
        width: 90%;
      }
      .grid {
        display: flex;
        @media (max-width: 720px) {
          display: block;
        }
        .header_box {
          width: 390px;
          margin-right: 250px;
          margin-bottom: 49px;
          @media (max-width: 1050px) {
            margin-right: 80px;
          }
          @media (max-width: 720px) {
            margin: 60px auto 20px auto;
            width: 90%;
          }
          h3 {
            margin: {
              bottom: 25px;
            }
            width: 275px;
            color: #fff;
            font: {
              style: normal;
              weight: 600;
              size: 30px;
            }
            line-height: 38px;
            @media (max-width: 720px) {
              margin-bottom: 15px;
              width: 100%;
            }
          }
          p {
            @media (max-width: 900px) {
              width: 95%;
              margin: 0 auto;
            }
            color: #d1d5db;
            font: {
              style: normal;
              weight: 400;
              size: 16px;
            }
            line-height: 29px;
          }
        }
      }
      @media (max-width: 720px) {
        text-align: center;
      }
    }
    svg.header_line {
      position: absolute;
      top: 350px;
      right: 0;
      z-index: 1;
      .line-path {
        stroke-dasharray: 2500px;
        stroke-dashoffset: 2500px;
      }
      @media (max-width: 1100px) {
        width: 350px;
        right: -150px;
        top: 150px;
      }
      @media (max-width: 667px) {
        right: -120px;
        width: 230px;
        top: 50px;
      }
    }
    svg.header_line--services {
      right: -250px;
      bottom: -750px;
      transform: rotate(-35deg);
    }
  } /* ./header */

  .hero--sub {
    padding: 193px 0 140px 0;
    text-align: left;
    position: relative;
    z-index: 1000;
    height: 695px;
    margin-top: -135px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @media (max-width: 667px) {
      padding: 145px 20px 116px 20px;
      margin-top: -90px;
      text-align: center;
      height: auto;
      background-image: url("../../images/transparex/transparex-bg-mobile.png") !important;
    }

    &__text {
      transform: translate(0, 500px);
      opacity: 0;
      transition: all 1.5s ease-in-out;
    }

    .subtitle {
      color: #fff;
      text-transform: uppercase;
      font-size: 14px;
      letter-spacing: 0.2em;
      margin-bottom: 17px;
      font-weight: 500;

      @media (max-width: 667px) {
        display: none;
      }
    }

    h1 {
      color: #fff;
      font-weight: 500;
      font-size: 52px;
      margin-bottom: 15px;
      max-width: 730px;
      @media (max-width: 667px) {
        font-size: 24px;
        margin-bottom: 10px;
        font-weight: 400;
      }
    }

    p {
      color: #fff;
      font-size: 20px;
      font-weight: 300;
      margin-bottom: 35px;
      max-width: 880px;
      margin-left: auto;
      margin-right: auto;

      @media (max-width: 992px) {
        max-width: 600px;
      }

      @media (max-width: 667px) {
        font-size: 14px;
        max-width: 290px;
      }
    }

    strong {
      font-weight: 700;
      color: #fff;
      display: block;
    }
  } /* ./hero--sub */

  .hero--services {
    padding-top: 80px;
    padding-bottom: 304px;

    @media (max-width: 667px) {
      padding-top: 50px;
      padding-bottom: 220px;
      h1 {
        margin-bottom: 20px;
      }
    }
  }

  .hero-subtitle {
    color: #e5375d;
    margin-bottom: 25px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.2em;
    font-size: 14px;

    @media (max-width: 667px) {
      font-size: 10px;
      margin-bottom: 9px;
    }
  }

  .content {
    background: #fff;
    padding: 70px 0 0 0;
    margin-bottom: 0;

    @media (max-width: 667px) {
      padding: 50px 0 0 0;
    }
  } /* content */

  .block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 110px;

    @media (max-width: 667px) {
      flex-wrap: wrap;
      margin-bottom: 30px;
    }

    &:nth-child(1) {
      .block__text {
        @media (max-width: 667px) {
          order: 2;
        }
      }
    }

    &:nth-child(2) {
      .block__text {
        margin-left: 50px;
        margin-right: 0;

        @media (max-width: 667px) {
          margin-left: 0;
        }
      }
    }

    &:nth-child(3) {
      margin-bottom: -20px;
      .block__text {
        @media (max-width: 667px) {
          order: 2;
        }
      }
    }

    &__text {
      max-width: 424px;
      margin-right: 50px;

      @media (max-width: 667px) {
        margin-right: 0;
        max-width: 100%;
      }
    }

    .text-toptitle {
      color: #e5375d;
      font-weight: 700;
      font-size: 14px;
      margin-bottom: 14px;
      text-transform: uppercase;
      letter-spacing: 0.2em;

      @media (max-width: 667px) {
        font-size: 12px;
        margin-bottom: 0;
      }
    }

    h2 {
      margin-top: 15px;
      font-size: 43px;
      margin-bottom: 15px;
      font-weight: 500;

      @media (max-width: 667px) {
        margin-top: 5px;
        font-size: 24px;
      }
    }

    p {
      color: #828282;
      font-size: 20px;
      line-height: 1.75;
      margin-bottom: 35px;

      @media (max-width: 667px) {
        font-size: 14px;
        margin-bottom: 25px;
      }

      strong {
        color: #828282;
      }
    }

    img {
      display: block;
      max-width: 100%;
      height: auto;

      @media (max-width: 667px) {
        margin-bottom: 25px;
      }
    }
  } /* /.block */

  .block--boxes {
    display: flex;
    justify-content: space-between;
    margin-bottom: 160px;

    @media (max-width: 667px) {
      display: block;
      margin-bottom: 70px;
    }

    .service-box {
      background: #f9f9f9;
      flex: 0 0 31.55%;
      padding: 40px 35px;
      min-height: 290px;
      transition: all 1s ease-in-out;

      // animations
      transform: translate(0, 50px);
      opacity: 0;

      @media (max-width: 667px) {
        min-height: 260px;
        margin-bottom: 15px;
        padding-right: 26px;
      }

      &__head {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 20px;

        h3 {
          margin: 0;
          font-size: 16px;
          font-weight: 700;
        }

        img {
          margin-right: 20px;
          max-width: 30px;

          @media (max-width: 667px) {
            margin-right: 15px;
          }
        }
      }

      p {
        font-size: 14px;
        color: #000;
        opacity: 0.6;
        margin-bottom: 0;
        font-weight: 500;
      }
    }
  }

  .portfolio-holder {
    position: relative;
    overflow: hidden;
  }

  .portfolio-slider-wrapper {
    padding-top: 58px;
    // display: flex;

    &:after {
      content: "";
      position: absolute;
      width: 500px;
      height: 100%;
      right: 0;
      top: 0;
      background: linear-gradient(270deg, #000807 0%, rgba(0, 8, 7, 0) 100%);
      z-index: 0;

      @media (max-width: 1440px) {
        width: 400px;
      }

      @media (max-width: 667px) {
        display: none;
      }

      @media (min-width: 2500px) {
        width: 950px;
      }
    }

    @media (max-width: 667px) {
      padding-top: 40px;
    }

    .portfolio-item {
      margin-right: 32px;
      display: block;

      @media (max-width: 667px) {
        margin-right: 10px;
        width: 90%;
      }

      &:hover {
        img {
          transform: scale(1.05);
        }
      }

      &:not(.is-selected) {
        h3,
        p,
        .hashtags {
          opacity: 0.5;
        }
      }

      .img-wrapper {
        overflow: hidden;
        margin-bottom: 40px;

        @media (max-width: 667px) {
          margin-bottom: 20px;
        }
      }

      img {
        max-width: 710px;
        display: block;
        height: auto;
        transition: all 0.8s ease-in-out;

        @media (max-width: 667px) {
          max-width: 100%;
        }
      }

      h3 {
        font-weight: 400;
        font-size: 30px;
        margin-bottom: 5px;
        color: #fff;
        line-height: 1;
        transition: all 0.6s ease-in-out;

        @media (max-width: 667px) {
          font-size: 18px;
        }
      }

      p {
        color: #fff;
        font-size: 14px;
        transition: all 0.6s ease-in-out;

        @media (max-width: 667px) {
          font-size: 12px;
        }
      }

      .hashtags {
        margin-top: 20px;
        transition: all 0.6s ease-in-out;

        @media (max-width: 667px) {
          margin-top: 5px;
        }
        span {
          display: inline-block;
          margin-right: 8px;
          font-weight: 600;
          font-size: 14px;
          color: #f3385a;
          @media (max-width: 667px) {
            font-size: 12px;
            font-weight: 400;
          }
        }
      }
    }

    .flickity-viewport {
      overflow: visible;
    }

    &.flickity-enabled {
      position: static;
    }
  }

  .portfolios {
    background: #000;
    padding: 110px 0;
    position: relative;

    @media (max-width: 667px) {
      padding: 47px 0 70px 0;
    }

    &--upper {
      @media (max-width: 667px) {
        padding-top: 300px;
      }
    }

    .portfolio-head {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 667px) {
        justify-content: center;
        text-align: center;
      }

      .toptitle {
        font-size: 14px;
        text-transform: uppercase;
        color: #e5375d;
        font-weight: 700;
        letter-spacing: 0.2em;
        margin-bottom: 15px;

        @media (max-width: 667px) {
          font-size: 10px;
          margin-bottom: 0;
        }
      }

      h2 {
        color: #fff;
        font-size: 46px;
        max-width: 260px;
        line-height: 1.15;

        @media (max-width: 667px) {
          font-size: 24px;
          max-width: 100%;
        }
      }

      .head-action {
        padding-top: 55px;

        @media (max-width: 667px) {
          display: none;
        }
      }
    }

    .action-mobile {
      display: none;
    }

    @media (max-width: 667px) {
      .action-mobile {
        margin-top: 23px;
        text-align: center;

        .btn--primary {
          height: 55px;
        }
      }
    }
  }

  .block--intro {
    transition: all 0.85s ease-in-out;

    max-width: 800px;
    margin: 0 auto 100px auto;

    // animation
    transform: translate3d(-150px, 0, 0);
    opacity: 0;

    @media (max-width: 667px) {
      margin-bottom: 40px;
    }

    h2 {
      font-size: 36px;
      font-weight: 500;
      margin-bottom: 25px;

      @media (max-width: 667px) {
        display: none;
      }
    }

    p {
      font-size: 18px;
      max-width: 800px;
      line-height: 1.85;

      @media (max-width: 667px) {
        font-size: 14px;
      }
    }
  }

  .kontakt {
    margin-top: 60px;

    .AU_animation12 {
      transform: translateX(-580px);
    }
    .AU_animation13 {
      transform: translateX(800px);
    }
    width: 1240px;
    height: 386px;
    margin: 0 auto;
    background: #f3385a;
    border-radius: 0;
    margin-bottom: 127px;
    display: flex;
    @media (max-width: 1440px) {
      width: 85%;
    }
    @media (max-width: 900px) {
      width: 80%;
      height: auto;
      display: grid;
    }
    @media (max-width: 667px) {
      width: 90%;
      margin-bottom: 61px;
    }
    .left {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 0;
      @media (max-width: 1100px) {
        width: 45%;
      }
      @media (max-width: 900px) {
        width: 100%;
        text-align: center;
        margin-top: 30px;
      }
      @media (max-width: 330px) {
        width: 280px;
      }
      div {
        width: 100%;
        padding: 0 50px 0 100px;

        @media (max-width: 667px) {
          padding: 0;
        }

        h2 {
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 23px;
          letter-spacing: 0.2em;
          text-transform: uppercase;
          color: #000;

          @media (max-width: 1024px) { 
            font-size: 20px;
          }

          @media (max-width: 667px) {
            font-size: 12px;
          }
        }
        h3 {
          margin-top: 20px;
          width: 100%;
          font-style: normal;
          font-weight: 700;
          font-size: 36px;
          line-height: 40px;
          color: #fff;

          @media (max-width: 1024px) { 
            font-size: 24px;
            line-height: 1.5;
          }
          @media (max-width: 500px) {
            font-size: 32px;
            line-height: 35px;
          }
          @media (max-width: 667px) {
            font-size: 18px;
            font-weight: 400;
            line-height: 1.25;
            margin-top: 0;
          }
        }
        p {
          margin-top: 35px;
          color: #fff;
          font-size: 36px;
          line-height: 1.1;
          @media (max-width: 1024px) { 
            font-size: 24px;
            line-height: 1.5;
            margin-top: 20px;
          }
          @media (max-width: 667px) {
            font-size: 18px;
            font-weight: 400;
            line-height: 1.25;
            margin-top: 25px;
          }
        }
      }
    }
    .right {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 1100px) {
        width: 55%;
      }
      @media (max-width: 900px) {
        width: 100%;
        padding: 20px 0 20px 0;
      }
      @media (max-width: 667px) {
        width: 100%;
        padding: 15px 15px 50px 15px;
      }
      form {
        width: 80%;
        @media (max-width: 1100px) {
          width: 90%;
        }
        @media (max-width: 900px) {
          width: 346px;
        }
        @media (max-width: 500px) {
          width: 300px;
        }
        @media (max-width: 330px) {
          width: 100%;
        }
        input,
        textarea {
          width: 344px;
          background: #ffffff;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
          border-radius: 3px;
          padding: 9px 13px;
          margin-bottom: 14px;
          border: none;
          outline: none;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #6b7280;
          @media (max-width: 500px) {
            width: 100%;
          }
        }
        input {
          height: 50px;
        }
        textarea {
          height: 132px;
        }
        .checkbox {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          input {
            width: 21px;
            height: 21px;
            background: #ffffff;
            border-radius: 3px;
            border: none;
            box-shadow: none;
            margin-bottom: 0;
            margin-right: 10px;
          }
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #ffffff;
          a {
            margin-left: 5px;
            color: #ffffff;
            text-decoration: underline;
          }
          @media (max-width: 800px) {
            display: block;
          }
        }
        a.Mybtn {
          rect {
            stroke-dasharray: 669px;
            stroke-dashoffset: 669px;
          }
          position: relative;
          display: block;
          overflow: hidden;
          @include size(100%, 50px);
          max-width: 129px;
          border-radius: 30px;
          span {
            @include absolute();
            display: block;
            @include size(0);
            border-radius: 50%;
            background-color: #fff;
            transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
            transform: translate(-50%, -50%);
          }

          &:hover {
            span {
              @include size(225%, 298px * 2.25);
            }
            .text {
              color: #f3385a;
            }
          }
          .text {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 34px;
            color: transparent;
            animation-duration: 2s;
            animation-fill-mode: forwards;
            transition: 2s color;
          }
        }
        @media (max-width: 950px) {
          a.Mybtn {
            margin: 0 auto;
            .text {
              font-size: 14px;
            }
            &:hover {
              span {
                @include size(0%, 129px * 2.25);
              }
              .text {
                color: #fff;
              }
            }
          }
        }
      }
    }
  } /* ./kontakt */

  footer {
    padding-bottom: 50px;
    .info {
      background: linear-gradient(to right, #f1133f 0%, #ffa5b7 100%);
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      margin-bottom: 81px;
      height: 150px;
      img {
        width: 100%;
        opacity: 0.19;
      }
      @media (max-width: 950px) {
        margin-bottom: 10px;
      }
      .columns {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        @media (max-width: 950px) {
          text-align: center;
          .is-2 {
            padding: 0;
          }
          .is-7 {
            height: 100%;
            margin: 0 auto;
          }
        }
        .all {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          @media (max-width: 950px) {
            .animation_13 {
              display: grid;
            }
          }
          a {
            font-style: normal;
            font-weight: 300;
            font-size: 56px;
            line-height: 59px;
            color: white;
            opacity: 0.9;
            transition: all 0.5s ease-in-out;
            &:hover {
              opacity:1;
            }
            @media (max-width: 1800px) {
              font-size: 45px;
            }
            @media (max-width: 1280px) {
              font-size: 39px;
            }
            @media (max-width: 1050px) {
              font-size: 32px;
            }
            @media (max-width: 950px) {
              font-weight: 100;
            }
            @media (max-width: 667px) {
              font-weight: 400;
            }
          }
          a.email {
            margin-right: 60px;
            @media (max-width: 1280px) {
              margin-right: 25px;
            }
            @media (max-width: 950px) {
              margin-right: 0px;
            }
          }
        }
      }
      .black_wall {
        background-color: #000807;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
        margin: auto;
        height: 100%;
        transform: translate(0%);
        transition: all 1s ease-in-out;
      }
      .black_wall:after {
        content: "";
        position: absolute;
        left: -179px;
        border-right: 180px solid #000807;
        border-top: 192px solid transparent;
      }
    }
    .end {
      opacity: 0;
      transition: all 0.5s ease-in-out;
      @media (max-width: 950px) {
        text-align: center;
      }
      .center_up {
        color: #9ca3af;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
      }
      .center_down {
        margin-top: 11px;
        color: #9ca3af;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
      }
      .social {
        display: flex;
        align-items: center;
        height: 20px;
        margin-top: 27px;
        a {
          position: relative;
          height: 100%;
          margin-right: 8px;
          //border-image-slice: 1;
          //border-bottom: 1px solid;
          //border-width: 1px;
          //border-image-source: linear-gradient(277.48deg, $bg-color 8.64%, $bg-color 56.22%, $bg-color 97.03%);
          transition: all 0.25s ease-in-out;
          &:before {
            content: "";
            width: 0;
            height: 3px;
            position: absolute;
            bottom: -10px;
            left: 0;
            background: linear-gradient(
              277.48deg,
              #0fa6e1 8.64%,
              #f3385a 56.22%,
              #45187a 97.03%
            );
            transition: all 0.35s ease-in-out;
          }
          &:hover {
            transform: translate(0, -5px);
            &:before {
              width: 100%;
            }
          }
        }
        @media (max-width: 950px) {
          justify-content: center;
          margin-top: 10px;
        }
      }
      .links {
        display: grid;
        float: right;
        a {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 5px;
          color: #ffffff;
          border-image-slice: 1;
          border-bottom: 2px solid;
          border-width: 2px;
          border-image-source: linear-gradient(
            277.48deg,
            $bg-color 8.64%,
            $bg-color 56.22%,
            $bg-color 97.03%
          );
          &:hover {
            transform: translateY(0px);
            transition: all 0.2s ease-in-out;
            border-image-source: linear-gradient(
              277.48deg,
              #0fa6e1 8.64%,
              #f3385a 56.22%,
              #45187a 97.03%
            );
            @media (max-width: 950px) {
              transform: translateY(0px);
              border-image-source: linear-gradient(
                277.48deg,
                $bg-color 8.64%,
                $bg-color 56.22%,
                $bg-color 97.03%
              );
            }
          }
        }
        @media (max-width: 950px) {
          float: none;
        }
      }
      .copy {
        margin-top: 60px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
      }
    }
  } /* ./footer */

  .back-link {
    color: #fff;
    font-size: 14px;
    display: flex;
    align-items: center;
    font-weight: 500;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
    margin-bottom: 78px;
    letter-spacing: 0.2em;

    // animations
    opacity: 0;

    @media (max-width: 667px) {
      margin-bottom: 15px;
      font-size: 10px;
      justify-content: center;
    }

    svg {
      margin-right: 20px;
      position: relative;
      top: -1px;
      path {
        transition: all 0.35s ease-in-out;
      }

      @media (max-width: 667px) {
        margin-right: 8px;
      }
    }

    &:hover {
      color: #f3385a;

      svg path {
        stroke: #f3385a;
      }
    }
  }

  .hashtags {
    display: flex;
    color: #fff;
    flex-wrap: wrap;

    @media (max-width: 667px) {
      justify-content: center;
      margin-top: 25px;
    }

    li {
      margin-right: 19px;
      font-size: 12px;
      text-transform: uppercase;
      padding-top: 15px;
      letter-spacing: 0.2em;
      font-weight: 600;

      @media (max-width: 667px) {
        font-size: 9px;
        margin-right: 8px;
        padding-top: 5px;
      }
    }
  }

  .block--cover-image {
    margin-bottom: 127px;
    position: relative;

    @media (max-width: 667px) {
      padding: 40px 0;
      background: #4fa0f1;
      margin-bottom: 40px;
    }

    .overlayer {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: #fff;
      z-index: 1000;
      transition: all 1s linear;
    }

    img {
      height: auto;
      max-width: 1920px;
      margin: 0 auto;
      width: 100vw;

      @media (min-width: 1921px) {
        width: 100%;
        max-width: 100%;
      }

      @media (max-width: 667px) {
        object-fit: cover;
        height: 245px;
      }
    }
  }

  .block--logo {
    margin: 0 auto;
    text-align: left;
    margin-bottom: 142px;

    @media (max-width: 667px) {
      margin-bottom: 56px;
    }

    .block-top {
      max-width: 450px;
      margin: 0 auto;
      transition: all 0.65s linear;

      // animations
      opacity: 0;
      transform: translate(0, 100px);
    }

    .block-title {
      font-weight: 500;
      @media (max-width: 667px) {
        font-size: 15px;
        font-weight: 600;
      }
    }

    .block-image {
      display: block;
      margin: 142px auto 124px auto;
      transition: all 0.85s linear;

      // animations
      opacity: 0;
      transform: translate(0, 100px);

      @media (max-width: 667px) {
        margin: 60px auto;
      }
    }

    .block-text {
      line-height: 1.85;
      @media (max-width: 667px) {
        font-size: 14px;
      }
    }
  }

  .block--image-pack {
    margin-bottom: 50px;
    position: relative;

    @media (max-width: 667px) {
      margin-bottom: 35px;
    }

    .overlayer {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1000;
      background: #efefef;
      transition: all 1s linear;
    }

    img {
      display: block;
      margin: 0 auto;
      max-width: 100%;

      @media (max-width: 667px) {
        height: 245px;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .block--screens {
    overflow: hidden;
    margin-top: 250px;

    @media (max-width: 667px) {
      margin-top: 150px;
    }

    img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
      width: 100%;
      transition: all 1s ease-in-out;

      // animation
      opacity: 0;
      transform: scale(3);

      @media (max-width: 667px) {
        height: 225px;
        object-fit: cover;
      }
    }
  }

  .block--previews {
    background: #2d323b;
    padding: 230px 0 0 0;
    overflow: hidden;
    position: relative;

    @media (max-width: 667px) {
      padding: 70px 15px;
    }

    .preview-top {
      margin: 0 auto;
      max-width: 920px;
      color: #fff;
      text-align: center;
      transition: all 1s ease-in-out;

      // animation
      opacity: 0;
      transform: translate(0, 100px);

      .top-title {
        color: #fff;
        margin-bottom: 30px;
        font-weight: 400;
        font-size: 20px;

        @media (max-width: 667px) {
          font-size: 12px;
          margin-bottom: 15px;
        }
      }

      .top-text {
        color: #fff;
        font-weight: 200;
        font-size: 24px;
        line-height: 1.75;
        margin-bottom: 30px;

        @media (max-width: 667px) {
          font-size: 14px;
        }
      }

      .name {
        color: #f3385a;
        font-size: 30px;
        font-weight: 200;

        @media (max-width: 667px) {
          font-size: 14px;
        }
      }

      .position {
        color: #fff;
        font-size: 18px;
        font-weight: 200;
        @media (max-width: 667px) {
          font-size: 12px;
        }
      }
    }

    .previews-screens {
      padding: 200px 0 100px 0;

      @media (max-width: 667px) {
        padding: 20px 0 0px 0;
      }

      .screen-image {
        margin-bottom: 50px;

        @media (max-width: 667px) {
          margin-bottom: 20px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      .block-image {
        margin: 0 auto;
        display: block;
        transition: all 1s ease-in-out;
        border-radius: 8px;

        // animations
        opacity: 0;
        transform: translate(0, 50px);
      }
    }
  }

  .svg-line-left {
    position: absolute;
    left: 0;
    top: -200px;

    .preview-line-l {
      .preview-line-lp {
        stroke-dasharray: 2500px;
        stroke-dashoffset: 2500px;
      }
    }

    @media (max-width: 667px) {
      display: none;
    }
  }

  .svg-line-right {
    position: absolute;
    right: 0;
    top: -550px;

    .preview-line-r {
      .preview-line-rp {
        stroke-dasharray: 2500px;
        stroke-dashoffset: 2500px;
      }
    }

    @media (max-width: 667px) {
      display: none;
    }
  }

  .brand-logo-mobile {
    display: none;
    @media (max-width: 667px) {
      display: block;
      margin: 0 auto;
      max-width: 100%;
      margin-bottom: 50px;
    }
  }
}

.blog-offers {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 100px 0;

  .header {
    margin-bottom: 70px;
    &__subtitle {
      color: #e5375d;
      margin-bottom: 15px;
      font-size: 14px;
      letter-spacing: 0.2em;
      font-weight: 700;
      text-transform: uppercase;
    }

    &__title {
      color: #000;
      font-size: 46px;
      font-weight: 500;
    }
  }

  &__box {
    color: #000;

    img {
      border-radius: 15px;
      margin-bottom: 30px;
    }

    h2 {
      font-size: 30px;
      font-weight: 500;
      margin-bottom: 30px;
    }

    p {
      font-size: 14px;
      line-height: 1.85;
    }
  }
}

.blogPage {
  background: #fff;

  header {
    background: #000;
    .AU_animation1 {
      transform: translateX(-650px);
    }
    .AU_animation2 {
      transition: all 2s ease-in-out;
      opacity: 0;
    }
    position: relative;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 82px 0 0 0;
      width: 1700px;
      margin: 0 auto;
      position: relative;
      background: #000;
      z-index: 100;
      .text,
      a {
        transition: 0.5s ease-in-out all;

        @media (max-width: 667px) {
          img {
            max-width: 125px;
          }
        }
      }
      @media (max-width: 1700px) {
        width: 95%;
      }
      @media (max-width: 1300px) {
        width: 95%;
        padding-top: 45px;
      }
      @media (max-width: 900px) {
        width: 90%;
        padding: 30px 0 30px 0;
      }
      @media (max-width: 768px) {
        .line_one {
          width: 450px;
        }
      }
      @media (max-width: 720px) {
        width: 90%;
      }
      @media (max-width: 667px) {
        width: 100%;
      }
      @media (max-width: 500px) {
        display: block;
      }
      .left {
        color: #fff;
        font-weight: bold;
        height: 50px;
        cursor: pointer;
        @media (max-width: 992px) {
          padding-left: 15px;
        }
        span {
          font-weight: 200;
          display: block;
        }
      }
      .right {
        color: #fff;
        font-weight: bold;
        cursor: pointer;
        @media (max-width: 500px) {
          margin-top: 20px;
        }
      }
    }
    .header-container {
      width: 1240px;
      margin: 90px auto 0 auto;
      @media (max-width: 1450px) {
        width: 1000px;
      }
      @media (max-width: 1240px) {
        width: 85%;
      }
      @media (max-width: 1050px) {
        margin-top: 50px;
      }
      @media (max-width: 900px) {
        width: 90%;
      }

      @media (max-width: 950px) {
        & {
          margin: 0 auto;
          text-align: center;
          width: 95%;
          padding-left: 0px;
        }
      }
      .service {
        color: $headline;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        font: {
          style: normal;
          weight: 600;
          size: 14px;
        }
        line-height: 18px;
      }
      .text {
        width: 638px;
        @media (max-width: 900px) {
          width: 90%;
          margin: 15px auto 0 auto;
        }
        h1 {
          margin: {
            top: 15px;
          }
          color: #fff;
          font: {
            style: normal;
            weight: 600;
            size: 52px;
          }
          line-height: 59px;
        }
      }
      @media (max-width: 1440px) {
        .text h1 {
          font: {
            size: 32px;
          }
          line-height: 39px;
        }
      }
      @media (max-width: 950px) {
      }
    }
    .header-container_2 {
      margin: {
        top: 92px;
        left: auto;
        right: auto;
      }
      width: 1240px;
      @media (max-width: 1450px) {
        width: 1000px;
      }
      @media (max-width: 1240px) {
        width: 85%;
      }
      @media (max-width: 900px) {
        width: 90%;
      }
      .grid {
        display: flex;
        @media (max-width: 720px) {
          display: block;
        }
        .header_box {
          width: 390px;
          margin-right: 250px;
          margin-bottom: 49px;
          @media (max-width: 1050px) {
            margin-right: 80px;
          }
          @media (max-width: 720px) {
            margin: 60px auto 20px auto;
            width: 90%;
          }
          h3 {
            margin: {
              bottom: 25px;
            }
            width: 275px;
            color: #fff;
            font: {
              style: normal;
              weight: 600;
              size: 30px;
            }
            line-height: 38px;
            @media (max-width: 720px) {
              margin-bottom: 15px;
              width: 100%;
            }
          }
          p {
            @media (max-width: 900px) {
              width: 95%;
              margin: 0 auto;
            }
            color: #d1d5db;
            font: {
              style: normal;
              weight: 400;
              size: 16px;
            }
            line-height: 29px;
          }
        }
      }
      @media (max-width: 720px) {
        text-align: center;
      }
    }
    svg.header_line {
      position: absolute;
      top: 350px;
      right: 0;
      z-index: 1;
      .line-path {
        stroke-dasharray: 2500px;
        stroke-dashoffset: 2500px;
      }
      @media (max-width: 1100px) {
        width: 350px;
        right: -150px;
        top: 150px;
      }
      @media (max-width: 667px) {
        right: -120px;
        width: 230px;
        top: 50px;
      }
    }
    svg.header_line--services {
      right: -250px;
      bottom: -750px;
      transform: rotate(-35deg);
    }
  } /* ./header */

  .hero--sub {
    min-height: 426px;
    background: #000;
    padding: 0 20px 95px 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    margin-top: 0;

    @media (max-width: 667px) {
      margin-top: -50px;
      align-items: flex-start;
    }

    .date {
      font-size: 14px;
      color: #e5375d;
      font-weight: 700;
      letter-spacing: 0.2em;
      margin-bottom: 5px;
    }

    .headline {
      font-size: 52px;
      color: #fff;
      max-width: 800px;
      margin: 0 auto;

     @media (max-width: 1280px) {
        font-size: 40px;
        text-align: center;
      }

      @media (max-width: 667px) {
        font-size: 34px;
        text-align: left;
      }
    }
  }

  article {
    max-width: 630px;
    margin: 0 auto;
    padding: 100px 0;

    @media (max-width: 667px) {
      padding: 40px 0;
    }

    h1 {
      color: #000;
      font-weight: 500;
      font-size: 36px;
      margin-bottom: 20px;
      margin-top: 0;
    }

    h2 {
      color: #000;
      font-weight: 500;
      font-size: 30px;
      margin-bottom: 20px;
    }

    h3,
    h4 {
      font-size: 22px;
      font-weight: 500;
      margin-bottom: 25px;
    }

    p {
      color: #929292;
      line-height: 1.8;
      font-size: 16px;
      margin-bottom: 20px;
    }

    img {
      margin-bottom: 80px;

      @media (max-width: 667px) {
        margin-bottom: 40px;
      }
    }

    ul,
    ol {
      margin: 30px 0 45px;
      li {
        color: #5e5e5e;
        margin-bottom: 6px;
      }
    }

    ul {
      padding-left: 20px;

      li {
        position: relative;
        padding-left: 30px;

        &:before {
          content: "";
          background-color: #f3385a;
          background-image: url("../../images/icon.svg");
          background-repeat: no-repeat;
          background-position: center;
          width: 17px;
          height: 17px;
          position: absolute;
          left: 0;
          top: 2px;
          border-radius: 4px;
        }
      }
    }

    ol {
      padding-left: 20px;
      counter-reset: my-awesome-counter;
      list-style: none;

      li {
        position: relative;
        padding-left: 30px;
        counter-increment: my-awesome-counter;

        &:before {
          content: counter(my-awesome-counter) ". ";
          background-color: #3fa9f5;
          color: #fff;
          font-weight: 600;
          width: 17px;
          font-size: 11px;
          height: 17px;
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          left: 0;
          top: 2px;
          border-radius: 4px;
        }
      }
    }
  }

  footer {
    background: #000;
    padding-bottom: 50px;
    .info {
      background-image: url("../../images/footer.svg");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      margin-bottom: 81px;
      height: 150px;
      img {
        width: 100%;
        opacity: 0.19;
      }
      @media (max-width: 950px) {
        margin-bottom: 10px;
      }
      .columns {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        @media (max-width: 950px) {
          text-align: center;
          .is-2 {
            padding: 0;
          }
          .is-7 {
            height: 100%;
            margin: 0 auto;
          }
        }
        .all {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          @media (max-width: 950px) {
            .animation_13 {
              display: grid;
            }
          }
          a {
            font-style: normal;
            font-weight: 300;
            font-size: 56px;
            line-height: 59px;
            color: white;
            opacity: 0.5;
            transition: all 0.5s ease-in-out;
            &:hover {
              opacity: 0.9;
            }
            @media (max-width: 1800px) {
              font-size: 45px;
            }
            @media (max-width: 1280px) {
              font-size: 39px;
            }
            @media (max-width: 1050px) {
              font-size: 32px;
            }
            @media (max-width: 950px) {
              font-weight: 100;
            }
            @media (max-width: 667px) {
              font-weight: 400;
            }
          }
          a.email {
            margin-right: 60px;
            @media (max-width: 1280px) {
              margin-right: 25px;
            }
            @media (max-width: 950px) {
              margin-right: 0px;
            }
          }
        }
      }
      .black_wall {
        background-color: #000807;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
        margin: auto;
        height: 100%;
        transform: translate(0%);
        transition: all 1s ease-in-out;
      }
      .black_wall:after {
        content: "";
        position: absolute;
        left: -179px;
        border-right: 180px solid #000807;
        border-top: 192px solid transparent;
      }
    }
    .end {
      opacity: 0;
      transition: all 0.5s ease-in-out;
      @media (max-width: 950px) {
        text-align: center;
      }
      .center_up {
        color: #9ca3af;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
      }
      .center_down {
        margin-top: 11px;
        color: #9ca3af;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
      }
      .social {
        display: flex;
        align-items: center;
        height: 20px;
        margin-top: 27px;
        a {
          position: relative;
          height: 100%;
          margin-right: 8px;
          //border-image-slice: 1;
          //border-bottom: 1px solid;
          //border-width: 1px;
          //border-image-source: linear-gradient(277.48deg, $bg-color 8.64%, $bg-color 56.22%, $bg-color 97.03%);
          transition: all 0.25s ease-in-out;
          &:before {
            content: "";
            width: 0;
            height: 3px;
            position: absolute;
            bottom: -10px;
            left: 0;
            background: linear-gradient(
              277.48deg,
              #0fa6e1 8.64%,
              #f3385a 56.22%,
              #45187a 97.03%
            );
            transition: all 0.35s ease-in-out;
          }
          &:hover {
            transform: translate(0, -5px);
            &:before {
              width: 100%;
            }
          }
        }
        @media (max-width: 950px) {
          justify-content: center;
          margin-top: 10px;
        }
      }
      .links {
        display: grid;
        float: right;
        a {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 5px;
          color: #ffffff;
          border-image-slice: 1;
          border-bottom: 2px solid;
          border-width: 2px;
          border-image-source: linear-gradient(
            277.48deg,
            $bg-color 8.64%,
            $bg-color 56.22%,
            $bg-color 97.03%
          );
          &:hover {
            transform: translateY(0px);
            transition: all 0.2s ease-in-out;
            border-image-source: linear-gradient(
              277.48deg,
              #0fa6e1 8.64%,
              #f3385a 56.22%,
              #45187a 97.03%
            );
            @media (max-width: 950px) {
              transform: translateY(0px);
              border-image-source: linear-gradient(
                277.48deg,
                $bg-color 8.64%,
                $bg-color 56.22%,
                $bg-color 97.03%
              );
            }
          }
        }
        @media (max-width: 950px) {
          float: none;
        }
      }
      .copy {
        margin-top: 60px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
      }
    }
  } /* ./footer */
}

.blogList {
  .hero {
    padding: 70px 0;
    text-align: center;

    @media (max-width: 667px) {
      padding: 30px 0;
    }

    &__subtitle {
      color: #e5375d;
      font-size: 14px;
      font-weight: 800;
      letter-spacing: 0.02em;
      text-transform: uppercase;
    }

    &__title {
      color: #fff;
      font-size: 52px;

      @media (max-width: 667px) {
        font-size: 34px;
      }

      strong {
        color: #fff;
      }
    }
  }

  .items {
    max-width: 816px;
    margin: 0 auto 105px auto;
    @media (max-width: 860px) {
      padding-left: 10px;
      padding-right: 10px;
    }
    .noContent {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      text-align: center;
      background: #1a1f1f;
      color: white;
      border-radius: 10px;
      padding-top: 30px;
      padding-bottom: 30px;
    }
    .blog-item {
      display: block;
      margin-bottom: 40px;
      h2 {
        color: #fff;
        margin-bottom: 14px;
        font-size: 30px;

        @media (max-width: 667px) {
          font-size: 24px;
        }
      }

      p {
        color: #5e5e5e;
        margin-bottom: 15px;
      }

      img {
        width: 100%;
        height: 390px;
        object-fit: cover;
        object-position: center;
        border-radius: 15px;
        margin-bottom: 25px;
      }

      a,
      .link {
        color: #5e5e5e;
        text-decoration: underline;
      }
    }
  }
}
