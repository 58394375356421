//
//	GENERAL
// ------------------------------------------------------

html {
    overflow-x: hidden;
}

html, body{
    font-family: 'Lexend Deca', sans-serif;
    font-weight: normal;
    overflow-x: hidden;
    font-size: 16px;
    overflow-x: hidden;
    background: {
        color: $bg-color;
    }

    .Home{
        header{
            .lang1{
                z-index: 100;
                bottom: 50px;
                left: 30px;
                position: fixed;
                width: 519px;
                height: 77px;
                background-color: #F3385A;
                border-radius: 15px;
                display: flex;
                align-items: center;
                justify-content: space-around;
                .left{
                    height: 100%;
                    width: 68%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .right{
                    height: 100%;
                    width: 32%;
                    display: flex;
                    align-items: center;
                    text-decoration: underline;
                    color: #fff;
                    .yes{
                        transition: all 0.1s ease-in-out;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        padding: 0 25px;
                        border-left: 1px solid rgba(255, 255, 255, 0.4);
                        border-right: 1px solid rgba(255, 255, 255, 0.4);
                        cursor: pointer;
                        &:hover{
                            background-color: #f2264c;
                        }
                    }
                    .no{
                        width: 100%;
                        border-radius: 0 0 15px 0;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        padding: 0 25px;
                        cursor: pointer;
                        &:hover{
                            background-color: #f2264c;
                        }
                    }
                }
                span{
                    color: #fff;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 34px;
                }
                .exit{
                    cursor: pointer;
                    position: absolute;
                    right: -8px;
                    top: -8px;
                }
            }
            .lang2{
                z-index: 100;
                bottom: 50px;
                left: 30px;
                position: fixed;
                width:479px;
                height:60px;
                background-color:#F3385A;
                border-radius:15px;
                display: flex;
                span{
                    color: #fff;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 34px;
                }
                .left{
                    width:50%;
                    display: flex;
                    justify-content:center;
                    align-items:center;
                }
                .right{
                    width:50%;
                    display: flex;
                    justify-content:center;
                    align-items:center;
                    border-left:1px solid white;
                    
                    border-radius: 0 0 15px 0;
                    &:hover{
                        background-color: #f2264c;
                    }
                    span{
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }
                .exit{
                    cursor: pointer;
                    position: absolute;
                    right: -8px;
                    top: -8px;
                }
            }
            .lang3{
                z-index: 100;
                bottom: 50px;
                left: 30px;
                position: fixed;
                width:269px;
                height:60px;
                background-color:#F3385A;
                border-radius:15px;
                display: flex;
                .left{
                    width:100%;
                    display: flex;
                    justify-content:center;
                    align-items:center; 
                    span{
                        color: #fff;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 34px;
                    }
                }
                .exit{
                    cursor: pointer;
                    position: absolute;
                    right: -8px;
                    top: -8px;
                }
            }
            .counter {
                position: relative;
                width: 200px;
                z-index: 98000;
                .fade-in {
                    position: fixed;
                    color: #000;
                    font-weight: 950;
                    font-size: 232px;
                    line-height: 296px;
                    left:50%;
                    top:50%;
                    transform: translate(-50%,-50%);
                }
                .overlay{
                    color:#fff;
                    position: absolute;
                    height:0%;
                    left:0;
                    top:0;
                    overflow: hidden;
                    font-weight: 950;
                    font-size: 232px;
                    line-height: 296px;
                }
                .color {
                    width: 0px;
                    overflow: hidden;
                    color: #f60d54;
                }
                @media (max-width: 950px){
                    .fade-in, .overlay{
                        font-size: 100px;
                        line-height: 164px;
                    }
                }
            }
            .landing_page{
                background:#191A1B;
                width: 100%;
                height: 100vh;
                z-index: 5000;
                overflow: hidden;
                position: relative;  
                @media (min-width: 2000px) and (min-height: 1300px) {
                    height: 80vh;
                    min-height: 600px;
                    padding-bottom: 50px;
                }
                @media (max-width: 992px){
                    height: 100vh;
                    min-height: 600px; 
                }    
                @media (max-width: 667px){
                    height: auto;
                }

                @media
                only screen and (-webkit-min-device-pixel-ratio: 2),
                only screen and (   min--moz-device-pixel-ratio: 2),
                only screen and (     -o-min-device-pixel-ratio: 2/1),
                only screen and (        min-device-pixel-ratio: 2),
                only screen and (                min-resolution: 192dpi),
                only screen and (                min-resolution: 2dppx) { 
                    background:#191c1b;
                }

                .line_one{
                    position: absolute;
                    left: 50%;
                    top: -350px;
                    transform: translate(-50%, 0) rotate(-30deg);
                    z-index: 6000;
                    .line_path{
                        stroke-dasharray: 1340px;
                        stroke-dashoffset: 1340px;
                    }
                }
                @media (max-width: 1800px) {
                    .line_one{
                        width: 450px;
                    }
                }

                @media (max-width: 950px){
                    .line_one {
                        width: 226px;
                    }
                }

                @media (max-width: 667px){
                    .line_one {
                        display:none;
                    } 
                }

                .line_two{
                    position: absolute;
                    left: 0;
                    bottom: -300px;
                    .line_path{
                        stroke-dasharray: 1300px;
                        stroke-dashoffset: 1300px;
                    }
                }
                @media (max-width: 1800px) {
                    .line_two{
                        width: 195px;
                    }
                }
                @media (max-width: 1050px){
                    .line_two {
                        width: 140px;
                    }
                }
                @media (max-width: 667px){
                    .line_two {
                        display: none;
                    }
                }

                .line_path{
                    animation: line_path 3s ease-in-out;
                    animation-fill-mode: forwards;
                }
                .digital_agenciy{
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 18px;
                    color: $headline;
                    letter-spacing: 0.2em;
                    text-transform: uppercase;
        
                    width: 100%;
                    position: absolute;
                    bottom: 64px;
                    text-align: center;
        
                    opacity: 1;
                    transition: all .2s ease-in-out;
                }
                .my_container{
                    width: $container_w;
                    height: 120px;
                    display: flex;
                    justify-content: space-between;
                    margin: 0 auto;
                    position: relative;
                    .logo{
                        cursor: pointer;

                        a {
                            transition: opacity .45s ease-in-out;
                        }
                        img{
                            position: absolute;
                            //left: $logo_left;
                            padding-top: 88px;
                            transition: all .8s ease-in-out; 
                            // transition: opacity .45s ease-in-out;

                            @media (max-width: 1440px) {
                                padding-top: 60px;
                            }
                        }

                        .text {
                            color: #fff;
                            font-weight: 700;
                            transition: opacity .45s ease-in-out;
                            bottom: -12px;
                            @media (max-width: 1440px) {
                                bottom: auto; 
                                top: 55px;
                            }
                            @media (max-width: 667px) {
                                bottom: auto; 
                                top: 25px;
                                left: 15px;
                                font-size: 14px;
                            }
                            span {
                                display: block;
                                font-weight: 400;
                            }
                        }
                    }
        
                    @media (max-width: 1800px) {
                        .logo img{
                             //left:calc(#{50%} - #{$logo_w / 2});
                        }
                    }
                    @media (max-width: 1050px) {
                        .logo img{
                            padding-top: 50px;
                        }
                    }
                    @media (max-width: 950px){
                        .logo img{
                            left: 15px !important;
                            padding-top: 55px;
                            width: 110px;
                        }
                    }
                    @media (max-width: 677px){
                        .logo img{
                            left: 15px !important;
                            padding-top: 30px;
                            width: 110px;
                        }
                    }
                    .work{
                        opacity: 0;
                        a h3{
                            padding-top: 88px;
                            @media (max-width: 1440px) {
                                padding-top: 60px;
                            }

                            font: {
                                weight: 600;
                                size: 14px;
                            }
                            color: rgba(255, 255, 255, 0.6);
                            letter-spacing: 0.2em;
                            text-transform: uppercase;
                            &:hover{
                                color: rgba(255, 255, 255, 1);
                                transition: all 1s ease-in-out;
                            }
                            @media (max-width: 1050px) {
                                &{
                                    padding-top: 50px;
                                }
                            }
                        }
                        @media (max-width: 950px){
                            &{
                                display: none;
                            }
                        }
        
                    }
                }
                @media (max-width: 1800px) {
                    .my_container{
                        width: 90%;
                    }
                }
                .columns{
                    margin-top: 140px;
                    @media (min-width: 2000px) and (min-height: 1300px) {
                        margin-top:200px;
                    }
                    @media (max-width: 1440px) {
                        &{
                            margin-top: 90px;
                        }
                    }
                    @media (max-width: 1024px){
                        &{
                            margin-top: 40px;
                        }
                    }

                    @media (max-width: 992px){
                        &{
                            margin-top: 18vh;
                        }
                    }

                    @media (max-width: 667px){
                        &{
                            margin-top:0;
                            margin-bottom: 45px;
                        }  
                    }
                    .landing_center{
                        .digital{
                            color: $headline;
                            letter-spacing: 0.2em;
                            text-transform: uppercase;
                            font: {
                                style: normal;
                                weight: 600;
                                size: 14px;
                            }
                            line-height: 18px;
                        }
                        .text{
                            h1{    
                                color: #fff;
                                font: {
                                    style: normal;
                                    weight: 600;
                                    size: 68px;
                                }
                                line-height: 85px;
                            }
                        }
                        @media (max-width: 1440px) {
                            .text h1{
                                font: {
                                    size:60px;
                                }
                                line-height: 70px;
                            }
                        }
                        @media (max-width:1024px){
                            .text h1{
                                font-size: 48px;
                                line-height: 1.4;
                            }
                        }
                        @media (max-width: 767px){
                            .text h1{
                                margin-top: 3px;
                                font-size: 28px;
                                line-height: 1.25;
                                font-weight: 400;
                            }
                        }
                        .sub_text {
                            opacity: 0;
                            p{ 
                                margin: {
                                    top: 29px;
                                    bottom: 38px;
                                }
                                font: {
                                    weight: 300;
                                    size: 24px;
                                }
                                line-height: 36px;
                                color: #EBEBEB;
                            }
        
                        }
                        @media (max-width: 1440px) {
                            .sub_text p{
                                margin: {
                                    top:20px;
                                    bottom:30px;
                                }
                                font: {
                                    size:22px;
                                }
                                line-height: 30px;
                            }
                        }   
                        @media (max-width: 1024px){
                            .sub_text p{
                                margin-top: 10px;
                                margin-bottom: 24px;
                                font-size: 18px;
                                line-height: 28px;
                                font-weight: 200;
                            }
                        }
                        a.Mybtn{
                            rect{
                                stroke-dasharray: 669px;
                                stroke-dashoffset: 669px;
                            }
                            position: relative;
                            display: block;
                            overflow: hidden;
                            @include size(100%, 65px);
                            max-width: 298px;
                            border-radius: 30px;
                            span {
                            @include absolute();
                            display: block;
                            @include size(0);
                            border-radius: 50%;
                            background-color:  #F3385A;
                            transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
                            transform: translate(-50%, -50%);
                            }
        
                            &:hover {
                                span {
                                    @include size(225%, 298px*2.25);
                                }
                            }
                            .text{
                                position: absolute;
                                top: 0;
                                left: 0;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 100%;
                                height: 100%;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 34px;
                                color: transparent;
                                animation-duration: 2s;
                                animation-fill-mode: forwards;
                            }
                        }
                        @media (max-width: 950px){
                            a.Mybtn{
                                width: 240px;
                                margin: 0 auto;
                                svg{
                                    width: 240px;
                                }
                                .text{
                                    font-size: 14px;
                                }
                                &:hover{
                                    span {
                                        @include size(0%, 298px*2.25);
                                    }
                                }
                            }
                        }
                        .info{
                            opacity: 0;
                            a{
                                display: inline-flex;
                                align-items: center;
                                height: auto;
                                font: {
                                    style: normal;
                                    weight: 400;
                                    size: 16px;
                                }
                                line-height: 25px;
                                @media only screen and (min-width: 3000px) {
                                    font-size:39px;
                                }
                                line-height: 25px;
                                color: #FFFFFF;
                                padding: 2px;
                                // border-image-slice: 1;
                                // border-bottom: 2px solid;
                                // border-width: 2px;
                                // border-image-source: linear-gradient(277.48deg, $bg-color 8.64%, $bg-color 56.22%, $bg-color 97.03%);
                                // &:hover{
                                //     transform: translateY(-6px);
                                //     transition: transform .2s ease-in-out;
                                //     border-image-source: linear-gradient(277.48deg, #0FA6E1 8.64%, #F3385A 56.22%, #45187A 97.03%);
                                // }
                                // @media only screen and (max-width: 420px) {
                                //     &:hover{
                                //         transform:none;
                                //         transition: transform .2s ease-in-out;
                                //         border-image-source: linear-gradient(277.48deg, $bg-color 8.64%, $bg-color 56.22%, $bg-color 97.03%);
                                //     }
                                // }
                                @media only screen and (max-width: 420px) {
                                    font-size: 14px;
                                    height: 33px;
                                }
                                @media only screen and (max-width: 667px) {
                                    display: inline-block;
                                    margin: 0 !important;
                                }
                            }
                            .underline--magical {
                                background-image: linear-gradient(120deg, rgb(240, 147, 251) 0%, rgb(245, 87, 108) 100%);
                                // @media only screen and (max-width: 900px) {
                                //     background-image: none;
                                // }
                                background-repeat: no-repeat;
                                background-size: 100% 0.2em;
                                background-position: 0 88%;
                                transition: background-size 0.25s ease-in;
                            }
                            .underline--magical:hover{
                                background-size: 100% 88%;
                            }
                            @media only screen and (max-width: 667px) {
                                display: flex;
                                flex-wrap: wrap;
                                justify-content: center;
                                align-items: center;
                                flex-direction: column;
                            }
                            span{
                                color: #fff;
                                @media only screen and (max-width: 667px) {
                                    display: none;
                                }
                                @media only screen and (min-width: 3000px) {
                                    font-size:39px;
                                }
                            }

                        }
                    }
                }
            }
        }
        .section2{
            padding-top: 118px;
            background-color: $bg_section2;
            width: 100%;
            @media (max-width: 1000px){
                padding-top: 20px;
            }
            .mobile{
                .one{
                    text-align: center;
                    width: 90%;
                    margin: 0 auto;
                    margin-bottom: 40px;
                    img{
                        width: 290px;
                        height: 155px;
                        margin: 0 auto;
                        @media (max-width: 992px) {
                            width: 100%;
                            height: 250px;
                            object-fit: cover;
                        }
                    }
                    .title{
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 23px;
                        letter-spacing: 0em;
                        margin-top: 22px;
                        margin-bottom: 12px;
                        color: #fff;
                    }
                    .text{
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: 0em;
                        color: #fff;
                        @media (max-width: 1000px){
                            width: 50%;
                            margin: 0 auto;
                        }
                        @media (max-width: 500px){
                            width: 80%;
                        }
                    }
                }
            }
            .header{
                width: 370px;
                .headline {
                    h2 {
                        color: $headline;
                        letter-spacing: 0.2em;
                        text-transform: uppercase;
                        font: {
                            style: normal;
                            weight: 700;
                            size: 14px;
                        }
                        line-height: 18px;
                    }
                }
                .title {
                    h2 {    
                        margin: {
                            top: 15px;
                        }
                        color: #fff;
                        font: {
                            style: normal;
                            weight: 100;
                            size: 52px;
                        }
                        line-height: 59px;
                    }
                }
                @media (max-width: 1800px) {
                    .title{
                        h2{    
                            font-size: 45px;
                            line-height: 45px;
                        } 
                    }
                }
                @media (max-width: 1050px) {
                    .title{
                        h2{    
                            font-size: 40px;
                            line-height: 40px;
                        } 
                    }
                    .headline{
                        h2{
                            font-size: 12px;
                        }
                    }
                }
                @media (max-width: 1000px){
                    &{
                        width: 90%;
                        margin: 0 auto;
                        text-align: center;
                    }
                    .title{
                        h2{    
                            margin-top: 2px;
                            font-size: 28px;
                            line-height: 27px;
                        } 
                    }
                    .headline{
                        h2{
                            font-size: 10px;
                        }
                    }
                }
            }
            .images{
                overflow-x: hidden;
                overflow-y: hidden;
                padding-top: 174px;
                width: 100%;
                margin: 0;
                .image{
                    position: relative;
                    height: 400px;
                    @media (max-width: 1050px) {
                        &{
                            height: 300px;
                        }
                    }
                    &:hover{
                        z-index: 5;
                    }
                    &:hover .img_con{
                        opacity: 1;
                        transform: scale(1.1) !important;
                    }
                    &:hover .img_con .img{
                        transform: scale(1) !important;
                    }
                    &:hover  .text .title{
                        opacity: 1;
                        transform: none;
                    }
                    &:hover  .text .popis{
                       opacity: 1;
                       transform: none;
                    }
                    .img_con{
                        width: 100%;
                        height: 100%;
                        opacity: 0.2;
                        overflow: hidden;
                        transition: all 2s ease-in-out;
                        transform-origin: bottom;
                        .img{
                            width: 100%;
                            height: 100%;
                            transform-origin: bottom;
                            transition: all 2.5s ease-in-out;
                            background-position: center;
                            background-size: cover;
                        }
                    }
                    .text{
                        position: absolute;
                        bottom: 70px;
                        left: 53px;
                        width: 390px;
                        transition: all .5s;
                        .title{
                            opacity: 0.6;
                            font-style: normal;
                            font-weight: 300;
                            font-size: 30px;
                            line-height: 38px;
                            color: #FFFFFF;
                            margin-bottom: 15px;
                            transition: all .45s ease-in-out;
                            transform: translate(0, 150%);
                        }
                        .popis{
                            opacity: 0;
                            transform: translate(0, 100%);
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 26px;
                            color: #FFFFFF;
                            transition: all .45s ease-in-out;
                        }
                        @media (max-width: 1280px) {
                            width: 80%;
                            .title{
                                font-size: 25px;
                            }
                        }
                        @media (max-width: 1050px) {
                            .title{
                                font-size: 22px;
                            }
                            .popis{
                                font-size: 12px;
                                line-height: 20px;
                            }
                        }
                    }
    
                }
            }
            @media (max-width: 1800px) {
                .images{
                    padding-top: 100px;
                }
            }
            @media (max-width: 1050px) {
                .images{
                    padding-top: 50px;
                }
            }
            @media (min-width: 1000px){
                .pc{
                    display: block;
                }
                .mobile{
                    display: none;
                }
            }
            @media (max-width: 1000px){
                .pc{
                    display: none;
                }
                .mobile{
                    display: block;
                }
            }
        }
        .section3{
            position: relative;
            height: 550px;
            width: 100%;
            @media (max-width: 799px){
                &{
                    height: auto;
                }
            }
            .bg{
                width: 100%;
                height: 100%;
                background-color: #F3385A;
                padding-top: 116px;
                @media (max-width: 799px){
                    height: auto;
                    &{
                        text-align: center;
                        padding-top: 0px;
                        padding-bottom: 90px;
                    }
                }
                .header{
                    .headline{
                        h2 {
                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 18px;
                            text-transform: uppercase;
                            color: #FFFFFF;
                        }
                    }
                    .title{
                        h2 {
                            margin-top: 11px;
                            font-style: normal;
                            font-weight: 100;
                            font-size: 46px;
                            line-height: 106.1%;
                            color: #FFFFFF;
                        }
                    }
                    @media (max-width: 1800px) {
                        .title{
                            h2{
                                font-size: 45px;
                                line-height: 93.1%;
                            } 
                        }
                    }
                    @media (max-width: 799px){
                        .title{
                            h2{
                                margin-top: 3px;
                                font-size: 32px;
                                line-height: 34px;
                            } 
                        }
                        .headline{
                            h2{
                                font-size: 10px;
                            }
                        }
                    }
                }
                p{
                    width: 383px;
                    font-style: normal;
                    font-weight: 200;
                    font-size: 20px;
                    line-height: 31px;
                    color: #FFFFFF;
                    opacity: 0;
                }
                .button {
                    background: transparent;
                    border: 2px solid #fff;
                    height: 65px;
                    border-radius: 60px;
                    padding-left: 30px;
                    padding-right: 30px;
                    font-weight: 600;
                    color: #fff;
                    margin-top: 80px;
                    letter-spacing: 0.01em;
                    transition: all .35s ease-in-out;
                    &:hover {
                        background: #fff;
                        color: #F3385A;
                    }
                    @media (max-width: 992px){
                        width: 100%;
                    }
                }
                @media (max-width: 1800px) {
                    P{
                        font-size:18px;
                    }
                }
                @media (max-width: 799px){
                    .button { 
                        margin-top: 15px;
                        height: 55px;
                    }
                    p{
                        width: 90%;
                        margin: 0 auto;
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }
            .black_wall{
                background-color: #000807;
                position: absolute;
                bottom: 0;
                right: 0;
                left: 0;
                width: 110%;
                margin:auto;
                height: 100%;
                transform: translate(-5%);
                transition: all 1s ease-in-out;
            }
            .black_wall:after{
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0;
                height: 0;
                border-left: 100px solid #F3385A;
                border-bottom: 550px solid transparent;
                border-top: 0px solid transparent;
                @media (max-width: 799px){
                    &{
                        border-bottom: 430px solid transparent;
                    }
                }
            }
        }
        .section4{
            position: relative;
            width: 100%;
            background-color: #131414;
            padding: 123px 0;

            .bg{
                .container-text{
                    .columns{
                        .header{
                            width: 300px;
                            @media (max-width: 950px){
                                &{
                                    width: 90%;
                                    margin: 0 auto;
                                    text-align: center;
                                }
                            }
                            .headline{
                                h2{
                                    color: $headline;
                                    letter-spacing: 0.2em;
                                    text-transform: uppercase;
                                    font: {
                                        style: normal;
                                        weight: 600;
                                        size: 14px;
                                    }
                                    line-height: 18px;
                                }
                            }
                            .title{
                                h2{    
                                    margin: {
                                        top: 15px;
                                    }
                                    color: #fff;
                                    font: {
                                        style: normal;
                                        weight: 100;
                                        size: 52px;
                                    }
                                    line-height: 59px;
                                }
                            }
                            @media (max-width: 1800px) {
                                .title{
                                    h2{
                                        font-size: 45px;
                                        line-height: 45px;                                
                                    } 
                                }
                            }
                            @media (max-width: 1050px) {
                                .title{
                                    h2{
                                        font-size: 40px;
                                        line-height: 40px;                                
                                    } 
                                }
                                .headline{
                                    h2{
                                        font-size: 12px;                              
                                    } 
                                }
                            }
                            @media (max-width: 950px){
                                .title{
                                    h2{
                                        margin-top: 3px;
                                        font-size: 24px;
                                        line-height: 27px;                                
                                    } 
                                }
                                .headline{
                                    h2{
                                        font-size: 10px;                              
                                    } 
                                }
                            }
                        }
                    }
                }
                .carousel{
                    position: relative;
                    opacity: 0;
                    .flickity-slider {
                        left: -8% !important;
                        @media (min-width: 2200px) {
                            left: -13.5% !important;
                        }
                        @media (min-width: 3300px) {
                            left: -17% !important;
                        }
                        @media (max-width: 1050px){
                            left: -9% !important;
                        }
                        @media (max-width: 800px){
                            left: -25% !important;
                        }
                        @media (max-width: 500px){
                            left: -14% !important;
                        }
                    }
                    .gradient{
                        position: absolute;
                        background: linear-gradient(270deg, #0F1615 0%, rgba(15, 22, 21, 0) 100%);
                        right: 0;
                        height: 100%;
                        width: 500px;
                        top: 0; 
                    }
                    .carousel-cell {
                        width: 36vw;
                        margin-right: 120px;
                        @media (min-width: 2200px) {
                            width: 25vw;
                        }
                        @media (min-width: 3300px) {
                            width: 17vw;
                        }
                        @media (max-width: 800px){
                            width: 47vw;
                            margin-right: 80px;
                        }
                        @media (max-width: 500px){
                            width: 200px;
                            margin-right: 25px;
                        }
                        .carousel__text{
                            h3 {
                                margin-left: 40px;
                                margin-top: 41px;
                                font-size: 30px;
                                font-style: normal;
                                font-weight: 100;
                                line-height: 38px;
                                letter-spacing: 0em;
                                color: rgba(255, 255, 255, 1);
                                transition: all .2s ease-in-out;
                            }
                            p{
                                margin-left: 40px;
                                width: 390px;
                                margin-top: 14px;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 26px;
                                letter-spacing: 0em;
                                color: rgba(255, 255, 255, 1);
                                transition: all .2s ease-in-out;
                            }
                            @media (max-width: 800px){
                                p{
                                    width:auto;
                                }
                            }
                            @media (max-width: 500px){
                                h3{
                                    margin-top: 13px;
                                    font-size: 20px;
                                    line-height: 23px;
                                    margin-left: 23px;
                                }
                                p{
                                    width:auto;
                                    font-size:12px;
                                    line-height: 20px;
                                    margin-left: 23px;
                                }
                            }
                            &:hover{
                                text-decoration: none;
                            }
    
                        }
                        .carousel__image {
                            position: relative;
                            width: 100%;
                            height: 335px;
                            max-height: 400px;
                            min-height: 300px;
                            margin: 0;
                            overflow: hidden;
                            display: block;
                            margin-left: 40px;
                            .background-image {
                                position: relative;
                                top: 0;
                                left: 0;
                                width: 101%;
                                height: 100%;
                                background-size: cover;
                                background-position: center;
                                transition: all 1s ease-in-out;
                            }
                            @media (max-width: 1440px){
                                height: 300px;
                            }
                            @media (max-width: 1050px){
                                height: 200px;
                                min-height: 200px;
                            }
                            @media (max-width: 500px){
                                height: 105px;
                                min-height: 105px;
                                margin-left: 23px;
                                width: 90%;
                            }
                        }
                        .carousel__num{
                            position: absolute;
                            left: 0px;
                            top: -5px;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 24px;
                            line-height: 31px;
                            transition: all .2s ease-in-out;
                            color: #F3385A;
                        }
                        @media (max-width: 500px){
                            .carousel__num{
                                font-size:18px;
                            }
                        }
                    }

                    .hashtags {
                        display: flex;
                        color: #fff;
                        flex-wrap: wrap;
                        margin-left: 40px;
                
                        @media (max-width: 500px) { 
                            margin-left: 23px;
                        }
                
                        span {
                            color: #F3385A;
                            font-weight: 500;
                            margin-right: 19px;
                            font-size: 12px; 
                            text-transform: uppercase;
                            padding-top: 15px;
                            letter-spacing: 0.2em;
                            font-weight: 600;
                
                            @media (max-width: 667px) { 
                                font-size: 9px;
                                margin-right: 8px;
                                padding-top: 5px;
                            }
                        }
                    }
                   
                    .carousel-cell[aria-hidden="true"]{
                        .carousel__text{
                            h3, p{
                                color: rgba(255, 255, 255, 0.6);
                            }
                        }
                        .carousel__num{
                            color: #333;
                        }
                    }
                    .carousel-cell:hover > .carousel__num{
                        color: #F3385A;
                    }
                    .carousel-cell:hover > .carousel__text h3{
                        color: rgba(255, 255, 255, 1);
                    }
                    .carousel-cell:hover > .carousel__text p{
                        color: rgba(255, 255, 255, 1);
                    }
                    .carousel-cell:hover > .carousel__image .background-image{
                        transform: scale(1.1);
                    }
                }
                .gallery:after{
                    content: "";
                    position: absolute;
                    right: 0;
                    top: 0;
                    height: 100%;
                    width: 150px;
                    background: linear-gradient(270deg, #0F1615 0%, rgba(15, 22, 21, 0) 100%);
                    @media (max-width: 500px){
                        width: 50px;
                    }
                }
                .animation_carousel:before{
                    width: 100%;
                    height: 100%;
                    content: "";
                    position: absolute;
                    right: 0;
                    top: 0;
                    background-color:#0F1615;
                    z-index: 100;
                    transform: translateX(0%);
                    animation: galerybefore 3s ease-in-out;
                    animation-duration: 1s;
                    animation-fill-mode: forwards;
                }
            }
            svg{
                pointer-events: none;
                position: absolute;
                top: -190px;
                right: 0;
                .line{
                    stroke-dasharray: 2800px;
                    stroke-dashoffset: 2800px;
                }
            }
            @media (min-width: 2200px) {
                svg{
                   display: none;
                }
            }
            @media (max-width: 1280px){
                svg{
                    top: -168px;
                }
            }
            @media (max-width: 1050px){
                svg{
                    width: 100%;
                    top: -266px;
                }
            }
            @media (max-width: 950px){
                svg{
                    left: -4%;
                    width: 106%;
                    top: -260px;
                }
            }
            @media (max-width: 500px){
                svg{
                    width: 900px;
                    top: -205px;
                    left: -428px;
                }
            }
        }
        .section5{
            width: 100%;
            margin-bottom: 0;
            @media (max-width: 950px){
                margin-bottom: 0;
            }
            .bg{
                z-index: 2;
                position: relative;
                background: #FFFFFF;
                height: auto;
                @media (max-width: 950px){
                    text-align: center;
                    height: auto;
                }
                .columns{
                    padding-top: 125px;
                    padding-bottom: 100px;
                    @media (max-width: 950px){
                        padding-top: 50px;
                        padding-bottom: 50px;
                    }
                    .header{
                        .headline h2{
                            color: $headline;
                            font-style: normal;
                            font-weight: 800;
                            font-size: 14px;
                            line-height: 18px;
                            letter-spacing: 0.2em;
                            text-transform: uppercase;
                        }
                        .title {
                            margin-top: 57px;
                            width: 400px;
                            h2{
                                font-style: normal;
                                font-weight: 600;
                                font-size: 52px;
                                line-height: 106.1%;
                                color: #000000;
                                
                            }
                        } 
                        @media (max-width: 1800px){
                            .title {
                                width: 95%;
                                h2{
                                    font-size: 37px;
                                    line-height: 106.1%;
                                }
                            }
                        }
                        @media (max-width: 1050px){
                            .title {
                                margin-top: 15px;
                            }
                        }
                        @media (max-width: 950px){
                            .title {
                                h2{
                                    font-size: 24px;
                                    line-height: 27px;
                                }
                            }
                            .headline h2{
                                font-size: 10px;
                                line-height: 12px;
                            }
                        }
                    }
                    p.text{
                        opacity: 0;
                        margin-left: 50px;
                        width: 644px;
                        font-style: normal;
                        font-weight: 200;
                        font-size: 36px;
                        line-height: 106.1%;
                        color: #000807;
                    }
                    @media (max-width: 1800px){
                        p.text{
                            width: 450px;
                            font-size: 26px;
                            line-height: 106.1%;
                        }
                    }
                    @media (max-width: 1050px){
                        p.text{
                            width: 400px;
                            font-size: 22px;
                        }
                    }
                    @media (max-width: 950px){
                        p.text{
                            width: 90%;
                            margin: 0 auto;
                            font-size: 22px;
                            line-height: 23px;
                        }
                    }
                }
                .video {
                    position: relative;
                    height: 700px;
                    width: 1420px;
                    //background-image: url('../../images/video.webp');
                    background-position: center;
                    background-size: cover;
                    span{
                        z-index: 0;
                        color: #fff;
                        position: absolute;
                        bottom: 40px;
                        right: 40px;
                        text-transform: uppercase;
                        img{
                            margin-right: 10px;
                        }
                    }
                    @media (min-width: 3300px){
                        height: 1000px;
                        width: 2000px;
                    }
                    @media (max-width: 1800px){
                        height: 500px;
                        width: 1000px;
                    }
                    @media (max-width: 1050px){
                        height: 500px;
                        width: 80%;
                    }
                    @media (max-width: 950px){
                        height: 350px;
                        width: 100%;
                    }
                    @media (max-width: 500px){
                        height: 240px;
                    }
                }
                .black_wall{
                    background-color: #000807;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    width: 100%;
                    margin:auto;
                    height: 100%;
                    transform: translate(50%);
                    transition: all .2s ease-in-out;
                }
                .black_wall:after{
                    content: "";
                    position: absolute;
                    transform: skewX(-5deg);
                    bottom: 0;
                    left: -78px;
                    width: 200px;
                    height: 100%;
                    border-left: 177px solid #FFFFFF;
                }
            }
        }
        .section6{
            padding-bottom: 230px;
            position: relative;
            svg.line{
                pointer-events: none;
                z-index: 1;
                position: absolute;
                top: -500px;
                right: 0;
                .line_path{
                    stroke-dasharray: 1180px;
                    stroke-dashoffset: 800px;
                }
            }
            @media (max-width: 1800px){
                svg.line{
                    top: -600px;
                }
            }
            @media (max-width: 950px){
                svg.line{
                    top: -650px;
                }
            }
            .columns{
                padding-top: 605px;
                padding-bottom: 50px;
                @media (min-width: 3300px){
                    padding-top: 900px;
                }
                @media (max-width: 1800px){
                    padding-top: 370px;
                }
                @media (max-width: 950px){
                    text-align: center;
                    padding-top: 200px;
                    padding-bottom: 0px;
                }
                .header{
                    .headline h2{
                        color: $headline;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 18px;
                        letter-spacing: 0.2em;
                        text-transform: uppercase;
                    }
                    .title {
                        margin-top: 18px;
                        h1{
                            font-style: normal;
                            font-weight: 200;
                            font-size: 52px;
                            line-height: 106.1%;
                            color: #fff;
                            
                        }
                    } 
                    @media (max-width: 1800px){
                        .title {
                            h1{
                                font-size: 45px;
                                line-height: 45px;
                            }
                        }
                    }
                    @media (max-width: 1050px){
                        .title {
                            h1{
                                font-size: 40px;
                                line-height: 40px;
                            }
                        }
                        .headline {
                            h2{
                                font-size: 12px;
                            }
                        }
                    }
                    @media (max-width: 950px){
                        .title {
                            margin-top: 3px;
                            h1{
                                font-size: 24px;
                                line-height: 27px;
                            }
                        }
                        .headline {
                            h2{
                                font-size: 10px;
                            }
                        }
                    }
                }
                .s_button{
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-end;
                    a.Mybtn{
                        rect{
                            stroke-dasharray: 561px;
                            stroke-dashoffset: 561px;
                        }
                        position: relative;
                        display: block;
                        overflow: hidden;
                        @include size(100%, 65px);
                        max-width: 243px;
                        border-radius: 30px;
                        span {
                        @include absolute();
                        display: block;
                        @include size(0);
                        border-radius: 50%;
                        background-color:  #F3385A;
                        transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
                        transform: translate(-50%, -50%);
                        }
    
                        &:hover {
                            span {
                                @include size(225%, 243px*2.25);
                            }
                            @media (max-width: 950px){
                                span {
                                    @include size(0%, 243px*2.25);
                                }
                            }
                        }
                        .text{
                            position: absolute;
                            top: 0;
                            left: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            height: 100%;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 34px;
                            color: transparent;
                            animation-fill-mode: forwards;
                        }
                    }
                }
                @media (max-width: 950px){
                    .s_button{
                        position: absolute;
                        bottom: 120px;
                        left: 0;
                        right: 0;
                        margin: auto;
                        a.Mybtn{
                            margin: 0 auto;
                        }
                    }
                }
            }
            .carousel{
                position: relative;
                &:after{
                    content: "";
                    position: absolute;
                    right: 0;
                    top: 0;
                    height: 100%;
                    width: 150px;
                    background: linear-gradient(270deg, #000807 0%, rgba(0, 8, 7, 0) 100%);
                    @media (max-width: 500px){
                        width: 50px;
                    }
                }
                .flickity-slider {
                    left: -14.2% !important;
                }
                @media (min-width: 2200px) {
                    .flickity-slider{
                        left: -20.5% !important;
                    }
                }
                @media (min-width: 3300px) {
                    .flickity-slider{
                        left: -24.5% !important;
                    }
                }
                @media (max-width: 800px){
                    .flickity-slider{
                        left: -20.2% !important;
                    }
                }
                @media (max-width: 500px){
                    .flickity-slider{
                        left: -6% !important;
                    }
                }
                .carousel-cell {
                    width: 36vw;
                    margin-right: 32px;
                    @media (min-width: 2200px) {
                        width: 25vw;
                    }
                    @media (min-width: 3300px) {
                        width: 18vw;
                    }
                    @media (max-width: 800px){
                        width: 47vw;
                    }
                    @media (max-width: 500px){
                        width: 240px;
                        margin-right: 10px;
                    }
                    .carousel__text{
                        opacity: 0;
                        transition: all 1s ease-in-out;
                        margin-top: 41px;
                        h1{
                            font-style: normal;
                            font-weight: 300;
                            font-size: 30px;
                            line-height: 38px;
                            color: #FFFFFF;
                        }
                        p{
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 26px;
                            color: #FFFFFF;
                        }
                        .tags{
                            margin-top: 12px;
                            display: flex;
                            align-items: center;
                            span{
                                font-style: normal;
                                font-weight: 600;
                                font-size: 14px;
                                line-height: 34px;
                                color: #F3385A;
                                margin-right: 8px;
                            }
                        }
                        @media (max-width: 1800px){
                        }
                        @media (max-width: 500px){
                            margin-top: 19px;
                            h1{
                                font-size: 18px;
                                line-height: 23px;
                            }
                            p{
                                font-size: 12px;
                                line-height: 15px;
                                margin-top: 7px;
                                width: 80%;
                            }
                            .tags{
                                margin-top: 3px;
                                span{
                                    font-size: 12px;
                                    margin-right: 10px;
                                }
                            }
                        }
                    }
                    .carousel__image {
                        position: relative;
                        width: 100%;
                        height: 335px;
                        max-height: 400px;
                        min-height: 300px;
                        margin: 0;
                        overflow: hidden;
                        display: block;
                        .background-image {
                            position: relative;
                            top: 0;
                            left: 0;
                            width: 101%;
                            height: 100%;
                            background-size: cover;
                            background-position: center;
                            transition: all 1s ease-in-out;
                        }
                        @media (max-width: 1800px){
                            &{
                                height: 300px;
                            }
                        }
                        @media (max-width: 1050px){
                            &{
                                height: 200px;
                                min-height: 200px;
                            }
                        }
                        @media (max-width: 500px){
                            height: 155px;
                            min-height: 155px;
                        }
                        .black_wall{
                            background-color: #000807;
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            left: 0;
                            width: 105%;
                            margin: auto;
                            height: 100%;
                            transform: translate(-3%);
                            transition: all .6s ease-in-out;
                        }
                        .black_wall:after{
                            content: "";
                            position: absolute;
                            transform: skewX(11deg);
                            bottom: 0;
                            width: 0;
                            left: -35px;
                            height: 100%;
                            border-left: 130px solid #000807;
                        }
                    }
                }
                .carousel-cell:hover > .carousel__image .background-image{
                    transform: scale(1.1);
                }
            }
        }
        .section7{
            position: relative;
            min-height: 660px;
            width: 100%;
            @media (max-width: 950px){
                height: auto;
            }
            svg.svg_line{
                position: absolute;
                top: -120px;
                pointer-events: none;
                .line{
                    stroke-dasharray: 2800px;
                    stroke-dashoffset: 2800px;
                    will-change: stroke-dashoffset;
                }
                @media (min-width: 2200px) {
                    display: none;
                }
                @media (max-width: 1800px){
                    top: -150px;
                }
                @media (max-width: 1050px){
                    width: 100%;
                    top: -300px;
                }
                @media (max-width: 500px){
                    width: auto;
                    top: -120px;
                    left: -600px;
                }
            }
            .bg{
                width: 100%;
                height: 100%;
                background-color:#181A1A;
                padding-top: 90px;
                padding-bottom: 100px;

                @media (max-width: 667px) {
                    padding-bottom: 40px;
                    padding-top: 60px;
                }
    
                .recenzie{
                    width: 100%;
                    position: relative;
                    @media (max-width: 950px){
                        width: 100%;
                        padding-bottom: 80px;
                        text-align: center;
                    }
                    .line-anim {
                        animation: lineAnim 2s ease-in-out forwards;
                    }
                    .line_one{
                        position: absolute;
                        right: 0;
                        top: -300px;
                        transform: translate(65%, 0) rotate(50deg);
                        z-index: 6000;

                        .line_path{
                            stroke-dasharray: 1340px;
                            stroke-dashoffset: 1340px;
                        }
                    }
                    @media (max-width: 1800px) {
                        .line_one{
                            width: 450px;
                        }
                    }
    
                    @media (max-width: 950px){
                        .line_one {
                            width: 226px;
                        }
                    }
    
                    @media (max-width: 667px){
                        .line_one {
                            display:none;
                        } 
                    }
                    .reveal_wrap {
                        width: 65%;
                        margin: 0 auto;
                        position: relative;
                    }
                    .recenzia{
                        background: #FFFFFF08;
                        position: relative;
                        border-radius: 24px;
                        padding: 40px;

                        @media (max-width: 667px) {
                            padding: 25px 15px;
                            text-align: left;
                        }

                    }
                    .recenzia.active{
                        width: 100%;
                        display: block;
                    }
                }
                .swiper-wrapper {
                    padding-top: 50px;

                    @media (max-width: 667px) {
                        padding-top: 25px;
                    }
                }
                .swiper-slide {
                    opacity: 0.3;
                    will-change: opacity;
                    transition: opacity .35s ease-in-out;
                    @media (max-width: 667px) {
                        opacity: 1;
                    }
                }
            
                .swiper-slide.swiper-slide-active {
                    opacity: 1;
                }
                .columns{
                    .is-5{
                        position: relative;
                    }
                }
                .header{
                    .headline{
                        h2{
                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 18px;
                            letter-spacing: 0.2em;
                            text-transform: uppercase;
                            color: #fff;   
                            display: inline-block;
                            background: -webkit-linear-gradient(180deg, #F11341, #FFA5B7);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }
                    }
                    .title {
                        margin-top: 8px;
                        margin-bottom: 70px;

                        @media (max-width: 992px){ 
                            margin-bottom: 120px;
                        }
                        @media (max-width: 667px){
                            margin-bottom: 60px;
                        }
                        h2{
                            font-style: normal;
                            font-weight: 200;
                            font-size: 46px;
                            line-height: 106.1%;
                            color: #FFFFFF;
                            
                        }
                    }
                    @media (max-width: 1800px){
                        .title {
                            h2{
                                font-size: 36px;
                                line-height: 106.1%;
                            }
                        }
                    }
                    @media (max-width: 600px){
                        .headline{
                            h2{
                                display: flex;
                                justify-content: center;
                            }
                        }
                        .title{
                            h2{
                                display: flex;
                                justify-content: center;
                            }
                        }
                    }
                }
                .uvodzovky{
                    display: none;
                    position: absolute;
                    top: -45px;
                    left: 50px;
                }
                @media (max-width: 667px){
                    .uvodzovky{
                        margin: 30px auto 0px auto;
                        max-width: 50px;
                        left: 25px;
                    }
                }
                .animation_11{
                    transition: all .5s ease-in-out;
                }
                p.text{
                    opacity: 1;
                    width: 100%;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 1.65;
                    letter-spacing: -0.015em;
                    margin-bottom: 13px;
                    color: #fff;
                }
                a.z_cele{
                    opacity: 1;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 24px;
                    letter-spacing: -0,015em;
                    text-decoration: underline;
                    color: #fff;
                }
                .brandlogo {
                    margin-top: 30px;
                }
                .meno{
                    font-size: 18px;
                    margin-top: 25px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 24px;
                    letter-spacing: -0,015em;
                    color: #fff;
                }
                .veduci{
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 24px;
                    letter-spacing: -0,015em;
                    color: #fff;
                    opacity: 0.5;
                }
                @media (max-width: 1440px){
                    p.text{
                        width: 100%;
                        font-size: 22px;
                        line-height: 36px;
                    }
                    a.z_cele{
                        font-size: 14px;
                        line-height: 20px;
                    }
                    .meno{
                        font-size: 14px;
                        line-height: 20px;
                    }
                    .veduci{
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
                @media (max-width: 1050px){
                    p.text{
                        width: auto;
                        font-size: 19px;
                        line-height: 28px;
                    }
                }
                @media (max-width: 600px){
                    p.text{
                        width: 98%;
                        margin:0 auto auto;
                        font-size: 17px;
                        line-height: 28px;
                        text-align: left;
                    }
                    a.z_cele{
                        display: flex;
                        justify-content: center;
                        margin-top:20px;
                        text-align: left;
                    }
                    .meno{
                        margin-top:20px;
                        text-align: left;
                    }
                    .veduci{
                        text-align: left;
                    }
                }
                .zisky{
                    position: relative;
                    width: 100%;
                    height: 100%;
                    @media (max-width: 950px){
                        height: 300px;
                    }
                    .zisk0{
                        position: absolute;
                        bottom: 0px;
                        right: 0;
                        height: 217px;
                        width: 440px;
                        background-image: url('../images/zisk0.svg');
                        background-size: contain;
                        background-repeat: no-repeat;
                        box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.15);
                        border-radius: 3px;
                        transform: scale(0);
                        @media (max-width: 1280px){
                            &{
                                height: 170px;
                                width: 335px;
                                bottom: 5px;
                                right: -40px;
                            }
                        } 
                        @media (max-width: 950px){
                            height: 150px;
                            width: 80%;
                            right: 0;
                            bottom: 0;
                        }
                    }
                    .zisk1{
                        bottom: 160px;
                        right: 70px;
                        position: absolute;
                        height: 291px;
                        width: 441px;
                        background-image: url('../images/zisk1.svg');
                        background-size: contain;
                        background-repeat: no-repeat;
                        filter: drop-shadow(0px 24px 24px rgba(0, 0, 0, 0.25));
                        border-radius: 3px;
                        transform: scale(0);
                        @media (max-width: 1280px){
                            &{
                                top: 55px;
                                left: 97px;
                                height: 230px;
                                width: 338px;
                            }
                        } 
                        @media (max-width: 950px){
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 40px;
                            width: 76%;
                            margin: auto;
                        }
                    }
                    .zisk2{
                        right: 300px;
                        bottom: 180px;
                        position: absolute;
                        height: 61px;
                        width: 291px;
                        background-image: url('../images/zisk2.svg');
                        background-size: contain;
                        background-repeat: no-repeat;
                        filter: drop-shadow(0px 24px 24px rgba(0, 0, 0, 0.25));
                        transform: scale(0);
                        border-radius: 3px;
                        @media (max-width: 1280px){
                            &{
                                left: 4px;
                                top: 216px;
                                height: 47px;
                                width: 223px;
                            }
                        } 
                        @media (max-width: 950px){
                            left: 0;
                            top: 128px;
                            height: inherit;
                            width: 180px;
                        }
                    }
                    .zisk3{
                        bottom: 350px;
                        right: 46px;
                        position: absolute;
                        height: 41px;
                        width: 65px;
                        background-image: url('../images/zisk3.svg');
                        box-shadow: 0px 24px 24px rgba(0, 0, 0, 0.25);
                        border-radius: 3px;
                        transform: scale(0);
                        @media (max-width: 1280px){
                            &{
                                top: 106px;
                                right: 7px;
                            }
                        } 
                        @media (max-width: 1050px){
                            display: none;
                        }
                    }
                    .zisk_animated{
                        animation: zisk 0.7s ease-in-out;
                        animation-fill-mode: forwards;
                    }
                }
                .foto{
                    position: relative;
                    width: 500px;
                    img{
                        filter: drop-shadow(0px 24px 24px rgba(0, 0, 0, 0.25));
                        transform: scale(0);
                    }
                    .zisk_animated{
                        animation: zisk 0.7s ease-in-out;
                        animation-fill-mode: forwards;
                    }
                }
    
            }
            .black_wall{
                background-color: #000807;
                position: absolute;
                bottom: 0;
                right: 0;
                left: 0;
                width: 110%;
                margin:auto;
                height: 100%;
                transform: translate(-5%);
                transition: all 1s ease-in-out;
                @media (max-width: 950px){
                    transform: translate(110%);
                }
                @media (max-width: 667px){
                    display: none;
                }
            }
            .black_wall:after{
                content: "";
                position: absolute;
                left: -1px;
                border-left: 150px solid #000;
                border-top: 660px solid transparent;
            }

            .flickity-ready .carousel-cell {
                height: 100%;
            }

            .carousel-cell  {
                padding: 60px 0;
                margin: 0 20px;
                width: 25%;
                opacity: 0.3;
                min-height: 100%;
                display: flex;
                transition: opacity .5s ease-in-out;

                @media (max-width: 1440px) {
                    width: 38%;
                }

                @media (max-width: 992px) {
                    width: 52%;
                }

                .uvodzovky.is-active {
                    display: none !important;
                }

                &.is-selected .uvodzovky {
                    display: none;
                }

                &.is-selected .uvodzovky.is-active {
                    display: block !important;
                }

                .text {
                    font-size: 18px;
                    line-height: 1.5;
                }

                &.is-selected {
                    opacity: 1;
                }

                .recenzia {
                    height: inherit;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }

                @media (max-width: 667px) {
                    width: 92%;
                    margin: 0 10px;
                }
            }
            .flickity-button {
                background: #fff;
                cursor: pointer;
                width: 72px;
                height: 72px;
                margin: 0 5px;
                border-radius: 24px;
                transition: all .35s ease-in-out;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    max-width: 15px;
                    height: 15px;
                    position: static;
                    path {
                        fill: #363636;
                        transition: all .35s ease-in-out;
                    }
                }

                &:hover,
                &:focus,
                &:active {
                    background: #F11341;
                    outline: none;
                    svg {
                        path {
                            fill: #fff;
                            transition: all .35s ease-in-out;
                        }
                    }
                }

                @media (max-width: 667px) {
                    width: 50px;
                    height: 50px;
                }
            }

            .flickity-prev-next-button.previous {
                top: -80px;
                left: auto;
                right: 380px;

                @media (max-width: 1024px) {
                    right:400px;
                }

                @media (max-width: 992px) {
                    right: auto;
                    left: 40%;
                    top: -40px;
                }

                @media (max-width: 667px) {
                    right: auto;
                    top: 0;
                    left: 40%;
                }

                @media (max-width: 375px) {
                    left: 32%;
                }
            }
            
            .flickity-prev-next-button.next {
                top: -80px;
                left: auto;
                right: 300px;
          
                @media (max-width: 1024px) {
                    right:315px;
                }

                @media (max-width: 992px) {
                    right: auto;
                    left: 50%;
                    top: -40px;
                }

                @media (max-width: 667px) {
                    top: 0;
                    right:auto;
                    left: 51%;
                }
            }

            /* OLd code for arrow navigation */
            .reviews-buttons {
                position: absolute;
                top: 20px;
                right: 60px;
                display: flex;

                @media (max-width: 1440px) {
                    right:125px;
                }

                @media (max-width: 667px) {
                    position: static;
                    margin: 0 auto;
                    padding: 20px 0;
                    justify-content: center;
                }

                >div,
                button {
                    border-radius: 50%;
                    background: none;
                    cursor: pointer;
                    width: 65px;
                    height: 65px;
                    margin: 0 10px;
                    border: 2px solid #363636;
                    transition: all .35s ease-in-out;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                        max-width: 15px;
                        height: 15px;
                        path {
                            fill: #363636;
                            transition: all .35s ease-in-out;
                        }
                    }

                    &:hover,
                    &:active {
                        background: #3FA9F5;
                        border: 2px solid #3FA9F5;

                        svg {
                            path {
                                fill: #fff;
                                transition: all .35s ease-in-out;
                            }
                        }
                    }
                }
            }
        }
        .section8{
            padding-top: 111px;
            padding-bottom: 200px;
            @media (max-width: 1050px){
                padding-top: 100px;
                padding-bottom: 100px;
            } 
            @media (max-width: 950px){
                padding-top: 0px;
                padding-bottom: 60px;
                text-align: center;
            }
            .columns{
                .header{
                    .headline h2{
                        color: $headline;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 18px;
                        letter-spacing: 0.2em;
                        text-transform: uppercase;
                    }
                    .title {
                        margin-top: 18px;
                        h1{
                            font-style: normal;
                            font-weight: 200;
                            font-size: 46px;
                            line-height: 106.1%;
                            color: #fff;
                            
                        }
                    }
                    @media (max-width: 1800px){
                        .title {
                            h1{
                                font-size: 45px;
                                line-height: 106.1%;
                            }
                        }
                        .headline h2{
                            font-size: 11px;
                            line-height: 18px;
                        }
                    } 
                    @media (max-width: 1050px){
                        .title {
                            h1{
                                font-size: 40px;
                                line-height: 40px;
                            }
                        }
                        .headline h2{
                            font-size: 12px;
                        }
                    } 
                    @media (max-width: 950px){
                        .title {
                            margin-top: 3px;
                            h1{
                                font-size: 24px;
                                line-height: 27px;
                            }
                        }
                        .headline {
                            h2{
                                font-size: 10px;
                            }
                        }
                    }
    
                }
                @media (max-width: 950px){
                    .column{
                        padding-bottom: 0;
                    }
                }
                .c_blog{
                    position: relative;
                    display: flex;
                    margin-top: 69px;
                    height: 580px;
                    @media (max-width: 1050px){
                        margin-top: 20px;
                        height: 550px;
                    } 
                    @media (max-width: 950px){
                        display: block;
                        height: auto;
                        margin-top: 0;
                    }
                    .o_blog{
                        margin-right: 106px;
                        display: flex;
                        width: 392px;
                        height: 100%;
                        @media (max-width: 1050px){
                            width: 260px;
                        } 
                        @media (max-width: 950px){
                            margin: 0 auto;
                        } 
                        .all{
                            width: 100%;
                            height: 479px;
                            @media (max-width: 950px){
                                height: auto;
                                margin-bottom: 37px;
                            }
                            .image{
                                height: 320px;
                                overflow: hidden;
                                .img{
                                    transition: all 1s ease-in-out;
                                    background-position: center;
                                    background-size: cover;
                                    width: 100%;
                                    height: 100%;
                                }
                                @media (max-width: 1050px){
                                    height: 250px;
                                }
                                @media (max-width: 950px){
                                    height: 150px;
                                }
                            }
                            .title{
                                font-style: normal;
                                font-weight: 200;
                                font-size: 30px;
                                line-height: 38px;
                                color: #FFFFFF;
                                margin-top: 29px;
                                margin-bottom: 14px;
                            }
                            p.text{
                                font-style: normal;
                                font-weight: 200;
                                font-size: 14px;
                                line-height: 26px;
                                color: #FFFFFF;
                            }
                            @media (max-width: 1050px){
                                .title{
                                    font-size: 25px;
                                    margin-top: 17px;
                                    margin-bottom: 14px;
                                }
                                p.text{
                                    font-size: 11px;
                                    line-height: 18px;
                                }
                            } 
                        }
                        .all a:hover > .image .img{
                            transform: scale(1.1);
                        }
                    }
                    .o_blog_top{
                        align-items: baseline;
                    }
                    .o_blog_bot{
                        align-items: flex-end;
                    }
                    .black_wall{
                        z-index: 10;
                        background-color: #000807;
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        left: 0;
                        width: 100%;
                        margin: auto;
                        height: 100%;
                        transform: translate(0%);
                        transition: all 1s ease-in-out;
                    }
                    .black_wall::after{
                        content: "";
                        position: absolute;
                        left: -15px;
                        top: -90px;
                        border-right: 17px solid #000807;
                        border-top: 660px solid transparent;
                    }
                }
                a.Mybtn{
                    margin-top: 84px;
                    rect{
                        stroke-dasharray: 561px;
                        stroke-dashoffset: 561px;
                    }
                    position: relative;
                    display: block;
                    overflow: hidden;
                    @include size(100%, 65px);
                    max-width: 175px;
                    border-radius: 30px;
                    span {
                    @include absolute();
                    display: block;
                    @include size(0);
                    border-radius: 50%;
                    background-color:  #F3385A;
                    transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
                    transform: translate(-50%, -50%);
                    }
    
                    &:hover {
                        span {
                            @include size(225%, 175px*2.25);
                        }
                        @media (max-width: 950px){
                            span {
                                @include size(0%, 175px*2.25);
                            }
                        }
                    }
                    cursor: pointer;
                    .text{
                        position: absolute;
                        top: 0;
                        left: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 100%;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 34px;
                        color: transparent;
                        animation-fill-mode: forwards;
                    }
                    @media (max-width: 1050px){
                        &{
                            margin-top: 0px;
                        }
                    } 
                    @media (max-width: 950px){
                        margin: 0 auto;
                        font-size: 14px;
                    } 
                }
            }
        }
    } // end Home
    .AboutUs{
        overflow: hidden;
        header {
            .AU_animation1 {
                transform: translateX(-650px);
            }
            .AU_animation2 {
                transition: all 2s ease-in-out;
                opacity: 0;
            }
            position: relative;
            .header{
                display:flex;
                justify-content:space-between;
                align-items: center;
                padding:82px 0 0 0;
                width:1700px;
                margin:0 auto;
                position: relative;
                z-index: 2000;
                .text, a {
                    transition: .5s ease-in-out all;

                    @media (max-width: 667px){
                       img { max-width: 125px; }
                    }
                }
                @media (max-width: 1700px){
                        width: 90%;
                }
   
                @media (max-width: 768px) {
                    .line_one{
                        width: 450px;
                    }
                }
                @media (max-width: 720px){
                    width:100%;
                }
                @media (max-width: 500px){
                    display: block;
                }
                .left {
                    color:#fff;
                    font-weight: bold;
                    height: 50px;
                    cursor: pointer;
                    @media (max-width: 992px){
                        padding-left: 15px;
                    }
                    span{
                        font-weight: 200; 
                        display:block;
                    }
                }
                .right {
                    color:#fff;
                    font-weight: bold;
                    cursor: pointer;
                    @media (max-width: 500px){
                        margin-top: 20px;
                    }
                }
            }
            .header-container{
                width:1240px;
                margin:90px auto 0 auto;
                @media (max-width: 1450px){
                    width:1000px;
                }
                @media (max-width: 1240px){
                    width: 85%;
                }
                @media (max-width: 1050px){
                    margin-top: 50px;
                }
                @media (max-width: 900px){
                    width: 90%;
                }

                @media (max-width: 950px){
                    &{
                        margin: 0 auto;
                        text-align: center;
                        width: 95%;
                        padding-left: 0px;
                    }
                }
                .service{
                    color: $headline;
                    letter-spacing: 0.2em;
                    text-transform: uppercase;
                    font: {
                        style: normal;
                        weight: 600;
                        size: 14px;
                    }
                    line-height: 18px;
                }
                .text{
                    width:638px;
                    @media (max-width: 900px){
                        width: 90%;
                        margin:15px auto 0 auto;
                    }
                    h1{    
                        margin: {
                            top: 15px;
                        }
                        color: #fff;
                        font: {
                            style: normal;
                            weight: 600;
                            size: 52px;
                        }
                        line-height: 59px;
                    }
                }
                @media (max-width: 1440px) {
                    .text h1{
                        font: {
                            size:32px;
                        }
                        line-height: 39px;
                    }
                }
                @media (max-width: 950px){
                }
            }
            .header-container_2{
                margin: {
                    top:92px;
                    left: auto;
                    right:auto;
                }
                width:1240px;
                @media (max-width: 1450px){
                    width:1000px;
                }
                @media (max-width: 1240px){
                    width: 85%;
                }
                @media (max-width: 900px){
                    width: 90%;
                }
                .grid{
                    display:flex;
                    @media (max-width: 720px){
                        display:block;
                    }
                    .header_box{
                        width: 390px;
                        margin-right:250px;
                        margin-bottom:49px;
                        @media (max-width: 1050px){
                            margin-right: 80px;
                        }
                        @media (max-width: 720px){
                            margin:60px auto 20px auto;
                            width:90%;
                        }
                        h3{
                            margin: {
                                bottom:25px;
                            }
                            width: 275px;
                            color: #fff;
                            font: {
                                style: normal;
                                weight: 600;
                                size: 30px;
                            }
                            line-height: 38px;
                            @media (max-width: 720px){
                                margin-bottom:15px;
                                width:100%;
                            }
                        }
                        p{
                            @media (max-width: 900px){
                                width: 95%;
                                margin:0 auto;
                            }
                            color: #D1D5DB;
                            font: {
                                style: normal;
                                weight: 400;
                                size: 16px;
                            }
                            line-height: 29px;
                        }
                    }
                }
                @media (max-width: 720px){
                    text-align:center;
                }
            }
            svg.header_line{
                position: absolute;
                bottom: -350px;
                z-index: 1;  
                .line{
                    stroke-dasharray: 8500px;
                    stroke-dashoffset: 8500px;
                }
                @media (max-width: 1700px){
                    bottom: -410px;
                    left: -40px;
                }
                @media (max-width: 1500px){
                    width: 15%;
                    bottom: -437px;
                }
                @media (max-width: 1100px){
                    width: 23%;
                }
                @media (max-width: 600px){
                    width: 34%;
                }
                @media (max-width: 330px){
                    width: 50%;
                }
            }
        }
        .section2{
            background-color: #F8F8F8;
            padding:69px 0;
            position: relative;
            z-index: 2;
            @media (max-width: 620px){
                padding:25px 0;
            }
            .wall{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #000807;
                z-index: 5;
                transform: translateX(0);
                transition: all 2s ease-in-out;
                &:after{
                    content: "";
                    position: absolute;
                    left: -179px;
                    border-right: 180px solid #000807;
                    border-top: 300px solid transparent;
                    @media (max-width: 721px){
                        left: 0px;
                    }
                }
            }
            .brands-container{
                width:1100px;
                margin:0 auto;
                @media (max-width: 1100px){
                    width: 85%;
                }
                @media (max-width: 900px){
                    width: 90%;
                }
                .first_img_col{
                    display:flex;
                    justify-content:space-between;
                    align-items: center;
                    img{
                        margin-bottom:50px;
                        @media (max-width: 720px){
                            width: 150px;
                        }
                    }
                    @media (max-width: 720px){
                        display: grid;
                        grid-template-columns: 2fr 2fr;
                        grid-gap: 31px;
                        margin: 0 auto;
                        align-items: center;
                        text-align: center;
                        width: 100%;
                    }
                }
                .second_img_col{
                    display:flex;
                    justify-content:space-between;
                    align-items: center;
                    margin: 0 40px;
                    img{
                        @media (max-width: 720px){
                            width: 150px;
                        }
                    }
                    @media (max-width: 720px){
                        display: grid;
                        grid-template-columns: 2fr 2fr;
                        grid-gap: 60px;
                        margin: 0 auto;
                        align-items: center;
                        text-align: center;
                        width: 100%;
                    }
                }
                @media (max-width: 720px){
                    padding:10px; 
                    margin:0 auto;
                }
            }
        }
        .section3{
            .AU_animation3{
                transition: all 1.5s ease-in-out;
                transform: translateX(-1500px);
            }
            padding-top: 81px;
            padding-bottom: 129px;
            background-color: #FFFFFF;
            position: relative;
            @media (max-width: 900px){
                padding-bottom: 40px;
            }
            .aboutus-container{
                width: 1240px;
                margin:0 auto;
                @media (max-width: 1300px){
                    width: 85%;
                }
                @media (max-width: 950px){
                    margin: 0 auto;
                    text-align: center;
                    width: 95%;
                    padding-left: 0px;
                }
                @media (max-width: 900px){
                    width: 90%;
                }
                .service{
                    color: $headline;
                    letter-spacing: 0.2em;
                    text-transform: uppercase;
                    font: {
                        style: normal;
                        weight: 600;
                        size: 14px;
                    }
                    line-height: 18px;
                }
                .text{
                    width: 638px;
                    @media (max-width: 900px){
                        width: 95%;
                        margin:15px auto 0 auto;
                    }
                    h1{    
                        margin: {
                            top: 15px;
                            bottom: 40px;
                        }
                        color: #333333;
                        font: {
                            style: normal;
                            weight: 400;
                            size: 46px;
                        }
                        line-height: 59px;
                    }
                }
                @media (max-width: 1440px) {
                    .text h1{
                        font: {
                            size:32px;
                        }
                        line-height: 39px;
                    }
                }
                @media (max-width: 950px){
                    .text h1{
                        margin-top: 3px;
                        font-size: 22px;
                        line-height: 26px;
                    }
                }
            }
            .first-p{
                width:1240px;
                color: #333333;
                margin:0 auto;
                @media (max-width: 1300px){
                    width: 85%;
                }
                @media (max-width: 950px){
                    margin: 0 auto;
                    margin-top: 10px;
                    width: 80%;
                }
                p{
                    width:600px;
                    font: {
                        style: normal;
                        weight: 300;
                        size: 20px;
                    }
                    line-height: 31px;
                    @media (max-width: 950px){
                        &{
                            margin: 0 auto;
                            text-align: center;
                            width: 100%;
                        }
                    }
                }
            
            }
            .second-p{
                width:1240px;
                margin:25px auto 61px auto;
                color: #333333;
                @media (max-width: 1300px){
                    width: 85%;
                }
                @media (max-width: 950px){
                    margin: 25px auto 60px auto;
                    width: 85%;
                }
                p{  
                    span{
                        font: {
                            style: normal;
                            weight: 900;
                            size: 20px;
                        }
                    }
                    line-height: 31px;
                    width:600px;
                    font: {
                        style: normal;
                        weight: 300;
                        size: 20px;
                    }
                    @media (max-width: 950px){
                        &{
                            margin: 0 auto;
                            text-align: center;
                            width: 100%;
                        }
                    }
                }
            }
            .heading-what{
                width:1240px;
                margin:0 auto;
                @media (max-width: 1300px){
                    width: 85%;
                }
                @media (max-width: 900px){
                    width: 90%;
                }
                h3{
                    color:#333333;
                    font: {
                        style: normal;
                        weight: 800;
                        size: 24px;
                    }
                    line-height: 31px;
                    @media (max-width: 950px){
                        &{
                            margin: 0 auto;
                            text-align: center;
                            width: 100%;
                        }
                    }
                }
            }
            .box-container{
                display:flex;
                width:1240px;
                margin: 0 auto;
                margin-top: 15px;
                @media (max-width: 1300px){
                    width: 85%;
                }
                @media (max-width: 900px){
                    width: 350px;
                }
                @media (max-width: 330px){
                    width: 290px
                }
                .boxes{
                    transform: translateY(500px);
                    padding: 32px;
                    width:386px;
                    margin-right: 32px;
                    border: 1px solid #F2F2F2;
                    box-sizing: border-box;
                    border-radius: 6px;
                    .text{
                        margin-top: 21px;
                        font: {
                            style: normal;
                            weight: 300;
                            size: 16px;
                        }
                        line-height: 28px;
                    }
                    .icon{
                        img{
                            width: 32px;
                            max-width: 32px;
                        }
                    }
                    @media (max-width: 1300px){
                        width: 300px;
                    }
                    @media (max-width: 950px){
                        width:95%;
                        text-align: center;
                        margin:0 auto;
                        padding:20px 25px;
                    }
                    @media (max-width: 900px){
                        margin-bottom: 30px;
                    }
                }
                @media (max-width: 950px){
                    display:block;
                }
            }
            svg.aboutus_line{
                position: absolute;
                bottom: -500px;
                right: 0;
                z-index: 1;  
                .line{
                    stroke-dasharray: 8500px;
                    stroke-dashoffset: 8500px;
                }
                @media (max-width: 1700px){
                    right: -130px;
                }
                @media (max-width: 1500px){
                    right: -180px;
                }
                @media (max-width: 1300px){
                    bottom: -740px;
                }
                @media (max-width: 1050px){
                    bottom: -600px;
                }
                @media (max-width: 500px){
                    width: 145%;
                    bottom: -757px;
                }
            }
        }
        .team{
            .AU_animationTeam{
                transform: translateX(-4000px);
            }
            .AU_animation7{
                transform: translateX(-700px);
            }
            position: relative;
            .cont{
                width: 1240px;
                margin: 0 auto;
                margin-top: 183px;
                margin-bottom: 236px;
                @media (max-width: 1300px){
                    width: 94%;
                }
                @media (max-width: 1050px){
                    margin-top: 83px;
                    margin-bottom: 100px;
                }
                @media (max-width: 500px){
                    margin-bottom: 25px;
                }
                .top{
                    h2{
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 18px;
                        letter-spacing: 0.2em;
                        text-transform: uppercase;
                        color: #E5375D;
                    }
                    h1{
                        width: 807px;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 46px;
                        line-height: 59px;
                        color: #FFFFFF;
                        @media (max-width: 500px){
                            font-size: 29px;
                            line-height: 38px;
                        }
                        @media (max-width: 330px){
                            font-size: 27px;
                            line-height: 37px;
                        }
                    }
                    @media (max-width: 1050px){
                        text-align: center;
                        h1{
                            width: 80%;
                            margin: auto;
                        }
                    }
                }
                .bottom{
                    margin-top: 86px;
                    @media (max-width: 1050px){
                        width: 86%;
                        margin: auto;
                        margin-top: 83px;
                    }
                    @media (max-width: 1000px){
                        width: 350px;
                    }
                    @media (max-width: 330px){
                        width: 270px;
                    }
                    .one{
                        position: relative;
                        width: 342px;
                        height: auto;
                        margin-right: 60px;
                        margin-bottom: 36px;
                        transition: 1s all ease-in-out; 
                        @media (max-width: 330px){
                            width: 270px;
                        }
                        h2{
                            margin-top: 21px;
                            margin-bottom: 8px;
                            font-style: normal;
                            font-weight: 200;
                            font-size: 24px;
                            line-height: 31px;
                            color: #FFFFFF;
                        }
                        h3{
                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 18px;
                            letter-spacing: 0.2em;
                            text-transform: uppercase;
                            color: #E5375D; 
                            transform: translate3D(0, 0, 0);
                        }
                        .head{
                            position: absolute;
                            right: 0;
                            top: 187px;
                            @media (max-width: 330px){
                                width: 30%;
                            }
                        }
                        &:hover{
                            transform: translateY(-25px) !important;
                        }
                        &:nth-child(2){
                            margin-top: 79px;
                        }
                        &:nth-child(3){
                            margin-top: 79px * 2;
                            @media (max-width: 1050px){
                                margin-top: unset;
                            }
                        }
                        &:nth-child(4){
                            @media (max-width: 1050px){
                                margin-top: 79px;
                            }
                        }
                        &:nth-child(5){
                            margin-top: 79px;
                            @media (max-width: 1050px){
                                margin-top: unset;
                            }
                        }
                        &:nth-child(6){
                            margin-top: 79px * 2;
                            @media (max-width: 1050px){
                                margin-top: 79px;
                            }
                        }
                        &:nth-child(7){
                            @media (max-width: 1050px){
                                margin-top: unset;
                            }
                        }
                        &:nth-child(8){
                            margin-top: 79px;
                        }
                        &:nth-child(9){
                            margin-top: 79px * 2;
                        }

                    }
                }
            }
            svg.team_line{
                position: absolute;
                bottom: -630px;
                left: 0;
                z-index: 1;  
                .line{
                    stroke-dasharray: 2600px;
                    stroke-dashoffset: 2600px;
                }
                @media (max-width: 1700px){
                    left: -100px;
                }
                @media (max-width: 1300px){
                    bottom: -800px;
                }
                @media (max-width: 900px){
                    left: -160px;
                }
                @media (max-width: 600px){
                    width: 48%;
                    bottom: -650px;
                    left: -150px;
                }
            }
        }
        .whywe{
            .AU_animation8{
                opacity: 0;
                transition: all 1s ease-in-out;
            }
            width: 1000px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            margin-bottom: 127px;
            @media (max-width: 1100px){
                display: block;
                width: 536px;
            }
            @media (max-width: 620px){
                margin-bottom: 50px;
            }
            @media (max-width: 500px){
                width: 310px;
            }
            @media (max-width: 330px){
                width:  290px;
            }
            .left{
                @media (max-width: 1100px){
                    text-align: center;
                    margin-bottom: 40px;
                }
                div{
                    h2{
                        font-style: normal;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 59px;
                        letter-spacing: 0.2em;
                        text-transform: uppercase;
                        color: #E5375D;
                    }
                    h1{
                        font-style: normal;
                        font-weight: 400;
                        font-size: 36px;
                        line-height: 40px;
                        color: #FFFFFF;

                    }
                }
            }
            .right{
                overflow: hidden;
                .block{
                    transform: translateX(700px);
                    width: 529px;
                    display: flex;
                    align-items: end;
                    margin-bottom: 25px;
                    @media (max-width: 620px){
                        display: grid;
                        text-align: center;
                        img{
                            margin: 0 auto;
                        }
                    }
                    @media (max-width: 500px){
                        width: 310px;
                    }
                    @media (max-width: 330px){
                        width:  260px;
                        margin: 0 auto;
                        margin-top: 25px;
                    }
                    div{
                        margin-top: 25px;
                        margin-left: 25px;
                        @media (max-width: 500px){
                            margin-left: 0;
                        }
                        h2{
                            font-style: normal;
                            font-weight: 600;
                            font-size: 22px;
                            line-height: 34px;
                            color: #FFFFFF;
                        }
                        p{
                            font-style: normal;
                            font-weight: 300;
                            font-size: 16px;
                            line-height: 26px;
                            color: rgba(255,255,255,0.6);
                        }
                    }
                    
                }
            }
 
        }
        .kontakt{
            .AU_animation12{
                transform: translateX(-500px);
            }
            .AU_animation13{
                transform: translateX(800px);
            }
            width: 1240px;
            height: 519px;
            margin: 0 auto;
            background: #F3385A;
            border-radius: 15px;
            margin-bottom: 127px;
            display: flex;
            @media (max-width: 1440px){
                width: 85%;
            }
            @media (max-width: 900px){
                width: 80%;
                height: auto;
                display: grid;
            }
            @media (max-width: 500px){
                width: 90%;
                margin-bottom: 30px;
            }
            .left{
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 0;
                @media (max-width: 1100px){
                    width: 45%;
                }
                @media (max-width: 900px){
                    width: 100%;
                    text-align: center;
                    margin-top: 40px;
                }
                @media (max-width: 330px){
                    width: 280px;
                }
                div{
                    h2{
                        font-style: normal;
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 23px;
                        letter-spacing: 0.2em;
                        text-transform: uppercase;
                        color: #000;

                        @media (max-width: 1024px){
                            font-size: 24px;
                        }
                    }
                    h1{
                        margin-top: 20px;
                        width: 306px;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 36px;
                        line-height: 40px;
                        color: #fff;
                        @media (max-width: 1024px){
                            font-size: 24px;
                        }
                        @media (max-width: 500px){
                            font-size: 32px;
                            line-height: 35px;
                        }
                        @media (max-width: 330px){
                            font-size: 30px;
                            line-height: 33px;
                        }
                        @media (max-width: 330px){
                            width: unset;
                        }
                    }
                }

            }
            .right{
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                @media (max-width: 1100px){
                    width: 55%;
                }
                @media (max-width: 900px){
                    width: 100%;
                    padding: 20px 0 20px 0;
                }
                @media (max-width: 330px){
                    width: 270px;
                }
                form{
                    width: 80%;
                    @media (max-width: 1100px){
                        width: 90%;
                    }
                    @media (max-width: 900px){
                        width: 346px;
                    }
                    @media (max-width: 500px){
                        width: 300px;
                    }
                    @media (max-width: 330px){
                        width: 250px;
                    }
                    input, textarea{
                        width: 344px;
                        background: #FFFFFF;
                        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
                        border-radius: 3px;
                        padding: 9px 13px;
                        margin-bottom: 14px;
                        border: none;
                        outline: none;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 20px;
                        color: #6B7280;
                        @media (max-width: 500px){
                            width: 100%;
                        }
                    }
                    input{
                        height: 50px;
                    }
                    textarea{
                        height: 132px;
                    }
                    .checkbox{
                        display: flex;
                        align-items: center;
                        margin-bottom: 20px;
                        input{
                            width: 21px;
                            height: 21px;
                            background: #FFFFFF;
                            border-radius: 3px;
                            border: none;
                            box-shadow: none;
                            margin-bottom: 0;
                            margin-right: 10px;
                        }
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 20px;
                        color: #FFFFFF;
                        a{
                            margin-left: 5px;
                            color: #FFFFFF;
                            text-decoration: underline;
                        }
                        @media (max-width: 800px){
                            display: block;
                        }
                    }
                    a.Mybtn{
                        rect{
                            stroke-dasharray: 669px;
                            stroke-dashoffset: 669px;
                        }
                        position: relative;
                        display: block;
                        overflow: hidden;
                        @include size(100%, 50px);
                        max-width: 129px;
                        border-radius: 30px;
                        span {
                            @include absolute();
                            display: block;
                            @include size(0);
                            border-radius: 50%;
                            background-color:  #fff;
                            transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
                            transform: translate(-50%, -50%);
                        }
    
                        &:hover {
                            span {
                                @include size(225%, 298px*2.25);
                            }
                            .text{
                                color: #F3385A;
                            }
                        }
                        .text{
                            position: absolute;
                            top: 0;
                            left: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            height: 100%;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 34px;
                            color: transparent;
                            animation-duration: 2s;
                            animation-fill-mode: forwards;
                            transition: 2s color; 
                        }
                    }
                    @media (max-width: 950px){
                        a.Mybtn{
                            margin: 0 auto;
                            .text{
                                font-size: 14px;
                            }
                            &:hover{
                                span {
                                    @include size(0%, 129px*2.25);
                                }
                                .text{
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }
        }
        
    } // end AboutUs
    .freeCons{
        opacity: 0;
        transition: .5s ease-out all;
        position: fixed;
        bottom: 80px;
        right: 40px;
        z-index: 10000;
        width: 255px;
        height: 70px;
        @media (max-width: 1000px){
            right: 50px;
            bottom: 50px;
        }
        @media (max-width: 667px){
            right: 20px;
            bottom: 20px;

            display: none;
        }
        background: #F3385A;
        border-radius: 15px;
        transition: all 0.5s ease;
        a.cont{
            width: 100%;
            height: 100%;
            padding: 0 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.5s ease;
            span{
                margin-right: 17px;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 29px;
                color: #FFFFFF;
                transition: all 0.5s ease;
                white-space: nowrap;
            }
            &:hover{
                text-decoration: none;
            }
        }
    }
    #myModal.active{
        display: flex; 
        z-index: 999999;
    }
    #myModal{
        display: none; 
        position: fixed; 
        z-index: 1; 
        left: 0;
        top: 0;
        margin: auto;
        width: 100%; 
        height: 100%; 
        overflow: auto; 
        background-color: rgba(0,0,0,0.6);
        .modal_content {
            transform: scale(0);
            transition: all 1s ease;
            width: 604px;
            height: auto;
            padding: 30px 0;  
            background-color: #F3385A;
            border-radius: 10px;
            @media (max-width: 600px){
                width: 95%;
                height: auto;
            }
            .TopClose{
                height: 36px;
                display: flex;
                justify-content: flex-end;
                svg{
                    cursor: pointer;
                }
                @media (max-width: 600px){
                    height: 63px;
                }
            }
            .cont{
                width: 456px;
                margin: 0 auto;
                margin-bottom: 47px;
                @media (max-width: 600px){
                    width: 95%;
                    h3,h1{
                        text-align: center;
                    }
                }
                @media (max-height: 830px){
                    margin-bottom: 20px;
                }
                
                h3.firstTitle{
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 18px;
                    letter-spacing: 0.2em;
                    text-transform: uppercase;
                    color: #000000;  
                }
                h1.secondTitle{
                    font-style: normal;
                    font-weight: 400;
                    font-size: 32px;
                    line-height: 40px;
                    color: #FFFFFF;
                    @media (max-height: 830px){
                        font-size: 23px;   
                        line-height: 27px;  
                    }
                    @media (max-height: 680px){
                        font-size: 20px;   
                        line-height: 24px;  
                    }
                }
                input{
                    height: 56px; 
                }
                .box{
                    width: 100%;
                    height: 50.89px;
                    background: rgba(255, 255, 255, 0.2);
                    border: 1px solid #FFFFFF;
                    box-sizing: border-box;
                    border-radius: 3px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @media (max-height: 830px){
                        height: 40px;
                    }
                    span{
                        margin-left: 10px;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 20px;
                        color: #FFFFFF;
                    }
                }
                .box.active{
                    background: #3FA9F5;
                    border: none;
                }
                #floatingTextarea{
                    height: 128px;
                    @media (max-height: 830px){
                        height: 70px;
                    }
                }
                input.form-check-input{
                    width: 21px;
                    height: 21px;
                    border-radius: 3px;  
                    margin-top: 0;  
                }
                .modal_what{
                    .col-lg-3{
                        padding: 0;
                    }
                    @media (max-width: 1000px){
                        .col-lg-3, .col-lg-6{
                            padding: 0;
                            margin-bottom: 10px;
                        }
                             
                    }
                }
                .suhlasim{
                    display: flex;
                    align-items: center;
                    .form-check-label{
                        margin-left: 12px;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 20px;
                        color: #FFFFFF;
                        a{
                            color: #FFFFFF;
                            text-decoration: underline;
                        }
                    }
                    
                }
                button.Mybtn{
                    position: relative;
                    display: block;
                    overflow: hidden;
                    @include size(100%, 51px);
                    max-width: 465px;
                    border-radius: 51px;
                    background-color: transparent;
                    border: none;
                    margin: 0 auto;
                    span {
                        @include absolute();
                        display: block;
                        @include size(0);
                        border-radius: 50%;
                        background-color:  #fff;
                        transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
                        transform: translate(-50%, -50%);
                    }
                    &:hover {
                        span {
                            @include size(225%, 298px*2.25);
                        }
                        .text{
                            color: #F3385A;
                        }
                    }
                    .text{
                        position: absolute;
                        top: 0;
                        left: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 100%;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 34px;
                        color: transparent;
                        animation-duration: 2s;
                        animation-fill-mode: forwards;
                        transition: 2s color; 
                        color: #fff;
                    }
                    @media (max-width: 950px){
                        margin: 0 auto;
                        .text{
                            font-size: 14px;
                        }
                        &:hover{
                            span {
                                @include size(0%, 129px*2.25);
                            }
                            .text{
                                color: #fff;
                            }
                        }
                    }
                    @media (max-width: 600px){
                        svg{
                            width: 95%;
                        }
                    }
                }
            }
        }

    }

    footer{
        background: #181A1A;
        padding-bottom: 50px;
        .info{
            background: linear-gradient(to right, rgba(241,19,63,1) 0%, rgba(255,165,183,1) 100%);
            position: relative;
            margin-bottom: 70px;
            min-height: 150px;
            img{
                width: 100%;
                opacity: 0.19;
            }
            @media (max-width: 950px){
                margin-bottom: 10px
            } 
            .columns{
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                @media (max-width: 950px){
                    text-align: center;
                    .is-2{
                        padding: 0;
                        display: none;
                    }
                    .is-7{
                        height: 100%;
                        margin: 0 auto;
                    }
                    .is-8 {
                        width: 100%;
                    }
                } 
                .all{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    @media (max-width: 950px){
                        .animation_13{
                            display: grid;
                        }
                    }
                    a{
                        font-style: normal;
                        font-weight: 300;
                        font-size: 56px;
                        line-height: 59px;
                        color: white;
                        transition: all .5s ease-in-out;
                        &:hover{
                            opacity: 0.65;
                        }
                        @media (max-width: 1800px){
                            font-size: 45px;
                        }
                        @media (max-width: 1280px){
                            font-size: 39px;
                        }
                        @media (max-width: 1050px){
                            font-size: 32px;
                        } 
                        @media (max-width: 950px){
                            font-weight: 100;
                        } 
                    }
                    a.email{
                        margin-right: 60px;
                        @media (max-width: 1280px){
                            margin-right: 25px;
                        }
                        @media (max-width: 950px){
                            margin-right: 0px;
                        } 
                    }
                }
            }
            .black_wall{
                background-color: #000807;
                position: absolute;
                bottom: 0;
                right: 0;
                left: 0;
                width: 100%;
                margin:auto;
                height: 100%;
                transform: translate(0%);
                transition: all 1s ease-in-out;
            }
            .black_wall:after{
                content: "";
                position: absolute;
                left: -179px;
                border-right: 180px solid #000807;
                border-top: 192px solid transparent;
            }
        }
        .end{
            opacity: 0;
            transition: all .5s ease-in-out;
            @media (max-width: 950px){
                text-align: center;
                justify-content: center;
            }
            .center_up{
                color: #8A8A8A;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
            }
            .center_down{
                margin-top: 11px;
                color: #8A8A8A;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
            }
            .social{
                display: flex;
                align-items: center;
                height: 20px;
                margin-top: 27px;
                a{
                    height: 100%;
                    margin-right: 8px;
                    border-image-slice: 1;
                    //border-bottom: 1px solid;
                    //border-width: 1px;
                    //border-image-source: linear-gradient(277.48deg, $bg-color 8.64%, $bg-color 56.22%, $bg-color 97.03%);
                    transition: all .25s ease-in-out;
                    
                    &:before {
                        content: '';
                        width: 0;
                        height: 3px;
                        position: absolute;
                        bottom: -10px;
                        left: 0;
                        background: linear-gradient(277.48deg, #0FA6E1 8.64%, #F3385A 56.22%, #45187A 97.03%);
                        transition: all .35s ease-in-out;
                    }
                    &:hover {
                        transform: translate(0, -5px);
                        &:before {
                            width: 100%;
                        }
                    }
                }
                @media (max-width: 950px){
                    justify-content: center;
                    margin-top: 10px;
                }
            }
            .links{
                display: grid;
                float: right;
                a{
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    margin-bottom: 5px;
                    color: #FFFFFF;
                    transition: all .35s ease-in-out;
                    //border-image-slice: 1;
                    //border-bottom: 2px solid;
                    //border-width: 2px;
                    //border-image-source: linear-gradient(277.48deg, $bg-color 8.64%, $bg-color 56.22%, $bg-color 97.03%);
                    // &:hover{
                    //     transform: translateY(0px);
                    //     transition: all .2s ease-in-out;
                    //     border-image-source: linear-gradient(277.48deg, #0FA6E1 8.64%, #F3385A 56.22%, #45187A 97.03%);
                    //     @media (max-width: 950px){
                    //         transform: translateY(0px);
                    //         border-image-source: linear-gradient(277.48deg, $bg-color 8.64%, $bg-color 56.22%, $bg-color 97.03%);
                    //     }
                    // }

                    &:hover {
                        opacity: 0.8;
                    }
                }
                @media (max-width: 950px){
                    float: none;
                }
            }

            .copy{
                margin-top: 60px;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                color: #FFFFFF;
            }
        }

    }
}

.position-absolute  {
    position: absolute;
}

.btn--primary {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    background: transparent;
    border: 1px solid #fff;
    height: 65px;
    padding-left: 30px;
    padding-right: 30px;
    font-weight: 300;
    color: #fff;
    letter-spacing: 0.01em;
    transition: all .35s ease-in-out;
    text-transform: uppercase;

    &:hover {
        background: #3FA9F5;
        color: #fff;
        border-color: #3FA9F5;
    }

    @media (max-width: 667px) {
        font-size: 13px;
        height: 45px;
    }
}

.container-block {
    max-width: 1260px;
    margin: 0 auto;
    padding: 0 15px;
}

.Services,
.ServiceWeb {
    header{
        .AU_animation1{
            transform: translateX(-650px);
        }
        .AU_animation2{
            transition: all 2s ease-in-out;
            opacity: 0;
        }
        position: relative;
        .header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 82px 0 0 0;
            width: 1700px;
            margin: 0 auto;
            position: relative;
            z-index: 100;
            .text, a{
                transition: .5s ease-in-out all;

                @media (max-width: 667px){
                    img { max-width: 125px; }
                 }
            }
            @media (max-width: 1700px){
                width: 95%;
            }
            @media (max-width: 1440px){
                width: 95%;
                padding-top: 45px;
            }
            @media (max-width: 900px){
                width: 90%;
                padding:30px 0 30px 0;
            }
            @media (max-width: 768px) {
                .line_one{
                    width: 450px;
                }
            }
            @media (max-width: 720px){
                width:90%;
            }
            @media (max-width: 667px){
                width:100%;
            }
            @media (max-width: 500px){
                display: block;
            }
            .left {
                color:#fff;
                font-weight: bold;
                height: 50px;
                cursor: pointer;
                @media (max-width: 992px){
                    padding-left: 15px;
                }
                span{
                    font-weight: 200; 
                    display:block;
                }
            }
            .right{
                color:#fff;
                font-weight: bold;
                cursor: pointer;
                @media (max-width: 500px){
                    margin-top: 20px;
                }
            }
        }
        .header-container{
            width:1240px;
            margin:90px auto 0 auto;
            @media (max-width: 1450px){
                width:1000px;
            }
            @media (max-width: 1240px){
                width: 85%;
            }
            @media (max-width: 1050px){
                margin-top: 50px;
            }
            @media (max-width: 900px){
                width: 90%;
            }

            @media (max-width: 950px){
                &{
                    margin: 0 auto;
                    text-align: center;
                    width: 95%;
                    padding-left: 0px;
                }
            }
            .service{
                color: $headline;
                letter-spacing: 0.2em;
                text-transform: uppercase;
                font: {
                    style: normal;
                    weight: 600;
                    size: 14px;
                }
                line-height: 18px;
            }
            .text{
                width:638px;
                @media (max-width: 900px){
                    width: 90%;
                    margin:15px auto 0 auto;
                }
                h1{    
                    margin: {
                        top: 15px;
                    }
                    color: #fff;
                    font: {
                        style: normal;
                        weight: 600;
                        size: 52px;
                    }
                    line-height: 59px;
                }
            }
            @media (max-width: 1440px) {
                .text h1{
                    font: {
                        size:32px;
                    }
                    line-height: 39px;
                }
            }
            @media (max-width: 950px){
            }
        }
        .header-container_2{
            margin: {
                top: 92px;
                left: auto;
                right: auto;
            }
            width: 1240px;
            @media (max-width: 1450px) {
                width:1000px;
            }
            @media (max-width: 1240px) {
                width: 85%;
            }
            @media (max-width: 900px) {
                width: 90%;
            }
            .grid{
                display:flex;
                @media (max-width: 720px){
                    display:block;
                }
                .header_box{
                    width: 390px;
                    margin-right:250px;
                    margin-bottom:49px;
                    @media (max-width: 1050px){
                        margin-right: 80px;
                    }
                    @media (max-width: 720px){
                        margin:60px auto 20px auto;
                        width:90%;
                    }
                    h3{
                        margin: {
                            bottom:25px;
                        }
                        width: 275px;
                        color: #fff;
                        font: {
                            style: normal;
                            weight: 600;
                            size: 30px;
                        }
                        line-height: 38px;
                        @media (max-width: 720px){
                            margin-bottom:15px;
                            width:100%;
                        }
                    }
                    p{
                        @media (max-width: 900px){
                            width: 95%;
                            margin:0 auto;
                        }
                        color: #D1D5DB;
                        font: {
                            style: normal;
                            weight: 400;
                            size: 16px;
                        }
                        line-height: 29px;
                    }
                }
            }
            @media (max-width: 720px){
                text-align:center;
            }
        }
        svg.header_line{
            position: absolute;
            bottom: -700px;
            left: 0;
            z-index: 1;  
            .line{
                stroke-dasharray: 8500px;
                stroke-dashoffset: 8500px;
            }
            @media (max-width: 1100px){
                width: 350px;
            }
            @media (max-width: 667px){
                transform: rotate(-45deg);
                right: -180px;
                width: 280px;
            }
        }
        svg.header_line--services {
            right: -250px;
            bottom: -750px;
            transform: rotate(45deg);
        }
    }/* ./header */

    .hero--sub {
        padding: 143px 0;
        text-align: center;
        position: relative;
        z-index: 1000;

        @media (max-width: 667px) {
            padding: 65px 0 116px 0;
        }

        &__text {
            transform: translate(0, 500px);
            opacity: 0;
            transition: all 1.5s ease-in-out;
        }

        h1 {
            color: #fff;
            font-weight: 500;
            font-size: 52px;
            margin-bottom: 15px;
            @media (max-width: 667px) {
                font-size: 24px;
                margin-bottom: 10px;
            }
        }

        p {
            color: #fff;
            font-size: 20px;
            font-weight: 300;
            margin-bottom: 35px;
            max-width: 880px;
            margin-left: auto;
            margin-right: auto;

            @media (max-width: 992px) {
                max-width: 600px;
            }

            @media (max-width: 667px) {
                font-size: 14px;
                max-width: 290px;
            }
        }

        strong {
            font-weight: 700;
            color: #fff;
            display: block;
        }

    }/* ./hero--sub */

    .hero--services {
        padding-top: 80px;
        padding-bottom: 304px;

        @media (max-width: 667px) {
            padding-top: 50px;
            padding-bottom: 220px;
            h1 {
                margin-bottom: 20px;
            }
        }
    }

    .hero-subtitle {
        color: #E5375D;
        margin-bottom: 25px;
        text-align: center;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 0.2em;
        font-size: 14px;

        @media (max-width: 667px) {
            font-size: 10px;  
            margin-bottom: 9px;
        }
    }

    .content {
        background: #fff;
        padding: 110px 0 90px 0;
        margin-bottom: 0;

        @media (max-width: 667px) {
            padding: 50px 0 60px 0;
        }

    } /* content */

    .block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 110px;

            @media (max-width: 768px) {
                flex-wrap: wrap;  
                margin-bottom: 40px;
                .block__image {
                    order: -1;
                    width: 100%;
                    margin-bottom: 20px;
                }
                .block__text {
                    max-width: 100%;
                 
                }
            }

        &:nth-child(1) {
            .block__text {
                @media (max-width: 667px) {
                    order: 2;
                }
            }
        }

        &:nth-child(2) {
            .block__text {
                margin-left: 50px;
                margin-right: 0;

                @media (max-width: 768px) {
                    margin-left: 0;
                }
            }
        }

        &:nth-child(3) {
            margin-bottom: -20px;
            .block__text {
                @media (max-width: 667px) {
                    order: 2;
                }
            }
        }

        &__text {
            max-width: 424px;
            margin-right: 50px;

            @media (max-width: 667px) {
                margin-right: 0;
                max-width: 100%;
            }
        }

        .text-toptitle {
            color: #E5375D;
            font-weight: 700;
            font-size: 14px;
            margin-bottom: 14px;
            text-transform: uppercase;
            letter-spacing: 0.2em;

            @media (max-width: 667px) {
                font-size: 12px;
                margin-bottom: 0;
            }
        }

        h2 {
            margin-top: 15px;
            font-size: 43px;
            margin-bottom: 15px;
            font-weight: 500;

            @media (max-width: 667px) {
                margin-top: 5px;
                font-size: 24px;
            }
        }

        p {
            color: #828282;
            font-size: 20px;
            line-height: 1.75;
            margin-bottom: 35px;

            @media (max-width: 667px) {
                font-size: 14px;
                margin-bottom: 25px;
            }

            strong {
                color: #828282; 
            }
        }

        img {
            display: block;
            max-width: 100%;
            height: auto;
        }
    } /* /.block */

    .portfolio-holder {
        position: relative;
        overflow: hidden;
        max-width: 1240px;
        margin: 50px auto;
        height: 591px;
        background:#1F1F1F;

        @media (max-width: 667px) {
            height: auto;
        }
    }

    .portfolio-slider-wrapper {
        padding-top: 58px;
       // display: flex;
        
        &:after {
            content: '';
            position: absolute;
            width: 400px;
            height: 100%;
            right: 0;
            top: 0;
            background: linear-gradient(270deg, #000807 0%, rgba(0, 8, 7, 0) 100%);
            z-index: 0;

            @media (max-width: 1440px) {
                width: 300px;
            }

            @media (max-width: 667px) {
                display: none;
            }

            @media (min-width: 2500px) {
                width: 950px;
            }
        }

        @media (max-width: 667px) {
            padding-top: 40px;
        }

        .portfolio-item {
            margin-right: 32px;
            display: block;

            @media (max-width: 667px) {
                margin-right: 10px;
                width: 90%;
            }

            &:hover {
                img {
                    transform: scale(1.05);
                }
            }

            &:not(.is-selected) {
                h3, p, .hashtags {
                    opacity: 0.5;
                }
            }
      
            .img-wrapper {
                overflow: hidden;
                margin-bottom: 40px;

                @media (max-width: 667px) {
                    margin-bottom: 20px;
                }
            }

            img {
                max-width: 710px;
                display: block;
                height: auto;
                transition: all .8s ease-in-out;

                @media (max-width: 667px) {
                    max-width: 100%;
                }
            }

            h3 {
                font-weight: 400;
                font-size: 30px;
                margin-bottom: 5px;
                color: #fff;
                line-height: 1;
                transition: all .6s ease-in-out;

                @media (max-width: 667px) {
                    font-size: 18px;
                }
            }

            p {
                color: #fff;
                font-size: 14px;
                transition: all .6s ease-in-out;

                @media (max-width: 667px) {
                    font-size: 12px;
                }
            }

            .hashtags {
                margin-top: 20px;
                transition: all .6s ease-in-out;

                @media (max-width: 667px) {
                    margin-top: 5px;
                }
                span {
                    display: inline-block;
                    margin-right: 8px;
                    font-weight: 600;
                    font-size: 14px;
                    color: #F3385A;
                    @media (max-width: 667px) {
                        font-size: 12px;
                        font-weight: 400;
                    }
                }   
            }
        }

        .flickity-viewport {
            overflow: visible;
        }

        &.flickity-enabled {
            position: static;
        }
    }
  
    .portfolios {
        background: #181A1A;
        padding: 90px 0 150px 0;        
        position: relative;

        @media (max-width: 667px) {
            padding: 47px 0 30px 0;
        }
        
        &--upper {
            @media (max-width: 667px) {
                padding-top: 300px;
            }
        }

        .portfolio-head {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media (max-width: 667px) {
                justify-content: center;
                text-align: center;
            }

            .toptitle {
                font-size: 14px;
                text-transform: uppercase;
                color: #E5375D;
                font-weight: 700;
                letter-spacing: 0.2em;
                margin-bottom: 15px;

                @media (max-width: 667px) {
                    font-size: 10px;
                    margin-bottom: 0;
                }
            }

            h2 {
                color: #fff;
                font-size: 46px;
                font-weight: 200;
                line-height: 1.15;

                @media (max-width: 667px) {
                    font-size: 24px;
                    max-width: 100%;
                }
            }
            
            .head-action {
                padding-top: 55px;

                @media (max-width: 667px) {
                    display: none;
                }
            }
        }

        .action-mobile {
            display: none;
        }

        @media (max-width: 667px) {
            .action-mobile {
                margin-top: 23px;
                text-align: center;

                .btn--primary {
                    height: 55px;
                }
            }
        }
    }

    .block-services-list {
        background: #131414;
        padding-bottom: 135px;
        position: relative;
        z-index: 2000;

        @media (max-width: 667px) {
            padding-bottom: 0;
        }

        .services {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 49px 32px;
            max-width: 1280px;
            top: -190px;
            margin-bottom: -190px;
            position: relative;
    
            @media (max-width: 992px) {
                grid-template-columns: 1fr 1fr;
            }
    
            @media (max-width: 667px) {
                grid-template-columns: 1fr;
                grid-gap: 20px;
            }
    
            .service-item {
                color: #fff;
                background: #1c1d1d;
                padding: 80px 55px 35px 55px;
                text-align: center;
                position: relative;
                overflow: hidden;
    
                @media (max-width: 667px) {
                    padding: 40px 25px 35px 25px;   
                }
    
                &:hover {
                    &:after {
                        height: 4px;
                    }
    
                    .service-item__link-holder {
                        opacity: 1;
                        transform: none;
                    }
    
                    .service-item__content {
                        transform: translate(0, -30px);
                    }
                }
    
                &:after {
                    content: '';
                    background: linear-gradient(280deg, #F11341 35.3%, #FFA5B7 96.79%);
                    position: absolute;
                    right: 0;
                    bottom: 4px;
                    width: 100%;
                    height: 0;
                    transition: all .35s ease-in-out;
                }
    
                &__content {
                    transition: all .35s ease-in-out;
                }
    
                &__image {
                    margin-bottom: 62px;
                    transition: all .35s ease-in-out;
    
                    @media (max-width: 667px) {
                        margin-bottom: 25px;
                    }
                }
    
                &__title {  
                    color: #fff;
                    font-size: 24px;
                    font-weight: 200;
                    margin-bottom: 12px;
                }
    
                &__desc {
                    font-size: 14px;
                    font-weight: 200;
                    line-height: 2;
                    color: #fff;
                }
    
                &__tags {
                    margin: 18px 0 0 0;
                    display: flex;
                    color: rgba(255, 255, 255, 0.4);
                    flex-wrap:wrap;
                    justify-content: center;
    
                    span {
                        margin: 0 3px;
                        font-size: 12px;
                        background: rgba(255, 255, 255, 0.04);
                        border-radius: 10px;
                        padding: 4px 8px;
                        margin-bottom: 6px;
                    }
                }
    
                &__link-holder  {
                    position: absolute;
                    bottom: 30px;
                    text-align: center;
                    width: 100%;
                    z-index: 100;
                    left: 0;
                    opacity: 0;
                    transform: translate(0, 50px);
                    transition: all .35s ease-in-out;
    
                    .link {
                        background: linear-gradient(282deg, #F11341 46.43%, #FFA5B7 96.23%);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        font-size: 16px;
                        letter-spacing: 0.64px;
                        text-transform: uppercase;
    
                        &:hover {
    
                        }
                    }
    
                }
            }
        }

        // old code
        .services-list {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 36px;
            top: -190px;
            margin-bottom: -190px;
            position: relative;
            opacity: 0;

            @media (max-width: 1200px) {
                grid-gap: 20px;
            }

            @media (max-width: 992px) {
                grid-template-columns: 1fr 1fr;
            }

            @media (max-width: 667px) {
                grid-template-columns: 1fr;
                margin-bottom: 0;
                grid-gap: 15px;
            }
        }

        .service-box {
            background: #131414;
            padding: 45px 40px 40px 44px; 
            transition: top .5s ease-in-out, box-shadow .5s ease-in-out;
            min-height: 319px;
            position: relative;
            top: 0;

            @media (max-width: 1200px) {
                padding: 35px 25px 25px 24px;  
            }

            @media (max-width: 667px) {
                min-height: 257px;
                padding: 33px 14px 25px 24px;  

                &:last-child {
                    margin-bottom: -300px;
                }
            }

            &:hover {
                @media (min-width: 667px) {
                    top: -20px;
                    box-shadow: -6px 6px 30px rgba(0, 0, 0, 0.15);
                }

                .box-footer {
                    .link-text {
                        opacity: 1;
                    }

                    .box-button { 
                        background-color: #3FA9F5;
                        box-shadow: 0px 6px 12px rgba(63, 169, 245, 0.3);
                        border-color: transparent;
                        opacity: 1;
                    }
                }
            }

            .box-top {
                display: flex;
                align-items: center;
                margin-bottom: 12px;

                img {
                    max-width: 40px;
                    @media (max-width: 667px) {
                        max-width: 25px;
                    }
                }
            }

            .box-data {
                padding-left: 57px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 82%;

                @media (max-width: 667px) {
                    padding-left: 0;
                    height: auto;
                }
            }

            h2 {
                color: #fff;
                font-weight: 400;
                font-size: 24px;
                padding-left: 15px;
                margin-bottom: 0;

                @media (max-width: 667px) {
                    font-size: 16px;
                }
            }

            p {
                color: #fff;
                font-size: 14px;
                line-height: 1.9;
                margin-bottom: 13px;

                @media (max-width: 667px) {
                    font-size: 13px;
                    line-height: 1.8;
                    margin-bottom: 18px;
                }
            }
        }

        .box-footer {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .link-text {
                text-transform: uppercase;
                color: #3FA9F5;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0.2em;
                opacity: 0;
                transition: all .35s ease-in-out;

                
                @media (max-width: 667px) {
                    display: none;
                }
            }

            .box-button {
                width: 39px;
                height: 39px;
                cursor: pointer;
                border-radius: 50%;
                border: 1px solid #FFFFFF;
                opacity: 0.2;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all .35s ease-in-out;

                i {
                    display: inline-block;
                    background: url("../images/services/next-arrow.svg") no-repeat;
                    background-size: contain;
                    width: 13px;
                    height: 13px;
                }
            }
        }
    }

    .kontakt {
        margin-top: 60px;
        
        .AU_animation12{
            transform: translateX(-580px);
        }
        .AU_animation13{
            transform: translateX(800px);
        }
        width: 1240px;
        height: 386px;
        margin: 0 auto;
        background: #F3385A;
        border-radius: 0;
        margin-bottom: 127px;
        display: flex;
        @media (max-width: 1440px){
            width: 85%;
        }
        @media (max-width: 900px){
            width: 80%;
            height: auto;
            display: grid;
        }
        @media (max-width: 667px){
            width: 90%;
            margin-bottom: 61px;
        }
        .left{
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 0;

            @media (max-width: 1100px){
                width: 45%;
            }
            @media (max-width: 900px){
                width: 100%;
                text-align: center;
                margin-top: 30px;
            }
            @media (max-width: 330px){
                width: 280px;
            }
            div{
                width: 100%;
                padding: 0 50px 0 100px;

                @media (max-width: 768px) {
                    padding: 0 50px;
                }

                @media (max-width: 667px) {
                    padding: 0;
                }

                h2{
                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 23px;
                    letter-spacing: 0.2em;
                    text-transform: uppercase;
                    color: #000;
                    @media (max-width: 1024px){
                        font-size: 24px;
                    }
                    @media (max-width: 667px) {
                        font-size: 12px;
                    }
                }
                h3{
                    margin-top: 20px;
                    width: 100%;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 36px;
                    line-height: 40px;
                    color: #fff;
                    @media (max-width: 1024px){
                        font-size: 24px;
                    }
                    @media (max-width: 500px){
                        font-size: 32px;
                        line-height: 35px;
                    }
                    @media (max-width: 667px){
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 1.25;
                        margin-top: 0;
                    }
                }
                p {
                    margin-top: 35px;
                    color: #fff;
                    font-size: 36px;
                    line-height: 1.1;
                    @media (max-width: 1024px){
                        font-size: 24px;
                    }
                    @media (max-width: 667px){
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 1.25;
                        margin-top: 25px;
                    }
                }
            }

        }
        .right{
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            @media (max-width: 1100px){
                width: 55%;
            }
            @media (max-width: 900px){
                width: 100%;
                padding: 20px 0 20px 0;
            }
            @media (max-width: 667px){
                width: 100%;
                padding: 15px 15px 50px 15px; 
            }
            form{
                width: 80%;
                @media (max-width: 1100px){
                    width: 90%;
                }
                @media (max-width: 900px){
                    width: 346px;
                }
                @media (max-width: 500px){
                    width: 300px;
                }
                @media (max-width: 330px){
                    width: 100%;
                }
                input, textarea{
                    width: 344px;
                    background: #FFFFFF;
                    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
                    border-radius: 3px;
                    padding: 9px 13px;
                    margin-bottom: 14px;
                    border: none;
                    outline: none;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    color: #6B7280;
                    @media (max-width: 500px){
                        width: 100%;
                    }
                }
                input{
                    height: 50px;
                }
                textarea{
                    height: 132px;
                }
                .checkbox{
                    display: flex;
                    align-items: center;
                    margin-bottom: 20px;
                    input{
                        width: 21px;
                        height: 21px;
                        background: #FFFFFF;
                        border-radius: 3px;
                        border: none;
                        box-shadow: none;
                        margin-bottom: 0;
                        margin-right: 10px;
                    }
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    color: #FFFFFF;
                    a{
                        margin-left: 5px;
                        color: #FFFFFF;
                        text-decoration: underline;
                    }
                    @media (max-width: 800px){
                        display: block;
                    }
                }
                a.Mybtn {
                    rect{
                        stroke-dasharray: 669px;
                        stroke-dashoffset: 669px;
                    }
                    position: relative;
                    display: block;
                    overflow: hidden;
                    @include size(100%, 50px);
                    max-width: 129px;
                    border-radius: 30px;
                    span {
                        @include absolute();
                        display: block;
                        @include size(0);
                        border-radius: 50%;
                        background-color:  #fff;
                        transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
                        transform: translate(-50%, -50%);
                    }

                    &:hover {
                        span {
                            @include size(225%, 298px*2.25);
                        }
                        .text{
                            color: #F3385A;
                        }
                    }
                    .text{
                        position: absolute;
                        top: 0;
                        left: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 100%;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 34px;
                        color: transparent;
                        animation-duration: 2s;
                        animation-fill-mode: forwards;
                        transition: 2s color; 
                    }
                }
                @media (max-width: 950px){
                    a.Mybtn{
                        margin: 0 auto;
                        .text{
                            font-size: 14px;
                        }
                        &:hover{
                            span {
                                @include size(0%, 129px*2.25);
                            }
                            .text{
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }/* ./kontakt */

    footer{
        padding-bottom: 50px;
        .info{
            background: linear-gradient(to right, #f1133f 0%, #ffa5b7 100%);
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            position: relative;
            margin-bottom: 81px;
            height: 150px;
            img{
                width: 100%;
                opacity: 0.19;
            }
            @media (max-width: 950px){
                margin-bottom: 10px
            } 
            .columns{
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                @media (max-width: 950px){
                    text-align: center;
                    .is-2{
                        padding: 0;
                    }
                    .is-7{
                        height: 100%;
                        margin: 0 auto;
                    }
                } 
                .all{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    @media (max-width: 950px){
                        .animation_13{
                            display: grid;
                        }
                    }
                    a{
                        font-style: normal;
                        font-weight: 300;
                        font-size: 56px;
                        line-height: 59px;
                        color: white;
                        opacity: 0.9;
                        transition: all .5s ease-in-out;
                        &:hover{
                            opacity: 1;
                        }
                        @media (max-width: 1800px){
                            font-size: 45px;
                        }
                        @media (max-width: 1280px){
                            font-size: 39px;
                        }
                        @media (max-width: 1050px){
                            font-size: 32px;
                        } 
                        @media (max-width: 950px){
                            font-weight: 100;
                        } 
                        @media (max-width: 667px){
                            font-weight: 400;
                        } 
                    }
                    a.email{
                        margin-right: 60px;
                        @media (max-width: 1280px){
                            margin-right: 25px;
                        }
                        @media (max-width: 950px){
                            margin-right: 0px;
                        } 
                    }
                }
            }
            .black_wall{
                background-color: #000807;
                position: absolute;
                bottom: 0;
                right: 0;
                left: 0;
                width: 100%;
                margin:auto;
                height: 100%;
                transform: translate(0%);
                transition: all 1s ease-in-out;
            }
            .black_wall:after{
                content: "";
                position: absolute;
                left: -179px;
                border-right: 180px solid #000807;
                border-top: 192px solid transparent;
            }
        }
        .end{
            opacity: 0;
            transition: all .5s ease-in-out;
            @media (max-width: 950px){
                text-align: center;
            }
            .center_up{
                color: #bababa;
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 22px;
            }
            .center_down{
                margin-top: 11px;
                color: #bababa;
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 22px;
            }
            .social{
                display: flex;
                align-items: center;
                height: 20px;
                margin-top: 27px;
                a {
                    position: relative;
                    height: 100%;
                    margin-right: 8px;
                    //border-image-slice: 1;
                    //border-bottom: 1px solid;
                    //border-width: 1px;
                    //border-image-source: linear-gradient(277.48deg, $bg-color 8.64%, $bg-color 56.22%, $bg-color 97.03%);
                    transition: all .25s ease-in-out;
                    &:before {
                        content: '';
                        width: 0;
                        height: 3px;
                        position: absolute;
                        bottom: -10px;
                        left: 0;
                        background: linear-gradient(277.48deg, #0FA6E1 8.64%, #F3385A 56.22%, #45187A 97.03%);
                        transition: all .35s ease-in-out;
                    }
                    &:hover {
                        transform: translate(0, -5px);
                        &:before {
                            width: 100%;
                        }
                    }
                }
                @media (max-width: 950px){
                    justify-content: center;
                    margin-top: 10px;
                }
            }
            .links{
                display: grid;
                float: right;
                a{
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    margin-bottom: 5px;
                    color: #FFFFFF;
                    border-image-slice: 1;
                    border-bottom: 2px solid;
                    border-width: 2px;
                    border-image-source: linear-gradient(277.48deg, $bg-color 8.64%, $bg-color 56.22%, $bg-color 97.03%);
                    &:hover{
                        transform: translateY(0px);
                        transition: all .2s ease-in-out;
                        border-image-source: linear-gradient(277.48deg, #0FA6E1 8.64%, #F3385A 56.22%, #45187A 97.03%);
                        @media (max-width: 950px){
                            transform: translateY(0px);
                            border-image-source: linear-gradient(277.48deg, $bg-color 8.64%, $bg-color 56.22%, $bg-color 97.03%);
                        }
                    }
                }
                @media (max-width: 950px){
                    float: none;
                }
            }
            .copy{
                margin-top: 60px;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                color: #FFFFFF;
            }
        }

    }/* ./footer */
}

.ServiceWeb {
    header {
        svg.header_line {
            transform: rotate(90deg);
            bottom: -810px;
            left: -250px;
            right: auto;

            @media (max-width: 1440px) {
                width: 405px;
                left: -200px;
            }

            @media (max-width: 950px) {
                transform: rotate(120deg);
                bottom: -980px; 
                left: -250px;
            }
        }
    }
}

.Services.Services--cookies {
    .kontakt-wrapper {
       background: #0F1615;
       padding-bottom: 50px;
       position: relative;
       z-index: 3000;

       .kontakt {
           margin-bottom: 0;
       }
    }

    .block-services-list {
        padding-bottom: 80px;
    }

    .block-services-list .services-list {
        grid-template-columns: 1fr 1fr;

        @media (max-width: 667px) {
            display: block;
        }
    }

    .service-box {
        @media (max-width: 667px) {
           margin-bottom: 40px;

           &:last-child {
               margin-bottom: -200px;
           }
        }
    }

    .service-box:hover {
        transform: none;
        top: 0;
    }

    .service-box.upper {
        top: -30px;

        @media (max-width: 667px) {
            top: 0;
        }
    }
}

/* breadcrumb */
.breadcrumb {
    position: absolute;
    top: 90px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1000;

    @media (max-width: 1440px) {
        top: 48px;
    }

    @media (max-width: 1200px) {
        display: none;
    }
        
    ul {
        display: flex;
        align-items: center;
        
        li {
            display: inline-block;
            position: relative;
            padding: 0 19px;
            text-transform: uppercase;
            letter-spacing: 0.2em;
            font-size: 14px;

            @media (max-width: 1600px) {
                padding: 0 12px;
            }

           + li:before {
                content: '';
                width: 2px;
                height: 26px;
                background: rgba(255, 255, 255, 0.3);
                position: absolute;
                left: 0;
                top: -2px;
                transform: rotate(25deg);
            }

            &:last-child,
            &:last-child a {
                color: #E5375D;
                font-weight: 600;
            }

            span {
                display: inline-block;
            }
            a {
                color: #9C9C9C;
                text-transform: uppercase;
                font-size: 14px;
                font-weight: 400;
                transition: all .3s ease-in-out;

                @media (max-width: 1280px) {
                    font-size: 12px;
                }            

                &:hover {
                    color: #E5375D;
                }
            }
        }
    }
}

.page-header {
    position: relative;
    z-index: 3000;
}


 /* Menu */
 @media (max-width: 667px) {
    body .Home header .landing_page .my_container {
        width: 100%;
    }
}


/* Navigation */
.navigation-bar {
    display: flex;
    align-items: center;
    position: relative;
    top: -5px;
    height: 40px;

    @media (max-width: 667px) {
        position: absolute;
        top: 0;
        width: 100%;
    }

    &__links {
        margin-right: 25px;
        display: flex;
        align-items: center;

        @media (max-width: 667px) {
            display: none;
        }
    }

    .separator {
        background:linear-gradient(142deg, #F11341 35.99%, #FFA5B7 96.18%), #F3385A;
        width: 5px;
        height: 5px;
        display: inline-block;
        margin: 0 17px;
        border-radius: 50%;
    }

    &__link {
        color: #fff;
        font-weight: 600;
        letter-spacing: 0.64px;
        text-transform: uppercase;
        font-size: 16px;
        transition: all .3s ease-in-out;

        &:hover {
            color: #F3385A;
        }
    }
}

/* navigation bar VARIATIONS */
.Home header .navigation-bar {
    top: 85px;
    animation-delay: 4s;
    opacity: 0;

    @media (max-width: 1440px) {
        top: 60px;
    }

    @media (max-width: 1024px) {
        top: 45px;
    }

    @media (max-width: 667px) {
        top: 0;
        width: 65px;
        height: 65px;
        right: 0;
    }
}



.AboutUs header .navigation-bar {
    @media (max-width: 667px) {
        top: 0;
        width: 65px;
        height: 65px;
        right: 0;
    }
}

.Services header .navigation-bar,
.ServiceWeb header .navigation-bar { 
    @media (max-width: 667px) {
        top: 0;
        width: 65px;
        height: 65px;
        right: 0;
    }
}

.referenceView .navigation-bar {
    @media (max-width: 667px) {
        top: 0;
        width: 65px;
        height: 65px;
        right: 0;
    }
}

/* only on mobile */
.hambi-btn {
    background: none;
    display: block;
    border: none;
    position: relative;
    width: 40px;
    height: 40px;
    top: 0;
    cursor: pointer;
    transition: all .3s ease-in-out;

    @media (min-width: 667px) {

        &:hover {
            span:before {
                width: calc(100% + 1px);
            }
        }
    }

    span {
        background: #fff;
        height: 3px;
        width: 100%; 
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &:before {
            content: '';
            position: absolute;
            left: -1px;
            top: 0;
            background: #F3385A;
            width: 0;
            height: 100%;
            z-index: 10;
            transition: all .35s ease-in-out;
        }

        &:nth-child(1) {
            margin-top: -12px;
        }

        &:nth-child(3) {
            margin-top: 12px;
        }
    }

    @media (max-width: 667px) {
        background: #F3385A;
        border: none;
        border-radius: 0px 0 0 10px;
        width: 65px;
        height: 65px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 9000;
        display: flex;
        justify-content: center;
        align-items: center;

        &--close { 
            background: #606060;
        }

        span {
            height: 2px;
            width: 20px; 

            &:nth-child(1) {
                margin-top: -7px;
            }
    
            &:nth-child(3) {
                margin-top: 7px;
            }
        }
    }
}

/* Main menu with overlay */
.overlay-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    //background: #000807;
    z-index: 99999;
    transform: translate3d(0, 0, 0);
    visibility: hidden;
    opacity: 0;
    transition: all .5s ease-in-out;
    overflow: hidden;

    &.is-showed {
        &:before {
            opacity: 1;
        }
    }

    &:before {
        content: '';
        background: #000807;
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0;
        transition: all .5s ease-in-out;
        z-index: 0;
    }

    &__content {
        position: relative;
        z-index: 50;
    }

    .svg-line {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 50%;
        z-index: 10;

        .line-path {
            stroke-dasharray: 2500;
            stroke-dashoffset: 2500;
        }

        .line-anim {
            animation: lineAnim 2s ease-in-out forwards;
        }

        .fixed-line {
            stroke-dashoffset: 0;
        }

        @keyframes lineAnim {
            to {
              stroke-dashoffset: 0;
            }
          }

        @media (max-width: 992px) {
            transform: rotate(17deg) scale(0.95);
            top: -150px;
            left: -60px;
        }

        @media (max-width: 667px) {
            transform: rotate(17deg) scale(0.8);
            top: -280px;
            left: -100px;
            width: 100%;
        }

        @media (max-width: 667px) and (orientation: landscape) {  
            top: -50px;
            left: -200px;
        }
    }

    .main-menu__content {
        position: relative;
        z-index: 1000;
        height: 100vh;
        max-width: 780px;
        padding: 0 20px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        @media (max-width: 1024px) {
            max-width: 550px;
        }

        @media (max-width: 667px) {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            max-width: 100%;
            padding-left: 25vw;
        }

        @media (max-width: 667px) and (orientation: landscape) {    
            flex-direction: row;
            padding-left: 10vw;

            .main-nav {
                margin-right: 50px;
                padding-top: 30px;
            }
        }
    }

    .main-nav {
        li {
            margin-bottom: 5px;

            @media (max-width: 667px) {
                margin-bottom: 2px;
            }

            a {
                font-size: 52px;
                color: #fff;
                transition: all .35s ease-in-out;
                font-weight: 400;

                &:hover {
                    color: #F3385A;
                    letter-spacing: 0.05em;
                }

                @media (max-width: 1024px) {
                    font-size: 40px;
                }

                @media (max-width: 667px) {
                    font-size: 28px;
                }

            }
        }
    }

    .main-menu-close {
        background: #606060;
        border: none;
        border-radius: 0px 0 0 10px;
        width: 105px;
        height: 105px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 99999;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all .3s ease-in-out;

        &:hover {
            background: #F3385A;
            span {
                &:nth-child(1) {
                    transform: rotate(-225deg);
                }

                &:nth-child(2) {
                    transform: rotate(225deg);
                }
            }
        }

        @media (max-width: 667px) {
            width: 65px;
            height: 65px; 
        }

        span {
            background: #fff;
            height: 43px;
            width: 3px; 
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0);
            transition: all .65s ease-in-out;

            @media (max-width: 667px) {
                height: 26px;
                width: 3px;   
            }

            &:nth-child(1) {
                transform: rotate(45deg);
            }

            &:nth-child(2) {
                transform: rotate(-45deg);
            }
        }
    }

    .main-menu__left {
        // animations
        transform: translate(-100px, 0); 
        opacity: 0;
        transition: all .8s ease-in-out;
        will-change: opacity;
    }

    .main-menu__right {
        padding-top: 70px;

        // animations
        transform: translate(100px, 0); 
        opacity: 0;
        transition: all .8s ease-in-out;

        @media (max-width: 992px) {
            padding-top: 20px;
        }

        @media (max-width: 667px) {
            padding-top: 45px;
        }
    }

    .menu-group {
        margin-bottom: 40px;

        &:last-child {
            margin-bottom: 0;
        }

        &__title {
            margin-bottom: 14px;
            font-size: 18px;
            color: rgba(255, 255, 255, 0.7);
            line-height: 1;

            @media (max-width: 667px) {
                font-size: 12px;
                margin-bottom: 9px;
            }
        }

        ul {
            li {
                margin-bottom: 5px;

                @media (max-width: 667px) {
                    line-height: 1;
                }
            
                a {
                    font-size: 24px;
                    color: #fff;
                    transition: all .35s ease-in-out;

                    &:hover {
                        color: #F3385A;
                    }

                    @media (max-width: 667px) {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .main-menu__social {
        display: flex;

        li {
            margin-right: 20px;

            a {
                display: block;
                position: relative;

                svg {
                    transition: all .35s ease-in-out;

                    @media (max-width: 667px) {
                        max-width: 17px;
                    }
                }

                &:before {
                    content: '';
                    background: linear-gradient(277.48deg, #0FA6E1 8.64%, #F3385A 56.22%, #45187A 97.03%);
                    width: 0;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    height: 2px;
                    transition: all .35s ease-in-out;
                }

                &:hover {
                    svg {
                        transform: translate(0, -5px);
                    }

                    &:before {
                        width: 100%;
                    }
                }
            }
        }
    }

    .main-menu__language{
        
        display: flex;

        li{
            a{
                
                margin-right: 10px;
            }
        }
    }
}

.relative {
    position: relative;
}

.portfolios {
    background: #131414;
    padding: 110px 0;        
    position: relative;

    @media (max-width: 667px) {
        padding: 47px 0 70px 0;
    }
    
    &--upper {
        @media (max-width: 667px) {
            padding-top: 300px;
        }
    }

    .portfolio-head {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 667px) {
            justify-content: center;
            text-align: center;
        }

        .toptitle {
            font-size: 14px;
            text-transform: uppercase;
            background: -webkit-linear-gradient(180deg, #F11341, #FFA5B7);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: 700;
            letter-spacing: 0.2em;
            margin-bottom: 15px;
            display: inline-block;

            @media (max-width: 667px) {
                font-size: 10px;
                margin-bottom: 0;
            }
        }

        h2 {
            color: #fff;
            font-size: 46px;
            font-weight: 200;
            line-height: 1.15;

            @media (max-width: 667px) {
                font-size: 24px;
                max-width: 100%;
            }
        }
        
        .head-action {
            padding-top: 55px;
        
            @media (max-width: 1280px) {
                margin-right: 150px;
             }
    
            @media (max-width: 667px) {
                display: none;
            }
        }
    }

    .action-mobile {
        display: none;
    }

    @media (max-width: 667px) {
        .action-mobile {
            margin-top: 23px;
            text-align: center;

            .btn--primary {
                height: 55px;
            }
        }
    }
}

.portfolio-slider-wrapper {
    padding-top: 58px;
   // display: flex;
    
    &:after {
        content: '';
        position: absolute;
        width: 500px;
        height: 100%;
        right: 0;
        top: 0;
        background: linear-gradient(270deg, #000807 0%, rgba(0, 8, 7, 0) 100%);
        z-index: 0;

        @media (max-width: 1440px) {
            width: 400px;
        }

        @media (max-width: 667px) {
            display: none;
        }

        @media (min-width: 2500px) {
            width: 950px;
        }
    }

    @media (max-width: 667px) {
        padding-top: 40px;
    }

    .portfolio-item {
        margin-right: 32px;
        display: block;

        @media (max-width: 667px) {
            margin-right: 10px;
            width: 90%;
        }

        &:hover {
            img {
                transform: scale(1.05);
            }
        }

        &:not(.is-selected) {
            h3, p, .hashtags {
                opacity: 0.5;
            }
        }
  
        .img-wrapper {
            overflow: hidden;
            margin-bottom: 40px;

            @media (max-width: 667px) {
                margin-bottom: 20px;
            }
        }

        img {
            max-width: 710px;
            display: block;
            height: auto;
            transition: all .8s ease-in-out;

            @media (max-width: 667px) {
                max-width: 100%;
            }
        }

        h3 {
            font-weight: 400;
            font-size: 30px;
            margin-bottom: 5px;
            color: #fff;
            line-height: 1;
            transition: all .6s ease-in-out;

            @media (max-width: 667px) {
                font-size: 18px;
            }
        }

        p {
            color: #fff;
            font-size: 14px;
            transition: all .6s ease-in-out;

            @media (max-width: 667px) {
                font-size: 12px;
            }
        }

        .hashtags {
            margin-top: 20px;
            transition: all .6s ease-in-out;

            @media (max-width: 667px) {
                margin-top: 5px;
            }
            span {
                display: inline-block;
                margin-right: 8px;
                font-weight: 600;
                font-size: 14px;
                color: #F3385A;
                @media (max-width: 667px) {
                    font-size: 12px;
                    font-weight: 400;
                }
            }   
        }
    }

    .flickity-viewport {
        overflow: visible;
    }

    &.flickity-enabled {
        position: static;
    }
}

.portfolio-holder {
    position: relative;
    overflow: hidden;
    max-width: 1240px;
    margin: 50px auto 23px auto;
    height: 591px;
    background:#1F1F1F;

    transform: translate(0, 100px);
    opacity: 0;

    @media (max-width: 667px) {
        height: 70vh;
        min-height: 490px;
    }

    @media (max-width: 667px) and (orientation: landscape) {
        height: 450px;
    }

    .portfolio-item {
        display: flex;
        height: inherit;

        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        @media (max-width: 667px) {
            display: flex;
            flex-wrap: wrap;
        }

        &.active {
            z-index: 5000;
        }
    }

    .portfolio__left {
        background: #1F1F1F;
        color: #fff;
        flex: 0 0 50%;
        padding: 40px 60px;

        @media (max-width: 667px) {
            padding: 20px 30px 80px 30px;
            flex: 0 0 100%;
            order: 2;
        }

        .hashlist {
            display: flex;
            position: absolute;
            top: 0;
            left: 0;

            @media (max-width: 667px) {
                position: relative;
                margin-bottom: 20px;
            }

            span {
                display: inline-block;
                margin-right: 7px;
                color: #F11341;
                font-size: 14px;
                font-weight: 600;

                @media (max-width: 667px) {
                    font-size: 13px;
                    font-weight: 500;
                }
            }
        }

        .content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            background: none;

            transform: translate(-104%, 0);
            opacity: 0;

            &__toptitle {
                color: #fff;
                text-transform: uppercase;
                font-weight: 600;
                letter-spacing: 1.6px;
                margin-bottom: 15px;

                @media (max-width: 667px) {
                    font-size: 13px;
                }
            }

            a {
                color: #F11341;
                font-size: 16px;
                transition: all .35s ease-in-out;
                letter-spacing: 0.64px;
                text-transform: uppercase;
                display: flex;
                align-items: center;

                @media (max-width: 667px) {
                    font-size: 13px;
                }

                svg {
                    margin-left: 10px;
                    transition: all .35s ease-in-out;
                    position: relative;
                    left: 0;
                }

                &:hover {
                    color: #fff;

                    svg {
                        left: 10px;
                    }

                    svg path {
                        stroke: #fff;
                    }
                }

                > svg path {
                    transition: all .35s ease-in-out;
                }
            }

            &__title {
                color: #fff;
                font-size: 44px;
                font-weight: 200;
                line-height: 1.3;
                margin-top: 0;
                margin-bottom: 32px;

                a {
                    font-size: inherit;
                    color: #fff;
                    text-transform: none;

                    &:hover {
                        color: #F11341;
                    }
                }

                @media (max-width: 667px) {
                    font-size: 24px;
                    font-weight: 400;
                    margin-bottom: 15px;

                    a {
                        font-size: 24px;
                        color: #fff;
                    }
                }
            }

            .info-boxes {
                display: flex;
                margin: 30px 0;
            }

            .box-item {
                margin-right: 20px;
                max-width: 180px;

                .box-number {
                    font-size: 38px;
                    font-weight: 200;
                    letter-spacing: 3.8px;
                    display: flex;
                }

                p {
                    margin-bottom: 0;
                    font-weight: 300;
                    color: rgba(255, 255, 255, 0.73);
                }
            }
        }
    }

    .portfolio__right {
        background: #252525;
        overflow: hidden;
        position: relative;
        flex: 0 0 50%;

        @media (max-width: 667px) {
            flex: 0 0 100%;
            height: 250px;
        }

        @media (max-width: 667px) and (orientation: landscape) {
            height: 300px;
        }

        .kastiel-graphics {
            opacity: 0;
            transform: scale(3);
            position: relative;
            z-index: 1000;

            @media (max-width: 667px) {
                top: -40px;
            }

            @media (max-width: 667px) and (orientation: landscape) {
                max-width: 60%;
                display: block;
                margin: 0 auto;
                top: -40px;
            }
        }

        .kastiel-chandelier {
            position: absolute;
            right: -100px;
            top: -50px;
            width: 380px;
            height: auto;
            z-index: 1500;
            opacity:1;
            transform-origin: 50% 0;
            transform: rotate(0deg) translate(0, -104%);

            @media (max-width: 667px) {
                width: 150px;
                right: -20px;
            }
        }

        .aldona-graphics {
            margin: 0 auto;
            position: relative;
            top: 10%;

            transform: translate(110%, 0);

            @media (max-width: 667px) {
                top: -20px;
            }

            @media (max-width: 667px) and (orientation: landscape) {
                max-width: 60%;
                display: block;
            }
        }

        .mirano-graphics {
            margin: 0 auto;
            opacity: 0;
            transform: scale(3);

            @media (max-width: 667px) {
                top: -50px;
                position: relative;
            }

            @media (max-width: 667px) and (orientation: landscape) {
                max-width: 65%;
                margin-left: auto;
                margin-right: 0;
                display: block;
            }
        }

        .drteiss-graphics{
            margin: 0 auto;
            opacity: 0;
            transform: scale(3);
        }

        .giganet-graphics {
            margin: 0 auto;
            position: absolute;
            bottom: 0;
            width: 100%;
            height: auto;
            z-index: 1000;

           transform: translate(0, 110%);

            @media (max-width: 667px) {
                 bottom: -25px;
            }

            @media (max-width: 667px) and (orientation: landscape) {
                max-width: 100%;
                display: block;
                margin: 0 auto;
                bottom: -145px;
            }
        }

        .mnam-pattern {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .mnam-screen {
            position: absolute;
            z-index: 1000;
            left: 0;
            bottom: 0;
            transform: translate(0, 104%);

            @media (max-width: 667px) {
                max-width: 73%;
            }

            @media (max-width: 667px) and (orientation: landscape) {
                max-width: 60%;
                top: -20px;
            }
        }

        .mnam-paste {
            position: absolute;
            z-index: 102;
            right: 0;
            bottom: 0;
            transform: translate(0, 104%);

            @media (max-width: 667px) {
                max-width: 45%;
            }
        }

        .mnam-circle {
            position: absolute;
            z-index: 100;
            right: 0;
            bottom: -75px;
            transform: translate(0, 104%);

            @media (max-width: 667px) {
                max-width: 50%;
            }
        }

        .jankadera-graphics {
            opacity: 0;
            transform: scale(3);
            position: relative;
            z-index: 1000;
        }

        .character-graphics {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) scale(0); 
            max-width: 100%;
            z-index: 1000;

            @media (max-width: 667px) {
                max-width: 33%;
            }
        }      
        
        .lines1-graphics {
            z-index: 100;
            position: absolute;
            bottom: 0;
            left: 30px;
            opacity: 0;
            transform: translate(100px, 100px);

            @media (max-width: 667px) {
                left: -30px;
                bottom: -40px;
            }
        }

        .lines2-graphics {
            z-index: 100;
            position: absolute;
            top: 20px;
            right: -30px;
            opacity: 0;
            transform: translate(-100px, -100px);
        }

        .morphhalf-graphics {
            position: absolute;
            left: 0;
            top: 3%;
            transform: translate(-100px, 0);

            @media (max-width: 667px) {
                left: -10%;
                max-width: 25%;
            }
        }

        .morphfull-graphics {
            position: absolute;
            right: 20px;
            bottom: 15%;
            transform: scale(0);

            @media (max-width: 667px) {
                right:0;
                max-width: 20%;
            }
        }
    }
}

.portfolio-actions {
    max-width: 1240px;
    margin: 0 auto;
    position: relative;
    z-index: 200;
    
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 667px) {
        top: 0px;
        justify-content: space-between;
        padding: 0 15px;
    }

    .portfolio-buttons {
        display: flex;
        .portfolio__btn  {
            cursor: pointer;
            width: 72px;
            height: 72px;
            background: #fff;
            display: flex;
            align-items: center;
            margin: 0 5px;
            justify-content: center;
            border: none;
            transition: all .35s ease-in-out;

            svg path {
                transition: all .35s ease-in-out;
            }

            &:hover {
                background: #F11341;

                svg path {
                    fill:#fff;
                }
            }
        }
    }
}

.portfolio-pagination {

    ul {
        display: flex;
        list-style-type: none;

        li {
            margin: 0 5px;

            &.active {
                button {
                    background:#F11341;
                }
            }

            button {
                border: none;
                border-radius: 50%;
                width: 8px;
                height: 8px;
                padding: 0;
                background: #4A4A4A;
                cursor: pointer;
                transition: all .35s ease-in-out;

                &:hover {
                    opacity: 0.5;
                }
            }
        }
    }
}

.Home .portfolio-slider-wrapper {
    &:after {
        width: 250px;
    }
}

.success-msg {
    margin: 20px 0;
    width: 100%;
    padding: 10px;
    border: 2px solid #28ec73;
    text-align: center;
    color: #fff;
    background: #28ec73;
    font-size: 16px;
}

.introduction {
    background: #fff;
    color: #181A1A;
    padding: 50px 0 0 0;
    position: relative;
    z-index: 10;

    @media (max-width: 667px) {
        .row {
            flex-wrap: wrap;
        }

    }

    .column {
        @media (max-width: 667px) {
            width: 100%;
            flex: 0 0 100%;
        }
        
        &:nth-child(1) {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding-bottom: 0;

            @media (max-width: 667px) {
                display: flex;
                flex-wrap: wrap;
            }
        }
    }

    .subtitle {
        color: #181A1A;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 1.6px;

        @media (max-width: 667px) {
            font-size: 15px;
        }
    }

    h2.title {
        font-weight: 200;
        font-size: 36px;
        margin-bottom: 20px;
        color: #181A1A;

        @media (max-width: 667px) {
            font-size: 28px; 
        }
    }

    p {
        margin-bottom: 25px;

        strong {
            font-size: 20px;
            font-weight: 800;
        }

        @media (max-width: 667px) {
            margin-bottom: 20px;
        }
    }

    .link-holder {
        color: #F11341;
        margin-bottom: 55px;

        a {
            color: #F11341;
            display: inline-flex;
            align-items: center;
            transition: all .35s ease-in-out;

            svg {
                transition: all .35s ease-in-out;
                margin-left: 10px;
                path {
                    transition: all .35s ease-in-out;
                }
            }

            &:hover {
                color: #181A1A;
                svg {
                    transform: translate(5px, 0);

                    path {
                        stroke: #181A1A;
                    }
                }
            }
        }
    }

    img {
        display: block;
        max-width: 80%;
        height: auto;
        margin-top: -120px;

        @media (max-width: 667px) {
            max-width: 100%;
            margin-top: -80px;
        }
    }

    .ceo-badge {
        display: inline-block;
        border-radius: 10px;
        background: #181A1A;
        padding: 20px 30px;
        line-height: 1;

        @media (max-width: 667px) {
            padding: 17px 18px;
            font-size: 15px;
        }

        .divider {
            margin: 0 15px;
            display: inline-block;
            color: #464646;
            
            @media (max-width: 667px) {
                margin: 0 7px;
            }
        }

        .ceo-label {
            background: -webkit-linear-gradient(0, #FFA5B7, #F11341);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: 600;
        }

        .ceo-name {
            color: #fff;
            font-weight: 600;
        }
    }

    .ceo-action {
        display: flex;
        align-items: center;
        margin-bottom: 50px;
    }

    .ceo-connections {
        display: flex;
        margin-left: 30px;

        @media (max-width: 667px) {
            margin-left: 20px;
        }

        li {
            margin-right: 25px;
            @media (max-width: 667px) {
                margin-right: 18px;
            }
            a {
                svg path {
                    transition: all .25s ease-in-out;
                }

                &:hover svg path {
                    fill: #181A1A;
                }
            }
        }
    }

}


/**
* Services home page
*/

.center-headline {
    text-align: center;
    margin-bottom: 40px;

    .subtitle { 
        text-transform: uppercase;
        margin-bottom: 15px;
        display: inline-block;
        background: -webkit-linear-gradient(0, #FFA5B7, #F11341);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 16px;
        letter-spacing: 1.6px;
        margin-bottom: 10px;

        @media (max-width: 667px) {
            font-size: 14px;
        }
    }

    .title {
        color: #fff;
        font-weight: 200;
        font-size: 48px;
        margin-top: 0;
        margin-bottom: 20px;

        @media (max-width: 667px) {
            font-size: 28px;
        }
    }
}

.services-wrapper {
    padding: 20vh 15px 80px 15px;
    background: #131414;

    @media (max-width: 667px) {
        padding-top: 60px;
    }

    .services {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 49px 32px;
        max-width: 1280px;
        margin: 45px auto;

        @media (max-width: 992px) {
            grid-template-columns: 1fr 1fr;
        }

        @media (max-width: 667px) {
            grid-template-columns: 1fr;
            grid-gap: 20px;
        }

        .service-item {
            color: #fff;
            background: #181A1A;
            padding: 80px 55px 35px 55px;
            text-align: center;
            position: relative;
            overflow: hidden;

            @media (max-width: 667px) {
                padding: 40px 25px 35px 25px;   
            }

            &:hover {
                &:after {
                    height: 4px;
                }

                .service-item__link-holder {
                    opacity: 1;
                    transform: none;
                }

                .service-item__content {
                    transform: translate(0, -30px);
                }
            }

            &:after {
                content: '';
                background: linear-gradient(280deg, #F11341 35.3%, #FFA5B7 96.79%);
                position: absolute;
                right: 0;
                bottom: 4px;
                width: 100%;
                height: 0;
                transition: all .35s ease-in-out;
            }

            &__content {
                transition: all .35s ease-in-out;
            }

            &__image {
                margin-bottom: 62px;
                transition: all .35s ease-in-out;

                @media (max-width: 667px) {
                    margin-bottom: 25px;
                }
            }

            &__title {  
                color: #fff;
                font-size: 24px;
                font-weight: 200;
                margin-bottom: 12px;
            }

            &__desc {
                font-size: 14px;
                font-weight: 200;
                line-height: 2;
                color: #fff;
            }

            &__tags {
                margin: 18px 0 0 0;
                display: flex;
                color: rgba(255, 255, 255, 0.4);
                flex-wrap:wrap;
                justify-content: center;

                span {
                    margin: 0 3px;
                    font-size: 12px;
                    background: rgba(255, 255, 255, 0.04);
                    border-radius: 10px;
                    padding: 4px 8px;
                    margin-bottom: 6px;
                }
            }

            &__link-holder  {
                position: absolute;
                bottom: 30px;
                text-align: center;
                width: 100%;
                z-index: 100;
                left: 0;
                opacity: 0;
                transform: translate(0, 50px);
                transition: all .35s ease-in-out;

                .link {
                    background: linear-gradient(282deg, #F11341 46.43%, #FFA5B7 96.23%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-size: 16px;
                    letter-spacing: 0.64px;
                    text-transform: uppercase;

                    &:hover {

                    }
                }

            }
        }
    }
}

/* buttons style */
.btn-holder {
    text-align: center;
}

.btn--outline {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 300;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 0.64px;
    border: none;
    color: #fff;
    padding: 15px 30px;
    height: 66px;
    position: relative;
    margin-top:0;
    background: linear-gradient(
      to right, 
      rgba(255, 165, 183, 1) 0%,
      rgba(241, 19, 63, 1) 100%
    );

    @media (max-width: 1024px) {
        font-size: 15px;
    }
    
    @media (max-width: 667px){ 
        padding: 15px 20px;
        width: 100%;
    }

    &:hover{
        color: #fff;
        &:before {
            opacity: 0;
        }

        .hand {
            transform:translate(0, -1px) rotate(45deg);
        }
    }

    .text {
      position: relative;
      z-index: 100;
    }

    .hand {
        display: inline-block;
        transform-origin: 50% 50%;
        position: relative;
        transition: all 0.35s ease-in-out;
    }

    &:before {
      content: "";
      position: absolute;
      width: calc(100% - 6px);
      height: calc(100% - 6px);
      left: 3px;
      top: 3px;
      background: #131414;
      transition: all 0.35s ease-in-out;
    }
}

.hero-wrapper {
    margin: 0 auto;
    max-width: 1380px;
    padding: 0 60px;
    position: relative;
    z-index: 1000;

    @media (max-width: 992px) {
        padding: 0 40px;
    }

    @media (max-width: 667px) { 
        padding: 0 15px;
        display: flex;
        flex-wrap: wrap;
    }

    .btn-holder { 
        opacity: 0;
    }

    video {
        max-width: 720px;
        height: auto;
        display: block;
        border: none;
        margin-top: 40px;
        background: transparent;

        @media (max-width:1440px) {
            margin-top: 0;
        }

        @media (max-width: 1024px) {
            max-width: 520px;         
        }

        @media (max-width: 820px) {
            max-width: 490px;         
        }

        @media (max-width: 667px) {
            max-width: 100%;
        }

        @media (max-width: 667px) and (orientation: landscape) {
            max-width: 50%;
            margin: 0 auto;
        }
    }

    .hero-left {
        position: relative;
        z-index: 1000;
        max-width: 760px;

        @media (max-width: 1440px) {
            max-width: 660px;
        }

        @media (max-width: 1024px) {
            max-width: 550px;
        }

        @media (max-width: 992px){
            max-width: 400px;
        }

        @media (max-width: 667px) and (orientation: landscape) {
            max-width: 400px;
            margin: 0 auto;
        }

        .btn-holder  {
            text-align: left;
        }

        .btn--outline:before {
            background:#181A1A;
        }
    }

    .sub_text {
        max-width: 550px;

        p {
            margin-bottom: 20px;
        }
    }

    .hero-right {
        position: absolute;
        left: 50%;
        transform: scale(1.5);
        opacity: 0;

        @media (max-width: 1280px) {
            left: 48%;
        }

        @media (max-width: 1024px) {
            left: 52%;
        }

        @media (max-width: 992px){
            top: 50%;
            left: 45%;
            transform: scale(1.5) translate(0, -40%);
        }

        @media (max-width: 820px) {
            left: 44%;
            transform: scale(1.4) translate(0, -40%);
        }

        @media (max-width: 667px) {
            position: relative;
            left: auto;
            top: auto;
            transform: none;
            order: -1;
            margin-top: -30px;

            // overlay on mobile for tap nowork
            &:after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 1000;
            }
        }
    }
}


.services .image-holder {
    position: relative;
}

.lf-player-container {
    position: absolute;
    left: 50%;
    top: -10px;
    width: 86px;
    height: 86px;
    z-index: 100;
    transform: translate(-50%, 0);

    opacity: 0;
}

.service-item:hover img {
    opacity: 0;
    transition: none;
}

.service-item:hover .lf-player-container {
    opacity: 1;
    transition: none;
}