.referenceView {
  position: relative;

  header {
    .AU_animation1 {
      transform: translateX(-650px);
    }
    .AU_animation2 {
      transition: all 2s ease-in-out;
      opacity: 0;
    }
    position: relative;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 82px 0 0 0;
      width: 1700px;
      margin: 0 auto;
      position: relative;
      z-index: 100;
      .text,
      a {
        transition: 0.5s ease-in-out all;

        @media (max-width: 667px) {
          img {
            max-width: 125px;
          }
        }
      }
      @media (max-width: 1700px) {
        width: 95%;
      }
      @media (max-width: 1300px) {
        width: 95%;
        padding-top: 45px;
      }
      @media (max-width: 900px) {
        width: 90%;
        padding: 30px 0 30px 0;
      }
      @media (max-width: 768px) {
        .line_one {
          width: 450px;
        }
      }
      @media (max-width: 720px) {
        width: 90%;
      }
      @media (max-width: 667px) {
        width: 100%;
      }
      @media (max-width: 500px) {
        display: block;
      }
      .left {
        color: #fff;
        font-weight: bold;
        height: 50px;
        cursor: pointer;
        @media (max-width: 992px) {
          padding-left: 15px;
        }
        span {
          font-weight: 200;
          display: block;
        }
      }
      .right {
        color: #fff;
        font-weight: bold;
        cursor: pointer;
        @media (max-width: 500px) {
          margin-top: 20px;
        }
      }
    }
    .header-container {
      width: 1240px;
      margin: 90px auto 0 auto;
      @media (max-width: 1450px) {
        width: 1000px;
      }
      @media (max-width: 1240px) {
        width: 85%;
      }
      @media (max-width: 1050px) {
        margin-top: 50px;
      }
      @media (max-width: 900px) {
        width: 90%;
      }

      @media (max-width: 950px) {
        & {
          margin: 0 auto;
          text-align: center;
          width: 95%;
          padding-left: 0px;
        }
      }
      .service {
        color: $headline;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        font: {
          style: normal;
          weight: 600;
          size: 14px;
        }
        line-height: 18px;
      }
      .text {
        width: 638px;
        @media (max-width: 900px) {
          width: 90%;
          margin: 15px auto 0 auto;
        }
        h1 {
          margin: {
            top: 15px;
          }
          color: #fff;
          font: {
            style: normal;
            weight: 600;
            size: 52px;
          }
          line-height: 59px;
        }
      }
      @media (max-width: 1440px) {
        .text h1 {
          font: {
            size: 32px;
          }
          line-height: 39px;
        }
      }
      @media (max-width: 950px) {
      }
    }
    .header-container_2 {
      margin: {
        top: 92px;
        left: auto;
        right: auto;
      }
      width: 1240px;
      @media (max-width: 1450px) {
        width: 1000px;
      }
      @media (max-width: 1240px) {
        width: 85%;
      }
      @media (max-width: 900px) {
        width: 90%;
      }
      .grid {
        display: flex;
        @media (max-width: 720px) {
          display: block;
        }
        .header_box {
          width: 390px;
          margin-right: 250px;
          margin-bottom: 49px;
          @media (max-width: 1050px) {
            margin-right: 80px;
          }
          @media (max-width: 720px) {
            margin: 60px auto 20px auto;
            width: 90%;
          }
          h3 {
            margin: {
              bottom: 25px;
            }
            width: 275px;
            color: #fff;
            font: {
              style: normal;
              weight: 600;
              size: 30px;
            }
            line-height: 38px;
            @media (max-width: 720px) {
              margin-bottom: 15px;
              width: 100%;
            }
          }
          p {
            @media (max-width: 900px) {
              width: 95%;
              margin: 0 auto;
            }
            color: #d1d5db;
            font: {
              style: normal;
              weight: 400;
              size: 16px;
            }
            line-height: 29px;
          }
        }
      }
      @media (max-width: 720px) {
        text-align: center;
      }
    }
    svg.header_line {
      position: absolute;
      top: 350px;
      right: 0;
      z-index: 1;
      .line-path {
        stroke-dasharray: 2500px;
        stroke-dashoffset: 2500px;
      }
      @media (max-width: 1100px) {
        width: 350px;
        right: -150px;
        top: 150px;
      }
      @media (max-width: 667px) {
        right: -120px;
        width: 230px;
        top: 50px;
      }
    }
    svg.header_line--services {
      right: -250px;
      bottom: -750px;
      transform: rotate(-35deg);
    }
  } /* ./header */

  .hero--sub {
    padding: 193px 0 140px 0;
    text-align: left;
    position: relative;
    z-index: 1000;
    height: 695px;
    margin-top: -135px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @media (max-width: 667px) {
      padding: 145px 20px 116px 20px;
      margin-top: -90px;
      text-align: center;
      height: auto;
      background-image: url("../../images/transparex/transparex-bg-mobile.png") !important;
    }

    &__text {
      transform: translate(0, 500px);
      opacity: 0;
      transition: all 1.5s ease-in-out;
      @media (max-width: 768px) {
        max-width: 500px;
      } 
    }

    .hashtags {
        @media (max-width: 667px) {
            justify-content: center;
        }
    }

    .subtitle {
      color: #fff;
      text-transform: uppercase;
      font-size: 14px;
      letter-spacing: 0.2em;
      margin-bottom: 17px;
      font-weight: 500;

      @media (max-width: 667px) {
        display: none;
      }
    }

    h1 {
      color: #fff;
      font-weight: 500;
      font-size: 52px;
      margin-bottom: 15px;
      max-width: 730px;
      @media (max-width: 768px) {
        font-size: 48px;
      }
      @media (max-width: 667px) {
        font-size: 24px;
        margin-bottom: 10px;
        font-weight: 400;
      }
    }

    p {
      color: #fff;
      font-size: 20px;
      font-weight: 300;
      margin-bottom: 35px;
      max-width: 880px;
      margin-left: auto;
      margin-right: auto;

      @media (max-width: 992px) {
        max-width: 600px;
      }

      @media (max-width: 667px) {
        font-size: 14px;
        max-width: 290px;
      }
    }

    strong {
      font-weight: 700;
      color: #fff;
      display: block;
    }
  } /* ./hero--sub */

  .hero--services {
    padding-top: 80px;
    padding-bottom: 304px;

    @media (max-width: 667px) {
      padding-top: 50px;
      padding-bottom: 220px;
      h1 {
        margin-bottom: 20px;
      }
    }
  }

  .hero-subtitle {
    color: #e5375d;
    margin-bottom: 25px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.2em;
    font-size: 14px;

    @media (max-width: 667px) {
      font-size: 10px;
      margin-bottom: 9px;
    }
  }

  .content {
    background: #fff;
    padding: 70px 0 0 0;
    margin-bottom: 0;

    @media (max-width: 667px) {
      padding: 50px 0 0 0;
    }
  } /* content */

  .block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 110px;

    @media (max-width: 667px) {
      flex-wrap: wrap;
      margin-bottom: 30px;
    }

    &:nth-child(1) {
      .block__text {
        @media (max-width: 667px) {
          order: 2;
        }
      }
    }

    &:nth-child(2) {
      .block__text {
        margin-left: 50px;
        margin-right: 0;

        @media (max-width: 667px) {
          margin-left: 0;
        }
      }
    }

    &:nth-child(3) {
      margin-bottom: -20px;
      .block__text {
        @media (max-width: 667px) {
          order: 2;
        }
      }
    }

    &__text {
      max-width: 424px;
      margin-right: 50px;

      @media (max-width: 667px) {
        margin-right: 0;
        max-width: 100%;
      }
    }

    .text-toptitle {
      color: #e5375d;
      font-weight: 700;
      font-size: 14px;
      margin-bottom: 14px;
      text-transform: uppercase;
      letter-spacing: 0.2em;

      @media (max-width: 667px) {
        font-size: 12px;
        margin-bottom: 0;
      }
    }

    h2 {
      margin-top: 15px;
      font-size: 43px;
      margin-bottom: 15px;
      font-weight: 500;

      @media (max-width: 667px) {
        margin-top: 5px;
        font-size: 24px;
      }
    }

    p {
      color: #828282;
      font-size: 20px;
      line-height: 1.75;
      margin-bottom: 35px;

      @media (max-width: 667px) {
        font-size: 14px;
        margin-bottom: 25px;
      }

      strong {
        color: #828282;
      }
    }

    img {
      display: block;
      max-width: 100%;
      height: auto;

      @media (max-width: 667px) {
        margin-bottom: 25px;
      }
    }
  } /* /.block */

  .block--boxes {
    display: flex;
    justify-content: space-between;
    margin-bottom: 160px;

    @media (max-width: 768px) {
        display: block;
    }

    @media (max-width: 667px) {
      display: block;
      margin-bottom: 70px;
    }

    .service-box {
      background: #f9f9f9;
      flex: 0 1 49%;
      padding: 40px 35px;
      min-height: 290px;
      transition: all 1s ease-in-out;

      // animations
      transform: translate(0, 50px);
      opacity: 0;

      @media (max-width: 768px) {
        margin-bottom: 25px;
      }

      @media (max-width: 667px) {
        min-height: 260px;
        margin-bottom: 15px;
        padding-right: 26px;
      }

      &__head {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 20px;

        h3 {
          margin: 0;
          font-size: 16px;
          font-weight: 700;
        }

        img {
          margin-right: 20px;
          max-width: 30px;

          @media (max-width: 667px) {
            margin-right: 15px;
          }
        }
      }

      p {
        font-size: 14px;
        color: #000;
        opacity: 0.6;
        margin-bottom: 0;
        font-weight: 500;
      }
    }
  }

  .portfolio-holder {
    position: relative;
    overflow: hidden;
  }

  .portfolio-slider-wrapper {
    padding-top: 58px;
    // display: flex;

    &:after {
      content: "";
      position: absolute;
      width: 500px;
      height: 100%;
      right: 0;
      top: 0;
      background: linear-gradient(270deg, #000807 0%, rgba(0, 8, 7, 0) 100%);
      z-index: 0;

      @media (max-width: 1440px) {
        width: 400px;
      }

      @media (max-width: 667px) {
        display: none;
      }

      @media (min-width: 2500px) {
        width: 950px;
      }
    }

    @media (max-width: 667px) {
      padding-top: 40px;
    }

    .portfolio-item {
      margin-right: 32px;
      display: block;

      @media (max-width: 667px) {
        margin-right: 10px;
        width: 90%;
      }

      &:hover {
        img {
          transform: scale(1.05);
        }
      }

      &:not(.is-selected) {
        h3,
        p,
        .hashtags {
          opacity: 0.5;
        }
      }

      .img-wrapper {
        overflow: hidden;
        margin-bottom: 40px;

        @media (max-width: 667px) {
          margin-bottom: 20px;
        }
      }

      img {
        max-width: 710px;
        display: block;
        height: auto;
        transition: all 0.8s ease-in-out;

        @media (max-width: 667px) {
          max-width: 100%;
        }
      }

      h3 {
        font-weight: 400;
        font-size: 30px;
        margin-bottom: 5px;
        color: #fff;
        line-height: 1;
        transition: all 0.6s ease-in-out;

        @media (max-width: 667px) {
          font-size: 18px;
        }
      }

      p {
        color: #fff;
        font-size: 14px;
        transition: all 0.6s ease-in-out;

        @media (max-width: 667px) {
          font-size: 12px;
        }
      }

      .hashtags {
        margin-top: 20px;
        transition: all 0.6s ease-in-out;

        @media (max-width: 667px) {
          margin-top: 5px;
        }
        span {
          display: inline-block;
          margin-right: 8px;
          font-weight: 600;
          font-size: 14px;
          color: #f3385a;
          @media (max-width: 667px) {
            font-size: 12px;
            font-weight: 400;
          }
        }
      }
    }

    .flickity-viewport {
      overflow: visible;
    }

    &.flickity-enabled {
      position: static;
    }
  }

  .portfolios {
    padding: 110px 0;
    position: relative;

    @media (max-width: 667px) {
      padding: 47px 0 70px 0;
    }

    &--upper {
      @media (max-width: 667px) {
        padding-top: 300px;
      }
    }

    .portfolio-head {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 667px) {
        justify-content: center;
        text-align: center;
      }

      .toptitle {
        font-size: 14px;
        text-transform: uppercase;
        color: #e5375d;
        font-weight: 700;
        letter-spacing: 0.2em;
        margin-bottom: 15px;

        @media (max-width: 667px) {
          font-size: 10px;
          margin-bottom: 0;
        }
      }

      h2 {
        color: #fff;
        font-size: 46px;
        max-width: 260px;
        line-height: 1.15;

        @media (max-width: 667px) {
          font-size: 24px;
          max-width: 100%;
        }
      }

      .head-action {
        padding-top: 55px;

        @media (max-width: 667px) {
          display: none;
        }
      }
    }

    .action-mobile {
      display: none;
    }

    @media (max-width: 667px) {
      .action-mobile {
        margin-top: 23px;
        text-align: center;

        .btn--primary {
          height: 55px;
        }
      }
    }
  }

  .block--intro {
    transition: all 0.85s ease-in-out;

    max-width: 800px;
    margin: 0 auto 100px auto;

    // animation
    transform: translate3d(-150px, 0, 0);
    opacity: 0;

    @media (max-width: 667px) {
      margin-bottom: 40px;
    }

    h2 {
      font-size: 36px;
      font-weight: 500;
      margin-bottom: 25px;

      @media (max-width: 667px) {
        display: none;
      }
    }

    p {
      font-size: 18px;
      max-width: 800px;
      line-height: 1.85;

      @media (max-width: 667px) {
        font-size: 14px;
      }
    }
  }

  .kontakt {
    margin-top: 60px;

    .AU_animation12 {
      transform: translateX(-580px);
    }
    .AU_animation13 {
      transform: translateX(800px);
    }
    width: 1240px;
    height: 386px;
    margin: 0 auto;
    background: #f3385a;
    border-radius: 0;
    margin-bottom: 127px;
    display: flex;
    @media (max-width: 1440px) {
      width: 85%;
    }
    @media (max-width: 900px) {
      width: 80%;
      height: auto;
      display: grid;
    }
    @media (max-width: 667px) {
      width: 90%;
      margin-bottom: 61px;
    }
    .left {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 0;
      @media (max-width: 1100px) {
        width: 45%;
      }
      @media (max-width: 900px) {
        width: 100%;
        text-align: center;
        margin-top: 30px;
      }
      @media (max-width: 330px) {
        width: 280px;
      }
      div {
        width: 100%;
        padding: 0 50px 0 100px;

        @media (max-width: 768px) {
            padding: 0 50px;
        }
        @media (max-width: 667px) {
          padding: 0;
        }

        h2 {
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 23px;
          letter-spacing: 0.2em;
          text-transform: uppercase;
          color: #000;
          @media (max-width: 1024px){
            font-size: 24px;
        }
          @media (max-width: 667px) {
            font-size: 12px;
          }
        }
        h3 {
          margin-top: 20px;
          width: 100%;
          font-style: normal;
          font-weight: 700;
          font-size: 36px;
          line-height: 40px;
          color: #fff;
          @media (max-width: 1024px){
            font-size: 24px;
        }
          @media (max-width: 500px) {
            font-size: 32px;
            line-height: 35px;
          }
          @media (max-width: 667px) {
            font-size: 18px;
            font-weight: 400;
            line-height: 1.25;
            margin-top: 0;
          }
        }
        p {
          margin-top: 35px;
          color: #fff;
          font-size: 36px;
          line-height: 1.1;
          @media (max-width: 1024px){
            font-size: 24px;
        }
          @media (max-width: 667px) {
            font-size: 18px;
            font-weight: 400;
            line-height: 1.25;
            margin-top: 25px;
          }
        }
      }
    }
    .right {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 1100px) {
        width: 55%;
      }
      @media (max-width: 900px) {
        width: 100%;
        padding: 20px 0 20px 0;
      }
      @media (max-width: 667px) {
        width: 100%;
        padding: 15px 15px 50px 15px;
      }
      form {
        width: 80%;
        @media (max-width: 1100px) {
          width: 90%;
        }
        @media (max-width: 900px) {
          width: 346px;
        }
        @media (max-width: 500px) {
          width: 300px;
        }
        @media (max-width: 330px) {
          width: 100%;
        }
        input,
        textarea {
          width: 344px;
          background: #ffffff;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
          border-radius: 3px;
          padding: 9px 13px;
          margin-bottom: 14px;
          border: none;
          outline: none;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #6b7280;
          @media (max-width: 500px) {
            width: 100%;
          }
        }
        input {
          height: 50px;
        }
        textarea {
          height: 132px;
        }
        .checkbox {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          input {
            width: 21px;
            height: 21px;
            background: #ffffff;
            border-radius: 3px;
            border: none;
            box-shadow: none;
            margin-bottom: 0;
            margin-right: 10px;
          }
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #ffffff;
          a {
            margin-left: 5px;
            color: #ffffff;
            text-decoration: underline;
          }
          @media (max-width: 800px) {
            display: block;
          }
        }
        a.Mybtn {
          rect {
            stroke-dasharray: 669px;
            stroke-dashoffset: 669px;
          }
          position: relative;
          display: block;
          overflow: hidden;
          @include size(100%, 50px);
          max-width: 129px;
          border-radius: 30px;
          span {
            @include absolute();
            display: block;
            @include size(0);
            border-radius: 50%;
            background-color: #fff;
            transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
            transform: translate(-50%, -50%);
          }

          &:hover {
            span {
              @include size(225%, 298px * 2.25);
            }
            .text {
              color: #f3385a;
            }
          }
          .text {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 34px;
            color: transparent;
            animation-duration: 2s;
            animation-fill-mode: forwards;
            transition: 2s color;
          }
        }
        @media (max-width: 950px) {
          a.Mybtn {
            margin: 0 auto;
            .text {
              font-size: 14px;
            }
            &:hover {
              span {
                @include size(0%, 129px * 2.25);
              }
              .text {
                color: #fff;
              }
            }
          }
        }
      }
    }
  } /* ./kontakt */

  footer {
    padding-bottom: 50px;
    .info {
        background: linear-gradient(to right, #f1133f 0%, #ffa5b7 100%);
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      margin-bottom: 81px;
      height: 150px;
      img {
        width: 100%;
        opacity: 0.19;
      }
      @media (max-width: 950px) {
        margin-bottom: 10px;
      }
      .columns {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        @media (max-width: 950px) {
          text-align: center;
          .is-2 {
            padding: 0;
          }
          .is-7 {
            height: 100%;
            margin: 0 auto;
          }
        }
        .all {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          @media (max-width: 950px) {
            .animation_13 {
              display: grid;
            }
          }
          a {
            font-style: normal;
            font-weight: 300;
            font-size: 56px;
            line-height: 59px;
            color: white;
            opacity: 0.5;
            transition: all 0.5s ease-in-out;
            &:hover {
              opacity: 0.9;
            }
            @media (max-width: 1800px) {
              font-size: 45px;
            }
            @media (max-width: 1280px) {
              font-size: 39px;
            }
            @media (max-width: 1050px) {
              font-size: 32px;
            }
            @media (max-width: 950px) {
              font-weight: 100;
            }
            @media (max-width: 667px) {
              font-weight: 400;
            }
          }
          a.email {
            margin-right: 60px;
            @media (max-width: 1280px) {
              margin-right: 25px;
            }
            @media (max-width: 950px) {
              margin-right: 0px;
            }
          }
        }
      }
      .black_wall {
        background-color: #000807;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
        margin: auto;
        height: 100%;
        transform: translate(0%);
        transition: all 1s ease-in-out;
      }
      .black_wall:after {
        content: "";
        position: absolute;
        left: -179px;
        border-right: 180px solid #000807;
        border-top: 192px solid transparent;
      }
    }
    .end {
      opacity: 0;
      transition: all 0.5s ease-in-out;
      @media (max-width: 950px) {
        text-align: center;
      }
      .center_up {
        color: #9ca3af;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
      }
      .center_down {
        margin-top: 11px;
        color: #9ca3af;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
      }
      .social {
        display: flex;
        align-items: center;
        height: 20px;
        margin-top: 27px;
        a {
          position: relative;
          height: 100%;
          margin-right: 8px;
          //border-image-slice: 1;
          //border-bottom: 1px solid;
          //border-width: 1px;
          //border-image-source: linear-gradient(277.48deg, $bg-color 8.64%, $bg-color 56.22%, $bg-color 97.03%);
          transition: all 0.25s ease-in-out;
          &:before {
            content: "";
            width: 0;
            height: 3px;
            position: absolute;
            bottom: -10px;
            left: 0;
            background: linear-gradient(
              277.48deg,
              #0fa6e1 8.64%,
              #f3385a 56.22%,
              #45187a 97.03%
            );
            transition: all 0.35s ease-in-out;
          }
          &:hover {
            transform: translate(0, -5px);
            &:before {
              width: 100%;
            }
          }
        }
        @media (max-width: 950px) {
          justify-content: center;
          margin-top: 10px;
        }
      }
      .links {
        display: grid;
        float: right;
        a {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 5px;
          color: #ffffff;
          border-image-slice: 1;
          border-bottom: 2px solid;
          border-width: 2px;
          border-image-source: linear-gradient(
            277.48deg,
            $bg-color 8.64%,
            $bg-color 56.22%,
            $bg-color 97.03%
          );
          &:hover {
            transform: translateY(0px);
            transition: all 0.2s ease-in-out;
            border-image-source: linear-gradient(
              277.48deg,
              #0fa6e1 8.64%,
              #f3385a 56.22%,
              #45187a 97.03%
            );
            @media (max-width: 950px) {
              transform: translateY(0px);
              border-image-source: linear-gradient(
                277.48deg,
                $bg-color 8.64%,
                $bg-color 56.22%,
                $bg-color 97.03%
              );
            }
          }
        }
        @media (max-width: 950px) {
          float: none;
        }
      }
      .copy {
        margin-top: 60px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
      }
    }
  } /* ./footer */

  .back-link {
    color: #fff;
    font-size: 14px;
    display: flex;
    align-items: center;
    font-weight: 500;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
    margin-bottom: 78px;
    letter-spacing: 0.2em;

    // animations
    opacity: 0;

    @media (max-width: 667px) {
      margin-bottom: 15px;
      font-size: 10px;
      justify-content: center;
    }

    svg {
      margin-right: 20px;
      position: relative;
      top: -1px;
      path {
        transition: all 0.35s ease-in-out;
      }

      @media (max-width: 667px) {
        margin-right: 8px;
      }
    }

    &:hover {
      color: #f3385a;

      svg path {
        stroke: #f3385a;
      }
    }
  }

  .hashtags {
    display: flex;
    color: #fff;
    flex-wrap: wrap;

    @media (max-width: 667px) {
      //justify-content: center;
      margin-top: 25px;
    }

    li {
      margin-right: 19px;
      font-size: 12px;
      text-transform: uppercase;
      padding-top: 15px;
      letter-spacing: 0.2em;
      font-weight: 600;

      @media (max-width: 667px) {
        font-size: 9px;
        margin-right: 8px;
        padding-top: 5px;
      }
    }
  }

  .block--cover-image {
    margin-bottom: 127px;
    position: relative;

    @media (max-width: 667px) {
      padding: 40px 0;
      background: #4fa0f1;
      margin-bottom: 40px;
    }

    .overlayer {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: #fff;
      z-index: 1000;
      transition: all 1s linear;
    }

    img {
      height: auto;
      max-width: 1920px;
      margin: 0 auto;
      width: 100vw;

      @media (min-width: 1921px) {
        width: 100%;
        max-width: 100%;
      }

      @media (max-width: 667px) {
        object-fit: cover;
        height: 245px;
      }
    }
  }

  .block--logo {
    margin: 0 auto;
    text-align: left;
    margin-bottom: 142px;

    @media (max-width: 667px) {
      margin-bottom: 56px;
    }

    .block-top {
      max-width: 450px;
      margin: 0 auto;
      transition: all 0.65s linear;

      // animations
      opacity: 0;
      transform: translate(0, 100px);
    }

    .block-title {
      font-weight: 500;
      @media (max-width: 667px) {
        font-size: 15px;
        font-weight: 600;
      }
    }

    .block-image {
      display: block;
      margin: 142px auto 124px auto;
      transition: all 0.85s linear;

      // animations
      opacity: 0;
      transform: translate(0, 100px);

      @media (max-width: 667px) {
        margin: 60px auto;
      }
    }

    .block-text {
      line-height: 1.85;
      @media (max-width: 667px) {
        font-size: 14px;
      }
    }
  }

  .block--image-pack {
    margin-bottom: 50px;
    position: relative;

    @media (max-width: 667px) {
      margin-bottom: 35px;
    }

    .overlayer {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1000;
      background: #efefef;
      transition: all 1s linear;
    }

    img {
      display: block;
      margin: 0 auto;
      max-width: 100%;

      @media (max-width: 667px) {
        height: 245px;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .block--screens {
    overflow: hidden;
    margin-top: 250px;

    @media (max-width: 667px) {
      margin-top: 150px;
    }

    img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
      width: 100%;
      transition: all 1s ease-in-out;

      // animation
      opacity: 0;
      transform: scale(3);

      @media (max-width: 667px) {
        height: 225px;
        object-fit: cover;
      }
    }
  }

  .block--previews {
    background: #2d323b;
    padding: 230px 0 0 0;
    overflow: hidden;
    position: relative;

    @media (max-width: 667px) {
      padding: 70px 15px;
    }

    .preview-top {
      margin: 0 auto;
      max-width: 920px;
      color: #fff;
      text-align: center;
      transition: all 1s ease-in-out;

      // animation
      opacity: 0;
      transform: translate(0, 100px);

      .top-title {
        color: #fff;
        margin-bottom: 30px;
        font-weight: 400;
        font-size: 20px;

        @media (max-width: 667px) {
          font-size: 12px;
          margin-bottom: 15px;
        }
      }

      .top-text {
        color: #fff;
        font-weight: 200;
        font-size: 24px;
        line-height: 1.75;
        margin-bottom: 30px;

        @media (max-width: 667px) {
          font-size: 14px;
        }
      }

      .name {
        color: #f3385a;
        font-size: 30px;
        font-weight: 200;

        @media (max-width: 667px) {
          font-size: 14px;
        }
      }

      .position {
        color: #fff;
        font-size: 18px;
        font-weight: 200;
        @media (max-width: 667px) {
          font-size: 12px;
        }
      }
    }

    .previews-screens {
      padding: 200px 0 100px 0;

      @media (max-width: 667px) {
        padding: 20px 0 0px 0;
      }

      .screen-image {
        margin-bottom: 50px;

        @media (max-width: 667px) {
          margin-bottom: 20px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      .block-image {
        margin: 0 auto;
        display: block;
        transition: all 1s ease-in-out;
        border-radius: 8px;

        // animations
        opacity: 0;
        transform: translate(0, 50px);
      }
    }
  }

  .svg-line-left {
    position: absolute;
    left: 0;
    top: -200px;

    .preview-line-l {
      .preview-line-lp {
        stroke-dasharray: 2500px;
        stroke-dashoffset: 2500px;
      }
    }

    @media (max-width: 1024px) {
      display: none;
    }
  }

  .svg-line-right {
    position: absolute;
    right: 0;
    top: -550px;

    .preview-line-r {
      .preview-line-rp {
        stroke-dasharray: 2500px;
        stroke-dashoffset: 2500px;
      }
    }

    @media (max-width: 1024px) {
      display: none;
    }
  }

  .brand-logo-mobile {
    display: none;
    @media (max-width: 667px) {
      display: block;
      margin: 0 auto;
      max-width: 100%;
      margin-bottom: 50px;
    }
  }
}
/* transparex  */
.referenceView.transparex {
    .block--boxes {
        .service-box {
            flex: 0 1 32%;
        }
    }
}
/* Aldona Specific Styles */
.referenceView.aldona {
  .hashtags li {
    color: #3af0aa;
  }

  .hero--sub h1 {
    max-width: 600px;
  }

  .hero--sub {
    background: #343458;
    background-image: url("../../images/references/aldona/map.svg") !important;
    background-repeat: no-repeat;
    background-position: 90% -70px;

    @media (max-width: 667px) {
      background-position: center bottom;
      background-size: 50%;
    }
  }

  .block--logo {
    .block-top {
      max-width: 750px;
    }
  }

  .block--previews {
    background: #f7f7f7;
    padding: 150px 0;

    @media (max-width: 667px) {
      padding: 60px 0;
    }

    .previews-screens {
      padding: 0;
    }

    .screen-image {
      padding: 0 13px;
    }
  }

  .block--boxes {
    .service-box {
        flex: 0 1 32%;
    }
  }

  .services {
    padding: 150px 0 0 0;

    @media (max-width: 667px) {
      padding-top: 0;
      padding-bottom: 40px;
    }

    &__item {
      margin-bottom: 150px;
      height: 100%;
      justify-content: space-between;
      align-items: center;

      &:nth-child(2) {
        .services__image {
          min-height: 586px;
        }
      }

      @media (max-width: 667px) {
        margin-bottom: 40px;
        display: flex;
        flex-wrap: wrap;
      }

      // animation
      opacity: 0;
      transform: translate(0, 100px);
      transition: all 1s ease-in-out;

      &:nth-child(1) {
        .services__image {
          justify-content: flex-end;
        }
      }

      &:nth-child(2) {
        .services__image {
          justify-content: flex-start;
        }
      }
    }

    &__col {
      height: 100%;
      max-width: 500px;

      @media (max-width: 768px) {
        max-width: 100%;
      }

      @media (max-width: 667px) {
        flex: 0 0 100%;
      }

      h2 {
        font-size: 36px;

        @media (max-width: 667px) {
          font-size: 18px;
        }
      }

      p {
        max-width: 440px;

        @media (max-width: 768px) {
            max-width: 100%;
        }
      }
    }

    &__image {
      display: flex;
      align-items: flex-start;
      background: #f7f7f7;
      position: relative;

      @media (max-width: 667px) {
        order: -1;
        width: 100%;
      }
    }
  }

  /* 2nd images animated */
  .imgRef01 {
    position: absolute;
    top: 14%;
    max-width: 203px;
    left: 20%;
    z-index: 10;
    animation: moveImageToLeft 2s infinite alternate;

    @media (max-width: 667px) {
      left: 5%;
    }
  }

  .imgRef02 {
    position: absolute;
    top: 22%;
    max-width: 364px;
    left: 36%;
    z-index: 20;
    animation: moveImageToRight 2s infinite alternate;

    @media (max-width: 667px) {
      left: 14%;
    }
  }

  .imgRef03 {
    position: absolute;
    top: 24%;
    max-width: 249px;
    left: 40%;
    z-index: 30;
    transition: transform 0.65s linear;

    @media (max-width: 667px) {
      left: 20%;
      top: 30%;
    }
  }

  .imgDecor01 {
    position: absolute;
    top: 19%;
    max-width: 249px;
    left: 75%;
    z-index: 30;
  }

  .imgDecor02 {
    position: absolute;
    top: 72%;
    max-width: 249px;
    left: 23%;
    z-index: 30;

    @media (max-width: 667px) {
      left: 5%;
    }
  }

  @keyframes moveImageToLeft {
    0% {
      transform: translate(0, 0);
    }

    100% {
      transform: translate(-10%, 0);
    }
  }

  @keyframes moveImageToRight {
    0% {
      transform: translate(0, 0);
    }

    100% {
      transform: translate(10%, 0);
    }
  }
}

.referenceView.kastiel {
  .hashtags li {
    color: #835539;
  }

  .hero--sub h1 {
    max-width: 750px;
  }

  .hero--sub {
    background: #cc9370;
    background-image: url("../../images/references/kastiel/logo.svg"),
      url("../../images/references/kastiel/bg-pattern.png") !important;
    background-repeat: no-repeat, no-repeat;
    background-position: 75% 50%, right bottom;

    @media (max-width: 1024px) {
        background-position: 90% 50%, right bottom;
    }   

    @media (max-width: 667px) {
      background-position: 50% 90%, right bottom;
      background-size: 15%, 60%;
    }
  }

  .block--boxes {
    justify-content: center;

    .service-box {
      background: #fdf7f4;
      margin: 0 15px;
      flex: 0 1 30%;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      padding: 40px 3%;

      @media (max-width: 768px) {
        margin-bottom: 25px;
      }

      h3 {
        text-transform: uppercase;
      }

      @media (max-width: 667px) {
        margin: 0 0 25px 0;
      }
    }
  }

  .block--cover-image {
    background: #cc9370;
  }

  .block-images {
    background: #f6f6f6;
    width: 100%;
    padding: 100px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
    position: relative;

    @media (max-width: 1024px) {
      padding: 60px 0;
    }

    @media (max-width: 667px) {
      margin-bottom: 100px;
      padding: 40px 10px 20px 10px;
      margin-bottom: 40px;
    }

    img {
      display: block;
      margin: 0 1%;
      max-width: 100%;
      transition: all 1s ease-in-out;

      //animations
      opacity: 0;
      transform: translate(0, 200px);

      @media (max-width: 667px) {
        margin-bottom: 20px;
      }
    }
  }

  .overlayer {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: #fdf7f4;
    z-index: 1000;
    transition: all 1s linear;
  }

  .block--intro {
    max-width: 850px;
    margin-left: auto;
    margin-right: auto;

    p {
      width: 100%;
      max-width: 800px;
    }
  }

  .block--previews {
    background: #f7f7f7;
    padding: 150px 0;

    @media (max-width: 667px) {
      padding: 60px 0;
    }

    .previews-screens {
      padding: 0;
    }

    .screen-image {
      padding: 0 13px;
    }
  }
}

.referenceView.mnam {
  .hashtags li {
    color: #fff;
    opacity: 0.5;
  }

  .hero--sub h1 {
    max-width: 600px;
  }

  .hero--sub {
    background: #f15a35;
    background-image: url("../../images/references/mnam/noodle.png") !important;
    background-repeat: no-repeat;
    background-position: 95% 50%;

    @media (max-width: 1024px) {
        background-size: 65%;
        background-position: 95% bottom;
    }

    @media (max-width: 667px) {
      background-position: 60% 110%;
      background-size: 50%;
    }
  }

  .block--boxes {
    justify-content: center;

    @media (max-width: 667px) {
        margin-bottom: 40px;
    }

    .service-box {
      background: #fff5f3;
      margin: 0 15px;
      max-width: 812px;
      width: 100%;
      display: flex;
      flex: 1;
      justify-content: center;
      flex-direction: column;
      padding: 40px 5%;

      @media (max-width: 768px) {
        margin: 0;
      }

      @media (max-width: 667px) {
        margin: 0;
      }
    }
  }

  .block--cover-image {
    background: #cc9370;
  }

  .block-images {
    background: #f6f6f6;
    width: 100%;
    padding: 150px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 150px;
    position: relative;

    @media (max-width: 1024px) {
      padding: 60px 0;
    }

    @media (max-width: 667px) {
      margin-bottom: 100px;
      padding: 40px 10px 20px 10px;
      margin-bottom: 40px;
    }

    img {
      display: block;
      margin: 0 1%;
      max-width: 100%;
      transition: all 1s ease-in-out;

      //animations
      opacity: 0;
      transform: translate(0, 200px);

      @media (max-width: 667px) {
        margin-bottom: 20px;
      }
    }
  }

  .overlayer {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: #fdf7f4;
    z-index: 1000;
    transition: all 1s linear;
  }

  .block--intro {
    max-width: 850px;
    margin-left: auto;
    margin-right: auto;

    p {
      max-width: 100%;
    }
  }

  .block--previews {
    background: #f7f7f7;
    padding: 150px 0;

    @media (max-width: 667px) {
      padding: 60px 0;
    }

    .previews-screens {
      padding: 0;
    }

    .screen-image {
      padding: 0 13px;
    }

    .block-image {
      margin-bottom: 40px;
    }
  }

  .block--screens {
    @media (max-width: 667px) {
      margin-top: 0;
    }

    img {
      width: 100vw;
      max-width: 100vw;
    }
  }

  .services {
    padding: 0;

    @media (max-width: 667px) {
      padding-top: 0px;
      padding-bottom: 20px;
    }

    &__item {
      margin-bottom: 150px;
      height: 100%;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 667px) {
        margin-bottom: 0;
        display: block;
        .column {
            margin-bottom: 20px;
        }
      }

      // animation
      opacity: 0;
      transform: translate(0, 100px);
      transition: all 1s ease-in-out;
    }

    &__col {
      height: 100%;
      max-width: 500px;

      @media (max-width: 667px) {
        flex: 0 0 100%;
      }

      h2 {
        font-size: 36px;

        @media (max-width: 667px) {
          font-size: 18px;
        }
      }

      p {
        max-width: 440px;
        line-height: 1.5;
      }
    }

    &__image {
      display: flex;
      align-items: flex-start;
      background: #f7f7f7;
      position: relative;
      min-height: 584px;
      flex: 0 0 48%;
      padding: 30px;

      @media (max-width: 667px) {
        order: -1;
        width: 100%;
        min-height: 350px;
      }
    }

    .cg01 {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      left: 5%;

      @media (max-width: 667px) {
        max-width: 42%;
        top: 220px;
      }
    }

    .cg02 {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      left: 200px;

      @media (max-width: 667px) {
        max-width: 50%;
        left: 170px;
        top: 220px;
      }
    }

    .cg03 {
      position: absolute;
      top: 20%;
      right: 170px;

      @media (max-width: 667px) {
        top: 20px;
        right: 150px;
      }
    }

    .cg04 {
      position: absolute;
      top: 17%;
      right: 10px;

      @media (max-width: 667px) {
        top: 0;
        right: -20px;
      }
    }

    .cg02_main {
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      left: 50%;
      width: 90%;
    }

    .cg03_01 {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      left: 27%;

      @media (max-width: 667px) {
        max-width: 35%;
        left: 15%;
      }
    }

    .cg03_02 {
      position: absolute;
      top: 53%;
      transform: translate(0, -50%);
      left: 40%;
      z-index: 20;

      @media (max-width: 667px) {
        max-width: 60%;
        left: 35%;
      }
    }
  }
}

.referenceView.giganet {
  .hashtags li {
    color: #fff;
    opacity: 0.5;
  }

  .hero--sub h1 {
    max-width: 550px;
  }

  .hero--sub {
    background-image: url("../../images/references/giganet/snail.png"),
      linear-gradient(
        to right,
        rgba(225, 23, 90, 1) 0%,
        rgba(148, 37, 104, 1) 51%,
        rgba(71, 51, 118, 1) 100%
      ) !important;
    background-repeat: no-repeat, no-repeat;
    background-position: 90% bottom, center;
    background-size: 40%, 100%;

    @media (min-width: 2000px) {
        background-position: 80% bottom, center;
        background-size: 30%, 100%;
    }  

    @media (max-width: 1024px) {
        background-position: 100% bottom, center;
    }

    @media (max-width: 667px) {
      background-position: 60% bottom, center;
      background-size: 35%, 100%;
    }
  }

  .block--boxes {
    justify-content: center;
    flex-wrap: wrap;

    .service-box {
      background: #fff2f6;
      margin: 0 15px 30px 15px;
      flex: 0 1 46%;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      padding: 40px 3%;

      @media (max-width: 667px) {
        margin: 0 0 25px 0;
      }
    }
  }

  .block--cover-image {
    background: #cc9370;

    @media (max-width: 667px) {
      padding: 0;
    }
  }

  .block-images {
    background: #f6f6f6;
    width: 100%;
    padding: 150px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 220px;
    position: relative;

    @media (max-width: 1024px) {
      padding: 60px 0;
    }

    @media (max-width: 667px) {
      margin-bottom: 100px;
      padding: 40px 10px 20px 10px;
      margin-bottom: 40px;
    }

    img {
      display: block;
      margin: 0 1%;
      max-width: 100%;
      transition: all 1s ease-in-out;

      //animations
      opacity: 0;
      transform: translate(0, 200px);

      @media (max-width: 667px) {
        margin-bottom: 20px;
      }
    }
  }

  .overlayer {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: #fdf7f4;
    z-index: 1000;
    transition: all 1s linear;
  }

  .block--intro {
    max-width: 850px;
    margin-left: auto;
    margin-right: auto;

    p {
      max-width: 100%;
    }
  }

  .block--screens {
    @media (max-width: 667px) {
      margin-top: 40px;
    }

    img {
      width: 100vw;
      max-width: 100vw;
    }
  }

  .services {
    padding: 0;

    @media (max-width: 667px) {
      padding-top: 20px;
    }

    &__item {
      margin-bottom: 62px;
      height: 100%;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 667px) {
        margin-bottom: 20px;
        display: block;
        flex-wrap: wrap;

        .column {
            padding: 0;
            margin-bottom: 20px;
        }
      }

      // animation
      opacity: 0;
      transform: translate(0, 100px);
      transition: all 1s ease-in-out;
    }

    &__col {
      height: 100%;
      max-width: 500px;

      @media (max-width: 667px) {
        flex: 0 0 100%;
      }

      h2 {
        font-size: 36px;
        max-width: 450px;

        @media (max-width: 667px) {
          font-size: 18px;
        }
      }

      p {
        max-width: 440px;
        line-height: 2;
      }
    }

    &__image {
      display: flex;
      align-items: flex-start;
      background: #f7f7f7;
      position: relative;
      min-height: 584px;

      @media (max-width: 768px) {
        img {
            width: 100%;
        }
      }

      @media (max-width: 667px) {
        order: -1;
        width: 100%;
        min-height: unset;
      }
    }
  }

  .image-pack {
    padding-bottom: 100px;

    @media (max-width: 667px) {
      padding-bottom: 40px;
    }
  }

  .block--image-pack {
    margin-bottom: 82px;

    @media (max-width: 667px) {
      margin-bottom: 25px;
    }
  }

  .block--columns {
    background: none;

    .services__image {
      background: none;
    }
  }

  @media (max-width: 667px) {
    .link-row--topNegative {
      margin: 30px 0;
    }
  }

  .block--columns-site {
    .services__item.columns {
      background: none;
      justify-content: center;

      @media (max-width: 667px) {
        display: block;
      }

      .services__image {
        background: #f6f6f6;
        align-items: flex-end;
        padding-bottom: 0;
        min-height: 691px;

        @media (max-width: 667px) {
          min-height: 0;
          margin-bottom: 20px;
        }

        &:nth-child(1) {
          justify-content: flex-end;
        }
      }
    }
  }
}

.referenceView.nadmerka {
  .hashtags li {
    color: #fff;
    opacity: 0.5;
  }

  .hero--sub h1 {
    max-width: 630px;
  }

  .hero--sub {
    background-image: url("../../images/references/nadmerka/nadmerka-bg.png") !important;
    background-repeat: no-repeat;

    @media (max-width: 667px) {
        background-position: 60% center;
    }
  }

  .block--boxes {
    justify-content: center;

    .service-box {
      background: #eff3f8;
      margin: 0 15px;
      flex: 0 1 48%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 40px 4%;

      @media (max-width: 768px) {
        margin-bottom: 25px;
      }

      @media (max-width: 667px) {
        margin: 0 0 25px 0;
      }
    }
  }

  .block--cover-image {
    background: #eff3f8;
  }

  .block-images {
    background: #f6f6f6;
    width: 100%;
    padding: 150px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 220px;
    position: relative;

    @media (max-width: 1024px) {
      padding: 60px 0;
    }

    @media (max-width: 667px) {
      margin-bottom: 100px;
      padding: 40px 10px 20px 10px;
      margin-bottom: 40px;
    }

    img {
      display: block;
      margin: 0 1%;
      max-width: 100%;
      transition: all 1s ease-in-out;

      //animations
      opacity: 0;
      transform: translate(0, 200px);

      @media (max-width: 667px) {
        margin-bottom: 20px;
      }
    }
  }

  .overlayer {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: #f5cc32;
    z-index: 1000;
    transition: all 1s linear;
  }

  .block--intro {
    max-width: 850px;
    margin-left: auto;
    margin-right: auto;

    p {
      max-width: 100%;
    }
  }

  .block--screens {
    margin-top: 0;

    @media (max-width: 667px) {
      margin-top: 40px;
    }

    img {
      width: 100vw;
      max-width: 100vw;
    }
  }

  .services {
    padding: 0 0 230px 0;

    @media (max-width: 667px) {
      padding-top: 20px;
      padding-bottom: 40px;
    }

    &__item {
      margin-bottom: 150px;
      height: 100%;
      justify-content: space-between;
      align-items: center;

      &:nth-child(1) {
        .services__image {
          background: #fffce5;
        }
      }

      &:nth-child(2) {
        .services__image {
          background: #eff3f8;
        }
      }

      @media (max-width: 667px) {
        margin-bottom: 20px;
        display: block;
        .column {
            margin-bottom: 20px;
        }
      }

      // animation
      opacity: 0;
      transform: translate(0, 100px);
      transition: all 1s ease-in-out;
    }

    &__col {
      height: 100%;
      max-width: 500px;

      @media (max-width: 667px) {
        flex: 0 0 100%;
      }

      h2 {
        font-size: 36px;
        max-width: 450px;

        @media (max-width: 667px) {
          font-size: 18px;
        }
      }

      p {
        max-width: 440px;
        line-height: 2;
      }
    }

    &__image {
      display: flex;
      align-items: flex-end;

      position: relative;
      min-height: 584px;
      overflow: hidden;
      &:nth-child(2) {
        background: #fffce5;
      }
      img {
        margin: 0 auto;
        position: relative;
        top: 20px;
      }

      @media (max-width: 667px) {
        order: -1;
        width: 100%;
        min-height: unset;

        img {
            top: 0;
        }
      }
    }

    .shoes-list {
      position: absolute;
      left: 59%;
      top: 20%;
      @media (max-width: 1024px) {
        left: 52%;
        top: 30%;
      }
      @media (max-width: 667px) {
        top: 10%;
        left: 50%;
        max-width: 50%;
      }
    }
  }

  .image-pack {
    padding-bottom: 100px;

    @media (max-width: 667px) {
      padding-bottom: 40px;
    }
  }

  .block--image-pack {
    margin-bottom: 82px;

    @media (max-width: 667px) {
      margin-bottom: 25px;
    }
  }

  .block--previews {
    background: #f6f6f6;
    padding: 150px 0 200px 0;

    @media (max-width: 667px) {
      padding: 60px 0;
    }

    .previews-screens {
      padding: 0;
    }

    .screen-image {
      padding: 0 13px;
    }

    .block-image {
      margin-bottom: 40px;
    }
  }
}

.referenceView.medirex {
  .hashtags li {
    color: #fff;
    opacity: 0.5;
  }

  .hero--sub h1 {
    max-width: 630px;
  }

  .hero--sub {
    background-image: url("../../images/references/medirex/hero-bg.png") !important;
    background-repeat: no-repeat;
  }

  .block--boxes {
    justify-content: center;

    .service-box {
      background: #f7f7fa;
      margin: 0 15px;
      flex: 0 1 48%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 40px 4%;

      @media (max-width: 768px) {
        margin-bottom: 25px;
      }

      p {
        line-height: 1.85;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      @media (max-width: 667px) {
        margin:0 0 25px 0;
      }
    }
  }

  .block--cover-image {
    background: #0d88e1;

    #video {
      display: block;
      margin-bottom: -5px;
      margin: 0 auto;
    }

    @media (max-width: 667px) {
      #video {
        transform: scale(1.6);
      }
    }
  }

  .block-images {
    background: #f6f6f6;
    width: 100%;
    padding: 150px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 220px;
    position: relative;

    @media (max-width: 1024px) {
      padding: 60px 0;
    }

    @media (max-width: 667px) {
      margin-bottom: 100px;
      padding: 40px 10px 20px 10px;
      margin-bottom: 40px;
    }

    img {
      display: block;
      margin: 0 1%;
      max-width: 100%;
      transition: all 1s ease-in-out;

      //animations
      opacity: 0;
      transform: translate(0, 200px);

      @media (max-width: 667px) {
        margin-bottom: 20px;
      }
    }
  }

  .overlayer {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: #0c3672;
    z-index: 1000;
    transition: all 1s linear;
  }

  .block--intro {
    max-width: 850px;
    margin-left: auto;
    margin-right: auto;

    p {
      max-width: 100%;
    }
  }

  .block--screens {
    margin-top: 0;

    @media (max-width: 667px) {
      margin-top: 40px;
    }

    img {
      width: 100vw;
      max-width: 100vw;
    }
  }

  .services {
    padding: 0;

    @media (max-width: 667px) {
      padding-top: 20px;
    }

    &__item {
      margin-bottom: 150px;
      height: 100%;
      justify-content: space-between;
      align-items: center;

      &:nth-child(1) {
        .services__image {
          background: #eff3f8;
        }
      }

      &:nth-child(2) {
        .services__image {
          background: #fafafa;
        }
      }

      @media (max-width: 667px) {
        margin-bottom: 20px;
        display: block;
        .column {
            margin-bottom: 20px;
        }
      }

      // animation
      opacity: 0;
      transform: translate(0, 100px);
      transition: all 1s ease-in-out;
    }

    &__col {
      height: 100%;
      max-width: 500px;

      @media (max-width: 667px) {
        flex: 0 0 100%;
      }

      h2 {
        font-size: 36px;
        max-width: 450px;

        @media (max-width: 667px) {
          font-size: 18px;
        }
      }

      p {
        max-width: 440px;
        line-height: 2;
      }
    }

    &__image {
      display: flex;
      align-items: flex-end;
      background: #fffce5;
      position: relative;
      min-height: 584px;
      overflow: hidden;
      flex: 0 0 49%;

      img {
        margin: 0 auto;
        position: relative;
        top: 20px;
      }

      @media (max-width: 667px) {
        order: -1;
        width: 100%;
      }
    }

    .cg01 {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      left: 3%;
    }

    .cg02 {
      position: absolute;
      top: 27%;
      left: 24%;
    }

    .cg03 {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      left: 53%;
    }

    .cg04 {
      position: absolute;
      top: 68%;
      left: 7%;
    }

    .cg05 {
      position: absolute;
      top: 70%;
      left: 54%;
      width: 9px;
    }

    .cg06 {
      position: absolute;
      top: 67%;
      left: 48%;
    }

    .cg07 {
      position: absolute;
      top: 67%;
      left: 53%;
    }

    .shoes-list {
      position: absolute;
      left: 59%;
      top: 20%;
    }
  }

  .image-pack {
    padding-bottom: 100px;

    @media (max-width: 667px) {
      padding-bottom: 40px;
    }
  }

  .block--image-pack {
    margin-bottom: 82px;

    @media (max-width: 667px) {
      margin-bottom: 25px;
    }
  }

  .block--previews {
    background: #f6f6f6;
    padding: 150px 0 150px 0;

    @media (max-width: 667px) {
      padding: 60px 0;
    }

    .previews-screens {
      padding: 0;
    }

    .screen-image {
      padding: 0 13px;
    }

    .block-image {
      margin-bottom: 40px;
    }
  }
}

.referenceView.drtheiss {
  .hashtags li {
    color: #fff;
    opacity: 0.5;
  }

  .hero--sub h1 {
    max-width: 630px;
  }

  .hero--sub {
    background-image: url("../../images/references/drtheiss/hero-bg.png") !important;
    background-repeat: no-repeat;
    background-size: 774px auto;
    background-position: 90% center;
    background-color: #ff7f14;

    @media (max-width: 1024px) {
        background-size: 474px auto;
    }

    @media (max-width: 768px) {
        background-size: 400px auto;
        background-position: right bottom;
    }

    @media (max-width: 667px) {
        background-size: 220px auto;
        background-position:center 280px;
    }
  }

  .partners {
    padding: 0 0 52px 0;
    margin-bottom: 80px;
    border-bottom: 1px solid #f6f6f6;
    display: flex;
    justify-content: space-between;

    @media (max-width: 667px) {
        padding-bottom: 30px;
        margin-bottom: 30px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
    }
  }

  .link-row {
    a {
      color: #ef7d00;
    }

    i {
      background: url("../../images/references/drtheiss/arrow-next.svg")
        no-repeat;
    }
  }

  .link-row--topNegative {
    @media (max-width: 667px) {
        margin-top: 0;
    }
  }

  .block--boxes {
    justify-content: center;

    @media (max-width: 667px) {
        margin-bottom: 30px;
    }

    .service-box {
      background: rgba(#ef7d00, 0.07);
      margin: 0 15px;
      flex: 0 1 35%;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      padding: 40px 4%;

      
      @media (max-width: 768px) {
        margin-bottom: 25px;
      }

      p {
        line-height: 1.85;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      @media (max-width: 667px) {
        margin: 0 0 25px 0;
      }
    }
  }

  .block--cover-image {
    background: #ff7f14;

    #video {
      display: block;
      margin-bottom: -5px;
      margin: 0 auto;
    }

    @media (max-width: 667px) {
      #video {
        transform: scale(1.6);
      }
    }
  }

  .block-images {
    background: #f6f6f6;
    width: 100%;
    padding: 150px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 220px;
    position: relative;

    @media (max-width: 1024px) {
      padding: 60px 0;
    }

    @media (max-width: 667px) {
      margin-bottom: 100px;
      padding: 40px 10px 20px 10px;
      margin-bottom: 40px;
    }

    img {
      display: block;
      margin: 0 1%;
      max-width: 100%;
      transition: all 1s ease-in-out;

      //animations
      opacity: 0;
      transform: translate(0, 200px);

      @media (max-width: 667px) {
        margin-bottom: 20px;
      }
    }
  }

  .overlayer {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: #ff7f14;
    z-index: 1000;
    transition: all 1s linear;
  }

  .block--intro {
    max-width: 850px;
    margin-left: auto;
    margin-right: auto;

    p {
      max-width: 100%;
    }
  }

  .block--screens {
    margin-top: 0;

    @media (max-width: 667px) {
      margin-top: 40px;
    }

    img {
      width: 100vw;
      max-width: 100vw;
    }
  }

  .services {
    padding: 0;

    @media (max-width: 667px) {
      padding-top: 20px;
    }

    &__item {
      margin-bottom: 150px;
      height: 100%;
      justify-content: space-between;
      align-items: center;

      &:nth-child(1) {
        .services__image {
          background: #fef6ed;
        }
      }

      &:nth-child(2) {
        .services__image {
          background: #fef6ed;
        }
      }

      @media (max-width: 667px) {
        margin-bottom: 20px;
        display: block;
        flex-wrap: wrap;

        .services__image {
            margin-bottom: 20px;
        }
      }

      // animation
      opacity: 0;
      transform: translate(0, 100px);
      transition: all 1s ease-in-out;
    }

    &__col {
      height: 100%;
      max-width: 500px;

      @media (max-width: 667px) {
        flex: 0 0 100%;
      }

      h2 {
        font-size: 36px;
        max-width: 450px;

        @media (max-width: 667px) {
          font-size: 18px;
        }
      }

      p {
        max-width: 440px;
        line-height: 2;
      }
    }

    &__image {
      display: flex;
      align-items: flex-end;
      background: #fffce5;
      position: relative;
      min-height: 584px;
      overflow: hidden;
      flex: 0 0 49%;

      img {
        margin: 0 auto;
        position: relative;
        top: 20px;
      }

      &.last {
        align-items: center;
      }

      @media (max-width: 667px) {
        order: -1;
        width: 100%;
        min-height: unset;
      }
    }
  }

  .image-pack {
    padding-bottom: 100px;

    @media (max-width: 667px) {
      padding-bottom: 40px;
    }
  }

  .block--image-pack {
    margin-bottom: 82px;

    @media (max-width: 667px) {
      margin-bottom: 25px;
    }
  }

  .block--previews {
    background: #f6f6f6;
    padding: 150px 0 150px 0;

    @media (max-width: 667px) {
      padding: 60px 0;
    }

    .previews-screens {
      padding: 0;
    }

    .screen-image {
      padding: 0 13px;
    }

    .block-image {
      margin-bottom: 40px;
    }
  }
}

.referenceView.orava {
  .hashtags li {
    color: #fff;
    opacity: 0.5;
  }

  .hero--sub h1 {
    max-width: 630px;
  }

  .hero--sub {
    background-image: url("../../images/references/orava/hero-bg.png") !important;
    background-repeat: no-repeat;
    background-size: 1025px auto;
    background-position: 90% center;
    background-color: #735636;

    @media (max-width: 1024px) {
        background-size: 725px auto;
    }
  }

  .link-row {
    a {
      color: #735636;
    }

    i {
      background: url("../../images/references/orava/arrow-next.svg") no-repeat;
    }
  }

  .block--boxes {
    justify-content: center;

    .service-box {
      background: #f5e6d5;
      margin: 0 15px;
      flex: 0 1 35%;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      padding: 40px 4%;

      @media (max-width: 768px) {
        margin-bottom: 25px;
      }

      p {
        line-height: 1.85;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      @media (max-width: 667px) {
        margin:0 0 25px 0;
      }
    }
  }

  .block--cover-image {
    background: #bc7f23;

    #video {
      display: block;
      margin-bottom: -5px;
      margin: 0 auto;
    }

    @media (max-width: 667px) {
      #video {
        transform: scale(1.6);
      }
    }
  }

  .block-images {
    background: #f6f6f6;
    width: 100%;
    padding: 150px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 220px;
    position: relative;

    @media (max-width: 1024px) {
      padding: 60px 0;
    }

    @media (max-width: 667px) {
      margin-bottom: 100px;
      padding: 40px 10px 20px 10px;
      margin-bottom: 40px;
    }

    img {
      display: block;
      margin: 0;
      max-width: 100%;
      transition: all 1s ease-in-out;

      //animations
      opacity: 0;
      transform: translate(0, 200px);

      @media (max-width: 667px) {
        margin-bottom: 20px;
      }
    }
  }

  .overlayer {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: #ff7f14;
    z-index: 1000;
    transition: all 1s linear;
  }

  .block--intro {
    max-width: 850px;
    margin-left: auto;
    margin-right: auto;

    p {
      max-width: 100%;
    }
  }

  .block--screens {
    margin-top: 0;

    @media (max-width: 667px) {
      margin-top: 40px;
    }

    img {
      width: 100vw;
      max-width: 100vw;
    }
  }

  .services {
    padding: 0;

    @media (max-width: 667px) {
      padding-top: 20px;
    }

    &__item {
      margin-bottom: 150px;
      height: 100%;
      justify-content: space-between;
      align-items: center;

      &:nth-child(1) {
        .services__image {
          background: #fef6ed;

          &.last {
            @media (max-width: 667px) {
                img {
                    max-width: 50%;
                }
              }
          }
        }
      }

      &:nth-child(2) {
        .services__image {
          background: #fef6ed;
        }
      }

      @media (max-width: 667px) {
        margin-bottom: 20px;
        display: block;

        .column {
            margin-bottom: 20px;
        }
      }

      // animation
      opacity: 0;
      transform: translate(0, 100px);
      transition: all 1s ease-in-out;
    }

    &__col {
      height: 100%;
      max-width: 500px;

      @media (max-width: 667px) {
        flex: 0 0 100%;
      }

      h2 {
        font-size: 36px;
        max-width: 450px;

        @media (max-width: 667px) {
          font-size: 18px;
        }
      }

      p {
        max-width: 440px;
        line-height: 2;
      }
    }

    &__image {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      background: #fffce5;
      position: relative;
      min-height: 584px;
      overflow: hidden;
      flex: 0 0 49%;

      img {
        position: relative;
        top: 20px;
      }

      &.last {
        align-items: center;
      }

      .cg03 {
        margin-left: -3px;
        top: 11px;
      }

      @media (max-width: 667px) {
        order: -1;
        width: 100%;
        min-height: unset;
      }
    }
  }

  .image-pack {
    padding-bottom: 100px;

    @media (max-width: 667px) {
      padding-bottom: 40px;
    }
  }

  .block--image-pack {
    margin-bottom: 82px;

    @media (max-width: 667px) {
      margin-bottom: 25px;
    }
  }

  .block--previews {
    background: #f5e6d5;
    padding: 150px 0 150px 0;

    @media (max-width: 667px) {
      padding: 60px 0;
    }

    .previews-screens {
      padding: 0;
    }

    .screen-image {
      padding: 0 13px;
    }

    .block-image {
      margin-bottom: 40px;
    }
  }
}

.referenceView.mirano {
  .hashtags li {
    color: #fff;
    opacity: 0.5;
  }

  .hero--sub h1 {
    max-width: 630px;
  }

  .hero--sub {
    background-image: url("../../images/references/mirano/hero-bg.png") !important;
    background-repeat: no-repeat;
    background-size: 1025px auto;
    background-position: 95% bottom;
    background-color: #7fae06;

    @media (max-width: 1024px) {
        background-size: 525px auto;
    }

    @media (max-width: 667px) {
        background-size: 225px auto;
        background-position: 25% bottom;
    }
  }

  .link-row {
    a {
      color: #7fae06;
    }

    i {
      background: url("../../images/references/mirano/arrow-next.svg") no-repeat;
    }
  }

  .block--boxes {
    justify-content: center;

    .service-box {
      background: rgba(#7fae06, 0.07);
      margin: 0 15px;
      flex: 0 1 35%;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      padding: 40px 4%;
      
      @media (max-width: 768px) {
        margin-bottom: 25px;
     }

      p {
        line-height: 1.85;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      @media (max-width: 667px) {
        margin:0 0 25px 0;
      }
    }
  }

  .block--cover-image {
    background: #7fae06;
    margin-bottom: 82px;

    @media (max-width: 667px) {
        margin-bottom: 35px;
    }

    #video {
      display: block;
      margin-bottom: -5px;
      margin: 0 auto;
      width: 100%;
    }

    @media (max-width: 667px) {
      #video {
        transform: scale(1.2);
      }
    }
  }

  .block-images {
    background: #f6f6f6;
    width: 100%;
    padding: 150px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 220px;
    position: relative;

    @media (max-width: 1024px) {
      padding: 60px 0;
    }

    @media (max-width: 667px) {
      margin-bottom: 100px;
      padding: 40px 10px 20px 10px;
      margin-bottom: 40px;
    }

    img {
      display: block;
      margin: 0;
      max-width: 100%;
      transition: all 1s ease-in-out;

      //animations
      opacity: 0;
      transform: translate(0, 200px);

      @media (max-width: 667px) {
        margin-bottom: 20px;
      }
    }
  }

  .overlayer {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: #7fae06;
    z-index: 1000;
    transition: all 1s linear;
  }

  .block--intro {
    max-width: 850px;
    margin-left: auto;
    margin-right: auto;

    p {
      max-width: 100%;
    }
  }

  .block--screens {
    margin-top: 0;

    @media (max-width: 667px) {
      margin-top: 40px;
    }

    img {
      width: 100vw;
      max-width: 100vw;
    }
  }

  .services {
    padding: 0;

    @media (max-width: 667px) {
      padding-top: 20px;
    }

    &__item {
      margin-bottom: 150px;
      height: 100%;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 667px) {
        margin-bottom: 20px;
        display: block;
        flex-wrap: wrap;

        .column {
            margin-bottom: 20px;
        }
      }

      // animation
      opacity: 0;
      transform: translate(0, 100px);
      transition: all 1s ease-in-out;
    }

    &__col {
      height: 100%;
      max-width: 500px;

      @media (max-width: 667px) {
        flex: 0 0 100%;
      }

      h2 {
        font-size: 36px;
        max-width: 450px;

        @media (max-width: 667px) {
          font-size: 18px;
        }
      }

      p {
        max-width: 440px;
        line-height: 2;
      }
    }

    &__image {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      background: #f6f9ed;
      position: relative;
      min-height: 584px;
      overflow: hidden;
      flex: 0 0 49%;

      img {
        position: relative;
        top: 20px;
      }

      &.last {
        align-items: center;
      }

      .cg03 {
        margin-left: -3px;
        top: 11px;
      }

      @media (max-width: 667px) {
        order: -1;
        width: 100%;
        min-height: unset;
      }
    }
  }

  .image-pack {
    padding-bottom: 100px;

    @media (max-width: 667px) {
      padding-bottom: 40px;
    }
  }

  .block--image-pack {
    margin-bottom: 82px;

    @media (max-width: 667px) {
      margin-bottom: 25px;
    }
  }

  .block--previews {
    background: #fff;
    padding: 150px 0 150px 0;

    @media (max-width: 667px) {
      padding: 60px 0;
    }

    .previews-screens {
      padding: 0;
    }

    .screen-image {
      padding: 0 13px;
    }

    .block-image {
      margin-bottom: 40px;
    }
  }

  .block--catalog {
    .container-block {
      max-width: 100%;
      padding: 0;
    }

    img {
      width: 100%;
    }
  }

  .block--decors {
    .block-image {
      width: 100%;

      &:nth-child(1) {
        margin-bottom: 10px;
      }
    }
  }
}

.referenceView.exsotrans {
  .hashtags li {
    color: #fff;
    opacity: 0.5;
  }

  .hero--sub h1 {
    max-width: 560px;
  }

  .hero--sub {
    background-image: url("../../images/references/exsotrans/hero-bg.png") !important;
    background-repeat: no-repeat;
    background-size: 1025px auto;
    background-position: 100% center;
    background-color: #aa2828;

    @media (max-width: 1024px) {
        background-size: 525px auto;
    }

    @media (max-width: 768px) {
        background-position: 150% center;
    }

    @media (max-width: 667px) {
        background-position:70% 110%;
        background-size: 250px auto;
    }
  }

  .link-row {
    a {
      color: #aa2828;
    }

    i {
      background: url("../../images/references/exsotrans/arrow-next.svg")
        no-repeat;
    }
  }

  .block--boxes {
    justify-content: center;

    .service-box {
      background: #fff4f4;
      margin: 0 15px;
      flex: 0 1 45%;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      padding: 40px 4%;

      @media (max-width: 768px) {
        margin-bottom: 25px;
      }

      p {
        line-height: 1.85;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      @media (max-width: 667px) {
        margin: 0 0 25px 0;
      }
    }
  }

  .block--cover-image {
    background: #aa2828;

    #video {
      display: block;
      margin-bottom: -5px;
      margin: 0 auto;
    }

    @media (max-width: 667px) {
      #video {
        transform: scale(1.6);
      }
    }
  }

  .block-images {
    background: #f6f6f6;
    width: 100%;
    padding: 150px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 220px;
    position: relative;

    @media (max-width: 1024px) {
      padding: 60px 0;
    }

    @media (max-width: 667px) {
      margin-bottom: 100px;
      padding: 40px 10px 20px 10px;
      margin-bottom: 40px;
    }

    img {
      display: block;
      margin: 0;
      max-width: 100%;
      transition: all 1s ease-in-out;

      //animations
      opacity: 0;
      transform: translate(0, 200px);

      @media (max-width: 667px) {
        margin-bottom: 20px;
      }
    }
  }

  .overlayer {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: #aa2828;
    z-index: 1000;
    transition: all 1s linear;
  }

  .block--intro {
    max-width: 850px;
    margin-left: auto;
    margin-right: auto;

    p {
      max-width: 100%;
    }
  }

  .block--screens {
    margin-top: 0;

    @media (max-width: 667px) {
      margin-top: 40px;
    }

    img {
      width: 100vw;
      max-width: 100vw;
    }
  }

  .block--cover-video {
    width: 102%;

    video {
      width: 100%;
    }
  }

  .services {
    padding: 0;

    @media (max-width: 667px) {
      padding-top: 20px;
    }

    &__item {
      margin-bottom: 150px;
      height: 100%;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 667px) {
        margin-bottom: 20px;
        display: block;

        .column {
            margin-bottom: 20px;
        }
      }

      // animation
      opacity: 0;
      transform: translate(0, 100px);
      transition: all 1s ease-in-out;
    }

    &__col {
      height: 100%;
      max-width: 500px;

      @media (max-width: 667px) {
        flex: 0 0 100%;
      }

      h2 {
        font-size: 36px;
        max-width: 450px;

        @media (max-width: 667px) {
          font-size: 18px;
        }
      }

      p {
        max-width: 440px;
        line-height: 2;
      }
    }

    &__image {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fffce5;
      position: relative;
      min-height: 584px;
      overflow: hidden;
      flex: 0 0 49%;

      &:nth-child(1) {
        justify-content: flex-end;
        background: #f1f1f3;
        padding-right: 0;
      }

      &:nth-child(2) {
        background: #fff4f4;
      }

      img {
        position: relative;
        top: 20px;
      }

      &.last {
        align-items: center;
      }

      .cg03 {
        margin-left: -3px;
        top: 11px;
      }

      @media (max-width: 667px) {
        order: -1;
        width: 100%;
        min-height: unset;
      }
    }
  }

  .image-pack {
    padding-bottom: 100px;

    @media (max-width: 667px) {
      padding-bottom: 40px;
    }
  }

  .block--image-pack {
    margin-bottom: 82px;

    @media (max-width: 667px) {
      margin-bottom: 25px;
    }
  }

  .block--previews {
    background: #f6f6f6;
    padding: 150px 0 150px 0;
    margin-top: -20px;

    @media (max-width: 667px) {
      padding: 60px 0;
    }

    .previews-screens {
      padding: 0;
    }

    .screen-image {
      padding: 0 13px;
    }

    .block-image {
      margin-bottom: 40px;
    }
  }
}

/* Reference view */
.reference {
  position: relative;

  header {
    .AU_animation1 {
      transform: translateX(-650px);
    }
    .AU_animation2 {
      transition: all 2s ease-in-out;
      opacity: 0;
    }
    position: relative;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 82px 0 0 0;
      width: 1700px;
      margin: 0 auto;
      position: relative;
      z-index: 100;
      .text,
      a {
        transition: 0.5s ease-in-out all;

        @media (max-width: 667px) {
          img {
            max-width: 125px;
          }
        }
      }
      @media (max-width: 1700px) {
        width: 95%;
      }
      @media (max-width: 1300px) {
        width: 95%;
        padding-top: 40px;
      }
      @media (max-width: 900px) {
        width: 90%;
        padding: 30px 0 30px 0;
      }
      @media (max-width: 768px) {
        .line_one {
          width: 450px;
        }
      }
      @media (max-width: 720px) {
        width: 90%;
      }
      @media (max-width: 667px) {
        width: 100%;
      }
      @media (max-width: 500px) {
        display: block;
      }
      .left {
        color: #fff;
        font-weight: bold;
        height: 50px;
        cursor: pointer;
        @media (max-width: 992px) {
          padding-left: 15px;
        }
        span {
          font-weight: 200;
          display: block;
        }
      }
      .right {
        color: #fff;
        font-weight: bold;
        cursor: pointer;
        @media (max-width: 500px) {
          margin-top: 20px;
        }
      }
    }
    .header-container {
      width: 1240px;
      margin: 90px auto 0 auto;
      @media (max-width: 1450px) {
        width: 1000px;
      }
      @media (max-width: 1240px) {
        width: 85%;
      }
      @media (max-width: 1050px) {
        margin-top: 50px;
      }
      @media (max-width: 900px) {
        width: 90%;
      }

      @media (max-width: 950px) {
        & {
          margin: 0 auto;
          text-align: center;
          width: 95%;
          padding-left: 0px;
        }
      }
      .service {
        color: $headline;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        font: {
          style: normal;
          weight: 600;
          size: 14px;
        }
        line-height: 18px;
      }
      .text {
        width: 638px;
        @media (max-width: 900px) {
          width: 90%;
          margin: 15px auto 0 auto;
        }
        h1 {
          margin: {
            top: 15px;
          }
          color: #fff;
          font: {
            style: normal;
            weight: 600;
            size: 52px;
          }
          line-height: 59px;
        }
      }
      @media (max-width: 1440px) {
        .text h1 {
          font: {
            size: 32px;
          }
          line-height: 39px;
        }
      }
      @media (max-width: 950px) {
      }
    }
    .header-container_2 {
      margin: {
        top: 92px;
        left: auto;
        right: auto;
      }
      width: 1240px;
      @media (max-width: 1450px) {
        width: 1000px;
      }
      @media (max-width: 1240px) {
        width: 85%;
      }
      @media (max-width: 900px) {
        width: 90%;
      }
      .grid {
        display: flex;
        @media (max-width: 720px) {
          display: block;
        }
        .header_box {
          width: 390px;
          margin-right: 250px;
          margin-bottom: 49px;
          @media (max-width: 1050px) {
            margin-right: 80px;
          }
          @media (max-width: 720px) {
            margin: 60px auto 20px auto;
            width: 90%;
          }
          h3 {
            margin: {
              bottom: 25px;
            }
            width: 275px;
            color: #fff;
            font: {
              style: normal;
              weight: 600;
              size: 30px;
            }
            line-height: 38px;
            @media (max-width: 720px) {
              margin-bottom: 15px;
              width: 100%;
            }
          }
          p {
            @media (max-width: 900px) {
              width: 95%;
              margin: 0 auto;
            }
            color: #d1d5db;
            font: {
              style: normal;
              weight: 400;
              size: 16px;
            }
            line-height: 29px;
          }
        }
      }
      @media (max-width: 720px) {
        text-align: center;
      }
    }
    svg.header_line {
      position: absolute;
      top: 350px;
      right: 0;
      z-index: 1;
      .line-path {
        stroke-dasharray: 2500px;
        stroke-dashoffset: 2500px;
      }
      @media (max-width: 1100px) {
        width: 350px;
        right: -150px;
        top: 150px;
      }
      @media (max-width: 667px) {
        right: -120px;
        width: 230px;
        top: 50px;
      }
    }
    svg.header_line--services {
      right: -250px;
      bottom: -750px;
      transform: rotate(-35deg);
    }
  } /* ./header */

  .navigation-bar {
    right: 0;
  }

  .hero--reference {
    text-align: center;
    padding-top: 30px;

    @media (max-width: 667px) {
      padding-top: 15px;
    }

    .hero__toptitle {
      color: #e5375d;
      text-transform: uppercase;
      margin-bottom: 15px;
      font-weight: 600;
      letter-spacing: 0.2em;
      font-size: 14px;
      transition: all 1s linear;

      opacity: 0;
      transform: translate(0, 50px);

      @media (max-width: 667px) {
        font-size: 12px;
      }
    }

    .hero__title {
      color: #fff;
      margin-bottom: 65px;
      margin-top: 0;
      font-weight: 500;
      font-size: 52px;
      transition: all 1s linear;

      opacity: 0;
      transform: translate(0, 50px);

      @media (max-width: 667px) {
        font-size: 28px;
        margin-bottom: 35px;
      }
    }
  }

  .content {
    background: #181A1A;
    padding: 70px 0 0 0;
    margin-bottom: 0;

    @media (max-width: 667px) {
      padding: 50px 0 0 0;
    }
  } /* content */

  .kontakt {
    margin-top: 60px;

    .AU_animation12 {
      transform: translateX(-580px);
    }
    .AU_animation13 {
      transform: translateX(800px);
    }
    width: 1240px;
    height: 386px;
    margin: 0 auto;
    background: #f3385a;
    border-radius: 0;
    margin-bottom: 127px;
    display: flex;
    @media (max-width: 1440px) {
      width: 85%;
    }
    @media (max-width: 900px) {
      width: 80%;
      height: auto;
      display: grid;
    }
    @media (max-width: 667px) {
      width: 90%;
      margin-bottom: 61px;
    }
    .left {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 0;
      @media (max-width: 1100px) {
        width: 45%;
      }
      @media (max-width: 900px) {
        width: 100%;
        text-align: center;
        margin-top: 30px;
      }
      @media (max-width: 330px) {
        width: 280px;
      }
      div {
        width: 100%;
        padding: 0 50px 0 100px;

        @media (max-width: 768px) {
            padding: 0 50px;
        }
        @media (max-width: 667px) {
          padding: 0;
        }

        h2 {
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 23px;
          letter-spacing: 0.2em;
          text-transform: uppercase;
          color: #000;
          @media (max-width: 1024px){
            font-size: 24px;
        }
          @media (max-width: 667px) {
            font-size: 12px;
          }
        }
        h3 {
          margin-top: 20px;
          width: 100%;
          font-style: normal;
          font-weight: 700;
          font-size: 36px;
          line-height: 40px;
          color: #fff;
          @media (max-width: 1024px){
            font-size: 24px;
        }
          @media (max-width: 500px) {
            font-size: 32px;
            line-height: 35px;
          }
          @media (max-width: 667px) {
            font-size: 18px;
            font-weight: 400;
            line-height: 1.25;
            margin-top: 0;
          }
        }
        p {
          margin-top: 35px;
          color: #fff;
          font-size: 36px;
          line-height: 1.1;
          @media (max-width: 1024px){
            font-size: 24px;
            margin-top: 20px;
        }
          @media (max-width: 667px) {
            font-size: 18px;
            font-weight: 400;
            line-height: 1.25;
            margin-top: 25px;
          }
        }
      }
    }
    .right {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 1100px) {
        width: 55%;
      }
      @media (max-width: 900px) {
        width: 100%;
        padding: 20px 0 20px 0;
      }
      @media (max-width: 667px) {
        width: 100%;
        padding: 15px 15px 50px 15px;
      }
      form {
        width: 80%;
        @media (max-width: 1100px) {
          width: 90%;
        }
        @media (max-width: 900px) {
          width: 346px;
        }
        @media (max-width: 500px) {
          width: 300px;
        }
        @media (max-width: 330px) {
          width: 100%;
        }
        input,
        textarea {
          width: 344px;
          background: #ffffff;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
          border-radius: 3px;
          padding: 9px 13px;
          margin-bottom: 14px;
          border: none;
          outline: none;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #6b7280;
          @media (max-width: 500px) {
            width: 100%;
          }
        }
        input {
          height: 50px;
        }
        textarea {
          height: 132px;
        }
        .checkbox {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          input {
            width: 21px;
            height: 21px;
            background: #ffffff;
            border-radius: 3px;
            border: none;
            box-shadow: none;
            margin-bottom: 0;
            margin-right: 10px;
          }
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #ffffff;
          a {
            margin-left: 5px;
            color: #ffffff;
            text-decoration: underline;
          }
          @media (max-width: 800px) {
            display: block;
          }
        }
        a.Mybtn {
          rect {
            stroke-dasharray: 669px;
            stroke-dashoffset: 669px;
          }
          position: relative;
          display: block;
          overflow: hidden;
          @include size(100%, 50px);
          max-width: 129px;
          border-radius: 30px;
          span {
            @include absolute();
            display: block;
            @include size(0);
            border-radius: 50%;
            background-color: #fff;
            transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
            transform: translate(-50%, -50%);
          }

          &:hover {
            span {
              @include size(225%, 298px * 2.25);
            }
            .text {
              color: #f3385a;
            }
          }
          .text {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 34px;
            color: transparent;
            animation-duration: 2s;
            animation-fill-mode: forwards;
            transition: 2s color;
          }
        }
        @media (max-width: 950px) {
          a.Mybtn {
            margin: 0 auto;
            .text {
              font-size: 14px;
            }
            &:hover {
              span {
                @include size(0%, 129px * 2.25);
              }
              .text {
                color: #fff;
              }
            }
          }
        }
      }
    }
  } /* ./kontakt */

  footer {
    padding-bottom: 50px;
    .info {
        background: linear-gradient(to right, #f1133f 0%, #ffa5b7 100%);
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      margin-bottom: 81px;
      height: 150px;
      img {
        width: 100%;
        opacity: 0.19;
      }
      @media (max-width: 950px) {
        margin-bottom: 10px;
      }
      .columns {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        @media (max-width: 950px) {
          text-align: center;
          .is-2 {
            padding: 0;
          }
          .is-7 {
            height: 100%;
            margin: 0 auto;
          }
        }
        .all {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          @media (max-width: 950px) {
            .animation_13 {
              display: grid;
            }
          }
          a {
            font-style: normal;
            font-weight: 300;
            font-size: 56px;
            line-height: 59px;
            color: white;
            opacity: 0.5;
            transition: all 0.5s ease-in-out;
            &:hover {
              opacity: 0.9;
            }
            @media (max-width: 1800px) {
              font-size: 45px;
            }
            @media (max-width: 1280px) {
              font-size: 39px;
            }
            @media (max-width: 1050px) {
              font-size: 32px;
            }
            @media (max-width: 950px) {
              font-weight: 100;
            }
            @media (max-width: 667px) {
              font-weight: 400;
            }
          }
          a.email {
            margin-right: 60px;
            @media (max-width: 1280px) {
              margin-right: 25px;
            }
            @media (max-width: 950px) {
              margin-right: 0px;
            }
          }
        }
      }
      .black_wall {
        background-color: #000807;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
        margin: auto;
        height: 100%;
        transform: translate(0%);
        transition: all 1s ease-in-out;
      }
      .black_wall:after {
        content: "";
        position: absolute;
        left: -179px;
        border-right: 180px solid #000807;
        border-top: 192px solid transparent;
      }
    }
    .end {
      opacity: 0;
      transition: all 0.5s ease-in-out;
      @media (max-width: 950px) {
        text-align: center;
      }
      .center_up {
        color: #9ca3af;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
      }
      .center_down {
        margin-top: 11px;
        color: #9ca3af;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
      }
      .social {
        display: flex;
        align-items: center;
        height: 20px;
        margin-top: 27px;
        a {
          position: relative;
          height: 100%;
          margin-right: 8px;
          //border-image-slice: 1;
          //border-bottom: 1px solid;
          //border-width: 1px;
          //border-image-source: linear-gradient(277.48deg, $bg-color 8.64%, $bg-color 56.22%, $bg-color 97.03%);
          transition: all 0.25s ease-in-out;
          &:before {
            content: "";
            width: 0;
            height: 3px;
            position: absolute;
            bottom: -10px;
            left: 0;
            background: linear-gradient(
              277.48deg,
              #0fa6e1 8.64%,
              #f3385a 56.22%,
              #45187a 97.03%
            );
            transition: all 0.35s ease-in-out;
          }
          &:hover {
            transform: translate(0, -5px);
            &:before {
              width: 100%;
            }
          }
        }
        @media (max-width: 950px) {
          justify-content: center;
          margin-top: 10px;
        }
      }
      .links {
        display: grid;
        float: right;
        a {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 5px;
          color: #ffffff;
          border-image-slice: 1;
          border-bottom: 2px solid;
          border-width: 2px;
          border-image-source: linear-gradient(
            277.48deg,
            $bg-color 8.64%,
            $bg-color 56.22%,
            $bg-color 97.03%
          );
          &:hover {
            transform: translateY(0px);
            transition: all 0.2s ease-in-out;
            border-image-source: linear-gradient(
              277.48deg,
              #0fa6e1 8.64%,
              #f3385a 56.22%,
              #45187a 97.03%
            );
            @media (max-width: 950px) {
              transform: translateY(0px);
              border-image-source: linear-gradient(
                277.48deg,
                $bg-color 8.64%,
                $bg-color 56.22%,
                $bg-color 97.03%
              );
            }
          }
        }
        @media (max-width: 950px) {
          float: none;
        }
      }
      .copy {
        margin-top: 60px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
      }
    }
  } /* ./footer */

  .back-link {
    color: #fff;
    font-size: 14px;
    display: flex;
    align-items: center;
    font-weight: 500;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
    margin-bottom: 78px;
    letter-spacing: 0.2em;

    // animations
    opacity: 0;

    @media (max-width: 667px) {
      margin-bottom: 15px;
      font-size: 10px;
      justify-content: center;
    }

    svg {
      margin-right: 20px;
      position: relative;
      top: -1px;
      path {
        transition: all 0.35s ease-in-out;
      }

      @media (max-width: 667px) {
        margin-right: 8px;
      }
    }

    &:hover {
      color: #f3385a;

      svg path {
        stroke: #f3385a;
      }
    }
  }

  .hashtags {
    display: flex;
    color: #fff;
    flex-wrap: wrap;

    @media (max-width: 667px) {
      //justify-content: center;

      margin-top: 25px;
    }

    li {
      margin-right: 19px;
      font-size: 12px;
      text-transform: uppercase;
      padding-top: 15px;
      letter-spacing: 0.2em;
      font-weight: 600;

      @media (max-width: 667px) {
        font-size: 9px;
        margin-right: 8px;
        padding-top: 5px;
      }
    }
  }

  /* Code for references list */
  .reference-filter {
    text-align: center;
    margin: 90px 0;
    //border-bottom: 1px solid rgba(#fff, 0.1);
    position: relative;

    &.is-fixed {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      margin: 0;
      background: #000;
      z-index: 9999;
    }

    .line {
      position: absolute;
      left: 0;
      width: 0;
      height: 1px;
      bottom: 0;
      background: rgba(#fff, 0.1);
      transition: all 2s linear;

      // animations
      width: 0;
    }

    @media (max-width: 667px) {
      margin: 40px 0;

      &:after {
        content: "";
        background: linear-gradient(-90deg, #000807, rgba(255, 255, 255, 0));
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 30%;

        display: none;
      }
    }

    ul {
      display: flex;
      list-style: none;
      justify-content: center;
      align-items: flex-start;

      @media (max-width: 667px) {
        justify-content: flex-start;
        margin-left: 20px;
      }

      li {
        padding: 20px 50px;
        margin: 0 10px;
        cursor: pointer;
        color: #fff;
        transition: all 0.35s ease-in-out;
        font-size: 18px;
        font-weight: 400;
        position: relative;

        // animation
        opacity: 0;

        @media (max-width: 667px) {
          padding: 20px 20px;
          font-size: 15px;

          &:last-child {
            padding-right: 75px;
          }
        }

        &:hover {
          color: #e5375d;
        }

        &.is-active {
          color: #e5375d;

          &:after {
            width: 100%;
          }
        }

        &:after {
          content: "";
          background: #f3385a;
          height: 3px;
          width: 0;
          position: absolute;
          left: 0;
          bottom: -1px;
          transition: all 0.35s ease-in-out;
        }
      }
    }
  }

  .references-list {
    transition: all 0.6s linear;
    min-height: 550px;

    // animations
    opacity: 0;
    transform: translate(0, 100px);

    &__item {
      margin-bottom: 158px;

      @media (max-width: 1440px) {
        margin-bottom: 100px;
      }

      @media (max-width: 768px) {
        margin-bottom: 40px;
        display: flex;
        flex-wrap: wrap;

        .column {
            flex: 1 1 100%;

            > a {
                display: block;
                width: 100%;
            }

            img {
                width: 100%;
            }

            .item-text {
                max-width: 100%;
            }
        }

        .column:not(.column--text) {
            order: -1;
        }
      }

      .column {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-start;

        @media (max-width: 667px) {
          width: 100%;
          flex: 0 0 100%;
        }

        &--text {
          padding-left: 70px;

          @media (max-width: 992px) {
            padding-left: 15px;
          }

          @media (max-width: 667px) {
            order: 2;
          }
        }
      }

      img {
        transition: all 0.5s ease-in-out;
        border-radius: 6px;
      }

      a:hover {
        img {
          transform: scale(1.15) rotate(2deg);
        }

        .img-wrapper:after {
          opacity: 1;
        }
      }

      .img-wrapper {
        overflow: hidden;
        border-radius: 6px;
        position: relative;

        &:after {
          content: "";
          position: absolute;
          left: 0;
          bottom: -1px;
          width: 100%;
          opacity: 0;
          z-index: 10;
          height: 40%;
          border-radius: 6px;
          transition: all 0.45s ease-in-out;
          background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0) 0%,
            rgba(0, 8, 7, 1) 100%
          );
        }
      }

      .item-hashtags {
        color: #f3385a;
        font-weight: 500;
        margin-bottom: 12px;

        span {
          display: inline-block;
          margin-right: 10px;
        }
      }

      .item-title {
        color: #fff;
        font-size: 44px;
        font-weight: 500;
        margin-bottom: 15px;
        margin-top: 0;

        &:hover a {
          color: #f3385a;
        }

        a {
          color: #fff;
          transition: all 0.35s ease-in-out;
        }

        @media (max-width: 667px) {
          font-size: 28px;
        }
      }

      .item-text {
        color: #fff;
        max-width: 420px;
        font-size: 18px;
        margin-bottom: 22px;

        @media (max-width: 667px) {
          font-size: 16px;
        }
      }

      .item-link {
        display: inline-block;
        color: #7f8383;
        position: relative;
        font-weight: 500;
        font-size: 16px;
        padding-bottom: 13px;
        transition: all 0.35s ease-in-out;

        &:hover {
          color: #f3385a;

          &:after {
            width: 100%;
          }
        }

        &:before {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          height: 1px;
          width: 100%;
          background: rgba(#fff, 0.5);
        }

        &:after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          height: 1px;
          width: 0;
          background: #f3385a;
          transition: width 0.45s ease-in-out;
          z-index: 10;
        }
      }
    }
  }

  .load-more {
    margin: 0 0 110px 0;
    text-align: center;

    .load-more-btn {
      color: #fff;
      height: 65px;
      border: 1px solid #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      max-width: 200px;
      margin: 0 auto;
      border-radius: 60px;
      transition: all 0.35s ease-in-out;
      font-size: 16px;
      font-weight: 600;

      &:hover {
        background: #fff;
        color: #f3385a;
      }
    }
  }

  .header_line {
    display: none;
  }

  .glow-line {
    position: absolute;
    left: -20px;
    top: 650px;

    @media (max-width: 1024px) {
      display: none;
    }

    .glow-path {
      stroke-dasharray: 2500px;
      stroke-dashoffset: 2500px;
    }
  }
}

.link-row {
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  color: #000;
  margin-bottom: 50px;
  a {
    color: #000;
    letter-spacing: 0.2em;

    &:hover {
      opacity: 0.75;

      i {
        transform: translate(5px, 0);
      }
    }
  }

  i {
    background: url("../../images/arrow-next.svg") no-repeat;
    display: inline-block;
    background-size: contain;
    width: 14px;
    height: 14px;
    position: relative;
    top: 3px;
    transition: all 0.35s ease-in-out;
  }

  &--topNegative {
    margin-top: -80px;

    @media (max-width: 667px) {
        margin-top: 20px; 
    }
  }

  &--topMore {
    margin-top: 100px;

    @media (max-width: 667px) {
        margin-top: 40px;
    }
  }
}

.info-box {
  max-width: 710px;
  margin: 50px auto;
  text-align: center;

  // animation
  transform: translate(0, 100px);
  opacity: 0;
  transition: all 1s ease-in-out;

  p {
    font-size: 18px;
  }
}

.container {
  z-index: 100;
}

.hero {
  &__canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;

    canvas {
      width: 100% !important;
      display: block;
    }
  }
}

.referenceView.casopis {
  .hashtags li {
    color: #fff;
    opacity: 0.5;
  }

  .hero--sub h1 {
    max-width: 630px;
  }

  .hero--sub {
    background-image: url("../../images/references/casopis/hero-bg.png") !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #f1f1f1;
  }

  .link-row {
    margin: 60px;

    @media (max-width: 667px) {
      margin: 20px 0;
    }

    a {
      color: #000;
    }

    i {
      background: url("../../images/references/drtheiss/arrow-next.svg")
        no-repeat;
    }
  }

  .block--boxes {
    justify-content: center;

    @media (max-width: 768px) {
        margin-bottom: 60px;
    }

    .service-box {
      background: #f3f3f3;
      margin: 0 15px;
      flex: 0 1 50%;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      padding: 40px 4%;

      @media (max-width: 768px) {
        margin-bottom: 25px;
        }

      p {
        line-height: 1.85;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      @media (max-width: 667px) {
        margin:0 0 25px 0;
      }
    }
  }

  .block--cover-image {
    background: #ffcc06;
    margin-bottom: 0;

    @media (max-width: 667px) {
      background: #f6f6f6;
      padding-bottom: 0;
    }

    #video {
      display: block;
      margin-bottom: -5px;
      margin: 0 auto;
    }

    @media (max-width: 667px) {
      #video {
        transform: scale(1.6);
      }
    }
  }

  .block-images {
    background: #f6f6f6;
    width: 100%;
    padding: 150px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 220px;
    position: relative;

    @media (max-width: 1024px) {
      padding: 60px 0;
    }

    @media (max-width: 667px) {
      margin-bottom: 100px;
      padding: 40px 10px 20px 10px;
      margin-bottom: 40px;
    }

    img {
      display: block;
      margin: 0 1%;
      max-width: 100%;
      transition: all 1s ease-in-out;

      //animations
      opacity: 0;
      transform: translate(0, 200px);

      @media (max-width: 667px) {
        margin-bottom: 20px;
      }
    }
  }

  .overlayer {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: #ffcc06;
    z-index: 1000;
    transition: all 1s linear;
  }

  .block--intro {
    max-width: 850px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;

    @media (max-width: 667px) {
      h2 {
        display: block;
        font-size: 20px;
      }
    }

    p {
      max-width: 100%;
    }
  }

  .block--columns {
    background: #f6f6f6;
    padding-top: 5vw;
  }

  .block--screens {
    margin-top: 0;

    @media (max-width: 667px) {
      margin-top: 40px;
    }

    img {
      width: 100vw;
      max-width: 100vw;
    }
  }

  .services {
    padding: 0;

    @media (max-width: 667px) {
      padding-top: 20px;
    }

    &__item {
      padding-bottom: 10vw;
      height: 100%;
      justify-content: space-between;
      align-items: flex-start;

      @media (max-width: 667px) {
        margin-bottom: 20px;
        display: flex;
        flex-wrap: wrap;
      }

      // animation
      opacity: 0;
      transform: translate(0, 100px);
      transition: all 1s ease-in-out;
    }

    &__col {
      height: 100%;
      max-width: 500px;

      @media (max-width: 667px) {
        flex: 0 0 100%;
      }

      h2 {
        font-size: 36px;
        max-width: 450px;

        @media (max-width: 667px) {
          font-size: 18px;
        }
      }

      p {
        max-width: 440px;
        line-height: 2;
      }
    }

    &__image {
      display: flex;
      align-items: flex-end;
      position: relative;
      min-height: 584px;
      overflow: hidden;
      flex: 0 0 49%;

      img {
        margin: 0 auto;
        position: relative;
        top: 20px;
      }

      &.last {
        align-items: center;
      }

      @media (max-width: 667px) {
        order: -1;
        width: 100%;
        min-height: unset;
        flex: 0 0 100%;
        margin-bottom: 20px;
      }
    }
  }

  .image-pack {
    padding-bottom: 100px;

    @media (max-width: 667px) {
      padding-bottom: 40px;
    }
  }

  .block--image-pack {
    margin-bottom: 82px;

    @media (max-width: 667px) {
      margin-bottom: 25px;
    }
  }

  .block--previews {
    background: #fff;
    padding: 150px 0 150px 0;

    @media (max-width: 667px) {
      padding: 60px 0;
    }

    .previews-screens {
      padding: 0;
    }

    .columns {
      align-items: center;
      justify-content: center;
    }

    .screen-image {
      padding: 0 13px;

      img {
        border-radius: 0;
      }
    }

    .block-image {
      margin-bottom: 40px;
    }
  }
}

.referenceView.jankadera {
  .hashtags li {
    color: #000;
    opacity: 1;
  }

  .hero--sub {
    background-color: #ffcc06;
    background-image: url("../../images/references/jan-kadera/hero-bg.png") !important;
    background-size: 678px 695px;
    background-repeat: no-repeat;
    background-position: 75% bottom;

    @media (max-width: 1024px) {
        background-size: 548px 565px;
        background-position: 100% bottom;
    }

    @media (max-width: 667px) {
      background-size: 178px 195px;
      background-position: 100% bottom;
    }

    h1 {
      max-width: 630px;
      color: #000;
      margin-bottom: 5px;

      @media (max-width: 768px) {
        max-width: 400px;
      }
    }

    .hashtags {
        @media (max-width: 768px) {
            max-width: 400px;
          }

          @media (max-width: 667px) {
            max-width: 200px;
            margin: 10px auto;
          }
    }

    .subtitle {
      color: #000;
      margin-bottom: 5px;
    }
  }

  .back-link {
    color: #000;

    svg path {
      stroke: #000;
    }

    &:hover {
      color: #f3385a;

      svg path {
        stroke: #f3385a;
      }
    }
  }

  .link-row {
    margin: 60px;

    @media (max-width: 667px) {
      margin: 20px 0;
    }

    a {
      color: #000;
    }

    i {
      background: url("../../images/references/drtheiss/arrow-next.svg")
        no-repeat;
    }
  }

  .block--boxes {
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 120px;

    @media (max-width: 667px) {
      margin-bottom: 60px;
    }

    .service-box {
      background: #fffced;
      margin: 0 15px 20px 15px;
      flex: 0 1 45%;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      padding: 40px 4%;

      p {
        line-height: 1.85;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      ul {
        li {
          font-size: 14px;
          line-height: 1.25;
          color: rgba(0, 0, 0, 0.65);
        }
      }

      @media (max-width: 667px) {
        margin: 0 0 25px 0;
      }
    }
  }

  .block--cover-image {
    background: #ffcc06;
    margin-bottom: 0;

    @media (max-width: 667px) {
      background: #f6f6f6;
      padding: 0;
    }
  }

  .block-images {
    background: #f6f6f6;
    width: 100%;
    padding: 150px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 220px;
    position: relative;

    @media (max-width: 1024px) {
      padding: 60px 0;
    }

    @media (max-width: 667px) {
      margin-bottom: 100px;
      padding: 40px 10px 20px 10px;
      margin-bottom: 40px;
    }

    img {
      display: block;
      margin: 0 1%;
      max-width: 100%;
      transition: all 1s ease-in-out;

      //animations
      opacity: 0;
      transform: translate(0, 200px);

      @media (max-width: 667px) {
        margin-bottom: 20px;
      }
    }
  }

  .overlayer {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: #ffcc06;
    z-index: 1000;
    transition: all 1s linear;
  }

  .block--intro {
    max-width: 850px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;

    @media (max-width: 667px) {
      h2 {
        display: block;
        font-size: 20px;
      }
    }

    p {
      max-width: 100%;
    }
  }

  .block--screens {
    margin-top: 0;

    @media (max-width: 667px) {
      margin-top: 40px;
    }

    img {
      width: 100vw;
      max-width: 100vw;
    }
  }

  .services {
    padding: 0;

    @media (max-width: 667px) {
      padding-top: 20px;
    }

    &__item {
      padding-bottom: 10vw;
      height: 100%;
      justify-content: space-between;
      align-items: flex-start;

      @media (max-width: 667px) {
        margin-bottom: 20px;
        display: flex;
        flex-wrap: wrap;
      }

      // animation
      opacity: 0;
      transform: translate(0, 100px);
      transition: all 1s ease-in-out;
    }

    &__col {
      height: 100%;
      max-width: 500px;

      @media (max-width: 667px) {
        flex: 0 0 100%;
      }

      h2 {
        font-size: 36px;
        max-width: 450px;

        @media (max-width: 667px) {
          font-size: 18px;
        }
      }

      p {
        max-width: 440px;
        line-height: 2;
      }
    }

    &__image {
      display: flex;
      align-items: flex-end;
      position: relative;
      min-height: 584px;
      overflow: hidden;
      flex: 0 0 49%;

      img {
        margin: 0 auto;
        position: relative;
        top: 20px;
      }

      &.last {
        align-items: center;
      }

      @media (max-width: 667px) {
        order: -1;
        width: 100%;
        min-height: unset;
        flex: 0 0 100%;
        margin-bottom: 20px;
      }
    }
  }

  .block--image-pack {
    margin: 82px 0 150px 0;

    @media (max-width: 667px) {
      margin: 25px 0;
    }
  }

  .block--previews {
    background: #fff;
    padding: 150px 0 20px 0;

    @media (max-width: 667px) {
      padding: 40px 0;
    }

    .previews-screens {
      padding: 0;
    }

    .columns {
      align-items: center;
      justify-content: center;
    }

    .screen-image {
      padding: 0 13px;

      img {
        border-radius: 0;
      }
    }

    .block-image {
      margin-bottom: 40px;

      @media (max-width: 667px) {
        margin-bottom: 20px;
      }
    }
  }
}
