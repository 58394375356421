//
//	SVG
// ------------------------------------------------------

button:focus{
    outline: none;
}

.p-0{
    padding: 0 !important;
}
.columns{
    margin: 0;
}
.opacity-0{
    opacity: 0;
}
.reveal_wrap{
    overflow: hidden;
    overflow-y: hidden;
    overflow-x: hidden;
    width: 100%;
}
.reveal_wrap div{
    transition: all 1s ease-in-out;
}

.d-none{
    display: none !important;
}
@media (max-width: 1000px){
    .cursor{
        display: none !important;
    }
}
.cursor {
    z-index: 50;
    width: 98px;
    height: 98px;
    border: 1px solid #F3385A;
    border-radius: 50%;
    display: none;
    justify-content: center;
    align-items: center;
    position: fixed;
    user-select: none;
    pointer-events: none;
}
.cursor .text {
    text-align: center;
    font-family: Roboto Light, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #fff;
    text-shadow: 0 2px 5px rgba(0, 0, 0, 0.45);
}
.cursor.cursor3 .text{
    font-size: 12px;
}
