//
//	ANIMATIONS
// ------------------------------------------------------

@keyframes line_path {
    100% {
        stroke-dashoffset: 2650;
    }
}
@-webkit-keyframes line_path {
    100% {
        stroke-dashoffset: 2650;
    }
}
@-moz-keyframes line_path {
    100% {
        stroke-dashoffset: 2650;
    }
}
@-ms-keyframes line_path {
    100% {
        stroke-dashoffset: 2650;
    }
}
@-o-keyframes line_path {
    100% {
        stroke-dashoffset: 2650;
    }
}


@keyframes galerybefore{
    100% {
        transform: translateX(100%);
    }
}
@-webkit-keyframes galerybefore{
    100% {
        transform: translateX(100%);
    }
}
@-moz-keyframes galerybefore{
    100% {
        transform: translateX(100%);
    }
}
@-ms-keyframes galerybefore{
    100% {
        transform: translateX(100%);
    }
}
@-o-keyframes galerybefore{
    100% {
        transform: translateX(100%);
    }
}



@keyframes blur {
    0%	{text-shadow: 0 0 5px #fff;}
    25%	{text-shadow: 0 0 4px #fff;}
    50%	{text-shadow: 0 0 3px #fff;}
    75%	{text-shadow: 0 0 2px #fff;}
	100%	{text-shadow: 0 0 0px #fff;}
}
@-webkit-keyframes blur {
    0%	{text-shadow: 0 0 5px #fff;}
    25%	{text-shadow: 0 0 4px #fff;}
    50%	{text-shadow: 0 0 3px #fff;}
    75%	{text-shadow: 0 0 2px #fff;}
	100%	{text-shadow: 0 0 0px #fff;}
}
@-moz-keyframes blur {
    0%	{text-shadow: 0 0 5px #fff;}
    25%	{text-shadow: 0 0 4px #fff;}
    50%	{text-shadow: 0 0 3px #fff;}
    75%	{text-shadow: 0 0 2px #fff;}
	100%	{text-shadow: 0 0 0px #fff;}
}
@-ms-keyframes blur {
    0%	{text-shadow: 0 0 5px #fff;}
    25%	{text-shadow: 0 0 4px #fff;}
    50%	{text-shadow: 0 0 3px #fff;}
    75%	{text-shadow: 0 0 2px #fff;}
	100%	{text-shadow: 0 0 0px #fff;}
}
@-o-keyframes blur {
    0%	{text-shadow: 0 0 5px #fff;}
    25%	{text-shadow: 0 0 4px #fff;}
    50%	{text-shadow: 0 0 3px #fff;}
    75%	{text-shadow: 0 0 2px #fff;}
	100%	{text-shadow: 0 0 0px #fff;}
}





@keyframes zisk {
    0%	{transform: scale(0)}
    50%	{transform: scale(1.3)}
    75%	{transform: scale(0.9)}
    100%{transform: scale(1)}
}
@-webkit-keyframes zisk {
    0%	{transform: scale(0)}
    50%	{transform: scale(1.3)}
    75%	{transform: scale(0.9)}
    100%{transform: scale(1)}
}
@-moz-keyframes zisk {
    0%	{transform: scale(0)}
    50%	{transform: scale(1.3)}
    75%	{transform: scale(0.9)}
    100%{transform: scale(1)}
}
@-ms-keyframes zisk {
    0%	{transform: scale(0)}
    50%	{transform: scale(1.3)}
    75%	{transform: scale(0.9)}
    100%{transform: scale(1)}
}
@-o-keyframes zisk {
    0%	{transform: scale(0)}
    50%	{transform: scale(1.3)}
    75%	{transform: scale(0.9)}
    100%{transform: scale(1)}
}


@keyframes keyarm{
    0% { transform: rotate(0deg); }
    5% { transform: rotate(-14deg); }
    10% { transform: rotate(0deg); }
}
@-webkit-keyframes keyarm{
    0% { transform: rotate(0deg); }
    5% { transform: rotate(-14deg); }
    10% { transform: rotate(0deg); }
}
@-moz-keyframes keyarm{
    0% { transform: rotate(0deg); }
    5% { transform: rotate(-14deg); }
    10% { transform: rotate(0deg); }
}
@-ms-keyframes keyarm{
    0% { transform: rotate(0deg); }
    5% { transform: rotate(-14deg); }
    10% { transform: rotate(0deg); }
}
@-o-keyframes keyarm{
    0% { transform: rotate(0deg); }
    5% { transform: rotate(-14deg); }
    10% { transform: rotate(0deg); }
}


@keyframes rotate {
    0% {
        transform: translate(-50%,-50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%,-50%) rotate(360deg);
        stroke-dashoffset: 0;
    }
}
@-webkit-keyframes rotate {
    0% {
        transform: translate(-50%,-50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%,-50%) rotate(360deg);
        stroke-dashoffset: 0;
    }
}
@-moz-keyframes rotate {
    0% {
        transform: translate(-50%,-50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%,-50%) rotate(360deg);
        stroke-dashoffset: 0;
    }
}
@-ms-keyframes rotate {
    0% {
        transform: translate(-50%,-50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%,-50%) rotate(360deg);
        stroke-dashoffset: 0;
    }
}
@-o-keyframes rotate {
    0% {
        transform: translate(-50%,-50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%,-50%) rotate(360deg);
        stroke-dashoffset: 0;
    }
}
