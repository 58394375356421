//
//	VARIABLES
// ------------------------------------------------------


$bg-color: #181A1A;

$headline: #E5375D;

$lienar_border: linear-gradient(277.48deg, #0FA6E1 8.64%, #F3385A 56.22%, #45187A 97.03%);

$container_w: 1700px;
$logo_w: 152px;

$logo_left: calc(#{$container_w / 2} - #{$logo_w / 2});
$logo_left_media :calc(#{95% / 2} - #{$logo_w / 2});
$bg_section2: #0F1615;


// Juice v0.0.8
// ------------

// ----------------------------------
// Variable settings
// ----------------------------------

// Base px
$base-px-default: 16px !default;
// Breakpoints
$hdpi-ratio-default: 1.3 !default;
// Border Radius
$border-radius-default: 5px !default;
// Placeholder Color
$placeholder-color-default: #555555 !default;
// Triangle
$triangle-direction-default: right !default;
$triangle-size-default: $base-px-default !default;
$triangle-color-default: #222222 !default;
$triangle-element-default: after !default;
// Circle
$circle-size-default: $base-px-default !default;
$circle-color-default: inherit !default;
$circle-border-width-default: null !default;
$circle-border-color-default: #222222 !default;
$circle-display-default: inline-block !default;
// Position
$position-default: null !default;
// Tint/Shade
$mix-percent-default: 15% !default;


// ----------------------------------
// Mixins
// ----------------------------------

// Breakpoints
// -----------
$breakpoints: (
	"xxlarge":	(min-width: 120.0625em),														// >= 1921px
	"xlarge-only":	(min-width: 90.0625em) and (max-width: 120em),	// 1441px - 1920px
	"xlarge-up":	(min-width: 90.0625em),														// <= 1920px
	"xlarge":	(max-width: 120em),																		// <= 1920px
	"large-only":	(min-width: 64.0625em) and (max-width: 90em),			// 1025px - 1440px
	"large-up":	(min-width: 64.0625em),															// <= 1440px
	"large":	(max-width: 90em),																		// <= 1440px
	"medium-only":	(min-width: 40.0625em) and (max-width: 64em),		// 641px - 1024px
	"medium-up":	(min-width: 40.0625em),														// <= 1024px
	"medium":	(max-width: 64em),																		// <= 1024px
	"not-small":	(min-width: 40.0625em),														// >= 641px
	"small":	(max-width: 40em),																		// <= 640px
	"xsmall":	(max-width: 30em),																		// <= 480px
	"xxsmall":	(max-width: 20em),																	// <= 320px
	"iphone3":	(min-device-width: 320px) and (max-device-width: 480px) and (-webkit-device-pixel-ratio: 1),
	"iphone3-landscape":	(min-device-width: 320px) and (max-device-width: 480px) and (-webkit-device-pixel-ratio: 1) and (orientation: landscape),
	"iphone3-portrait":	(min-device-width: 320px) and (max-device-width: 480px) and (-webkit-device-pixel-ratio: 1) and (orientation: portrait),
	"iphone4":	(min-device-width: 320px) and (max-device-width: 480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3),
	"iphone4-landscape":	(min-device-width: 320px) and (max-device-width: 480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) and (orientation: landscape),
	"iphone4-portrait":	(min-device-width: 320px) and (max-device-width: 480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) and (orientation: portrait),
	"iphone5":	(min-device-width: 320px) and (max-device-width: 568px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71),
	"iphone5-landscape":	(min-device-width: 320px) and (max-device-width: 568px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) and (orientation: landscape),
	"iphone5-portrait":	(min-device-width: 320px) and (max-device-width: 568px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) and (orientation: portrait),
	"iphone6":	(min-device-width: 375px) and (max-device-width: 667px) and (-webkit-device-pixel-ratio: 2),
	"iphone6-landscape":	(min-device-width: 375px) and (max-device-width: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape),
	"iphone6-portrait":	(min-device-width: 375px) and (max-device-width: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait),
	"iphone6-plus":	(min-device-width: 414px) and (max-device-width: 736px) and (-webkit-device-pixel-ratio: 3),
	"iphone6-plus-landscape":	(min-device-width: 414px) and (max-device-width: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape),
	"iphone6-plus-portrait":	(min-device-width: 414px) and (max-device-width: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait),
	"ipad":	(min-device-width: 768px) and (max-device-width: 1024px),
	"ipad-landscape":	(min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape),
	"ipad-portrait":	(min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait),
	"ipad-retina":	(min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2),
	"ipad-retina-landscape":	(min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape),
	"ipad-retina-portrait":	(min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait),
	"hdpi":	"(-webkit-min-device-pixel-ratio: $hdpi-ratio-default),
					only screen and (min--moz-device-pixel-ratio: $hdpi-ratio-default),
					only screen and (-moz-min-device-pixel-ratio: $hdpi-ratio-default),
					only screen and (-o-min-device-pixel-ratio: #{$hdpi-ratio-default}/1),
					only screen and (min-resolution: #{round($hdpi-ratio-default*96)}dpi),
					only screen and (min-resolution: #{$hdpi-ratio-default}dppx)"
);

@mixin bp($break, $viewport1: null) {
	// preset breakpoint
	@if not $viewport1 {
		@if map-has-key($breakpoints, $break) {
			@media only screen and #{inspect(map-get($breakpoints, $break))} { @content; }
		}
		@else {
			@warn "Couldn't find a breakpoint named #{$break}.";
		}
	}
	@else {
		// min breakpoint
		@if $break == min {
			@media screen and (min-width: $viewport1) { @content; }
		}
		// max breakpoint
		@else if $break == max {
			@media screen and (max-width: $viewport1) { @content; }
		}
		// min & max breakpoint
		@else {
			@media screen and (min-width: $break) and (max-width: $viewport1) { @content; }
		}
	}
}


// Single side border radius
// -------------------------
@mixin border-top-radius($radius: $border-radius-default) {
	border-top-right-radius: $radius;
	border-top-left-radius: $radius;
}
@mixin border-right-radius($radius: $border-radius-default) {
	border-top-right-radius: $radius;
	border-bottom-right-radius: $radius;
}
@mixin border-bottom-radius($radius: $border-radius-default) {
	border-bottom-right-radius: $radius;
	border-bottom-left-radius: $radius;
}
@mixin border-left-radius($radius: $border-radius-default) {
	border-top-left-radius: $radius;
	border-bottom-left-radius: $radius;
}


// Transforms
// ----------
@mixin transform-single($property, $value) {
	transform: #{$property}unquote('('#{$value}')');
}
@mixin rotate($deg) {
	@include transform-single(rotate, $deg);
}
@mixin rotateX($deg) {
	@include transform-single(rotateX, $deg);
}
@mixin rotateY($deg) {
	@include transform-single(rotateY, $deg);
}
@mixin rotateZ($deg) {
	@include transform-single(rotateZ, $deg);
}
@mixin rotate3d($x, $y, $z, $deg) {
	$multi-var: $x, $y, $z, $deg;
	@include transform-single(rotate3d, $multi-var);
}
@mixin scale($ratio) {
	@include transform-single(scale, $ratio);
}
@mixin scaleX($ratio) {
	@include transform-single(scaleX, $ratio);
}
@mixin scaleY($ratio) {
	@include transform-single(scaleY, $ratio);
}
@mixin scaleZ($ratio) {
	@include transform-single(scaleZ, $ratio);
}
@mixin scale3d($x, $y, $z) {
	$multi-var: $x, $y, $z;
	@include transform-single(scale3d, $multi-var);
}
@mixin skew($x, $y) {
	$multi-var: $x, $y;
	@include transform-single(skew, $multi-var);
	backface-visibility: hidden;
}
@mixin skewX($deg) {
	@include transform-single(skewX, $deg);
	backface-visibility: hidden;
}
@mixin skewY($deg) {
	@include transform-single(skewY, $deg);
	backface-visibility: hidden;
}
@mixin translate($x, $y) {
	$multi-var: $x, $y;
	@include transform-single(translate, $multi-var);
}
@mixin translateX($x) {
	@include transform-single(translateX, $x);
}
@mixin translateY($y) {
	@include transform-single(translateY, $y);
}
@mixin translateZ($z) {
	@include transform-single(translateZ, $z);
}
@mixin translate3d($x, $y, $z) {
	$multi-var: $x, $y, $z;
	@include transform-single(translate3d, $multi-var);
}


// Emboss effect
// -------------
@mixin box-emboss($opacity, $opacity2){
	box-shadow: rgba(white,$opacity) 0 1px 0, inset rgba(black,$opacity2) 0 1px 0;
}

// Letterpress effect
// ------------------
@mixin letterpress($opacity){
	text-shadow: rgba(white,$opacity) 0 1px 0;
}

// Placeholder text
// ----------------
@mixin placeholder-color($color: $placeholder-color-default) {
	&::placeholder {
		color: $color;
	}
}

// Sizing
// ------
@mixin size($width, $height: $width) {
	width: $width;
	height: $height;
}
@mixin square($size) {
	@include size($size, $size);
}

// Normal & hover state
// --------------------
@mixin hoverer($property, $normal, $hovered) {
	#{$property}: $normal;
	&:hover {
		#{$property}: $hovered;
	}
}

// Add responsive styling for multiple breakpoints
// -----------------------------------------------
@mixin responsive($property, $full, $medium:false, $small:false) {
	#{$property}: $full;
	@if $medium {
		@include bp(medium) {
			#{$property}: $medium;
		}
	}
	@if $small {
		@include bp(small) {
			#{$property}: $small;
		}
	}
}

// Create a triangle using borders (add in the option to make a border and triangle shape)
// Cut down on the code (Use sass maps for "trbl" and translate direction, maybe direction and border direction)
// ------------------------------------------------------------------------------------------------------------------
@mixin triangle($direction: $triangle-direction-default,
								$size: $triangle-size-default,
								$color: $triangle-color-default,
								$center: false,
								$element: $triangle-element-default) {
	&:#{$element} {
		@if not $center {
			position: absolute;
		}
		content:'';
		@include square(0);
		-webkit-transform: rotate(360deg);
		border-style: solid;
		@if $direction == up {
			border-width: 0 $size ($size*1.625) $size;
			border-color: transparent transparent $color transparent;
			@if $center {
				@include absolute(null,null,100%,50%);
				transform: translateX(-$size);
			}
		}
		@else if $direction == up-right {
			border-width: 0 $size $size 0;
			border-color: transparent $color transparent transparent;
			@if $center {
				@include absolute(0,0);
			}
		}
		@else if $direction == right {
			border-width: $size 0 $size ($size*1.625);
			border-color: transparent transparent transparent $color;
			@if $center {
				@include absolute(50%,null,null,100%);
				transform: translateY(-$size);
			}
		}
		@else if $direction == down-right {
			border-width: 0 0 $size $size;
			border-color: transparent transparent $color transparent;
			@if $center {
				@include absolute(null,0,0);
			}
		}
		@else if $direction == down {
			border-width: ($size*1.625) $size 0 $size;
			border-color: $color transparent transparent transparent;
			@if $center {
				@include absolute(100%,null,null,50%);
				transform: translateX(-$size);
			}
		}
		@else if $direction == down-left {
			border-width: $size 0 0 $size;
			border-color: transparent transparent transparent $color;
			@if $center {
				@include absolute(null,null,0,0);
			}
		}
		@else if $direction == left {
			border-width: $size ($size*1.625) $size 0;
			border-color: transparent $color transparent transparent;
			@if $center {
				@include absolute(50%,100%);
				transform: translateY(-$size);
			}
		}
		@else if $direction == up-left {
			border-width: $size $size 0 0;
			border-color: $color transparent transparent transparent;
			@if $center {
				@include absolute(0,null,null,0);
			}
		}
		@else {
			@warn "Available directions: up, up-right, right, down-right, down, down-left, left, up-left.";
		}
		@content;
	}
}

// Create a circle, with an optional border
@mixin circle($size: $circle-size-default,
						$color: $circle-color-default,
						$border-width: $circle-border-width-default,
						$border-color: $circle-border-color-default,
						$display: $circle-display-default) {
	display: $display;
	border-radius: 50%;
	@if $border-width {
		@include square($size);
		background-color: $color;
		border: $border-width solid $border-color;
	}
	@else{
		@if $color == inherit {
			border-width: $size/2;
			border-style: solid;
			@include square(0);
		}
		@else {
			@include square($size);
			background-color: $color;
		}
	}
}

// Advanced positioning
// --------------------
@mixin position($type,
								$top: $position-default,
								$right: $position-default,
								$bottom: $position-default,
								$left: $position-default) {
	position: $type;
	$allowed_types: absolute relative fixed;
	@if not index($allowed_types, $type) {
		@warn "Unknown position: #{$type}.";
	}
	@each $data in top $top, right $right, bottom $bottom, left $left {
		#{nth($data, 1)}: nth($data, 2);
	}
}
@mixin absolute($top: $position-default, $right: $position-default, $bottom: $position-default, $left: $position-default) {
	@include position(absolute, $top, $right, $bottom, $left);
}
@mixin relative($top: $position-default, $right: $position-default, $bottom: $position-default, $left: $position-default) {
	@include position(relative, $top, $right, $bottom, $left);
}
@mixin fixed($top: $position-default, $right: $position-default, $bottom: $position-default, $left: $position-default) {
	@include position(fixed, $top, $right, $bottom, $left);
}


// ----------------------------------
// Placeholders
// ----------------------------------

// Clearfix
// --------
%clearfix {
	*zoom: 1;
	&:before,
	&:after {
		display: table;
		content:'';
		line-height: 0;
	}
	&:after {
		clear: both;
	}
}

// Hide text
// ---------
%hide-text {
	font: 0/0 a;
	color: transparent;
	text-shadow: none;
}

// Center an element vertically and horizontally
// ---------------------------------------------
%centerer {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

// Center an element vertically
// ----------------------------
%vert-centerer {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

// Cover everything
// ----------------
%coverer {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

// Center a block level element
// ----------------------------
%center-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
}


// ----------------------------------
// Functions
// ----------------------------------

// Mix white with another color
// ----------------------------
@function tint($color, $percent: $mix-percent-default){
	@return mix(white, $color, $percent);
}
 
// Mix black with another color
// ----------------------------
@function shade($color, $percent: $mix-percent-default){
	@return mix(black, $color, $percent);
}

// Strip the units from a value
// ----------------------------
@function strip-units($value) {
	@return $value / ($value * 0 + 1);
}

// Calculate rems from a px value
// ------------------------------
@function rem-calc($px, $base-val: $base-px-default) {
	@if not unitless($px) {
		$px: strip-units($px);
	}
	@if not unitless($base-val) {
		$base-val: strip-units($base-val);
	}
	@return ($px / $base-val) * 1rem;
}

// Calculate ems from a px value
// ------------------------------
@function em-calc($px, $base-val: $base-px-default) {
	@if not unitless($px) {
		$px: strip-units($px);
	}
	@if not unitless($base-val) {
		$base-val: strip-units($base-val);
	}
	@return ($px / $base-val) * 1em;
}

// Create a random color
// ---------------------
@function random-color($min: 0, $max: 255, $alpha: 1) {
	@if $min < 0 {
		$min: -1;
	} @else {
		$min: $min - 1;
	}
	@if $max > 255 {
		$max: 256;
	} @else {
		$max: $max + 1;
	}
	$red: random($max) + $min;
	$green: random($max) + $min;
	$blue: random($max) + $min;
	@return rgba($red, $green, $blue, $alpha);
}

// Reverse a string
// ----------------
@function reverse($string) {
	$reversed-string: '';
	@for $i from 1 through str-length($string) {
		$char: str-slice($string, $i, $i);
		$reversed-string: "#{$char}#{$reversed-string}";
	}
	@return #{$reversed-string};
}
