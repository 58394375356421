/* new styles for services pages */
.ServiceWeb {
  #footer {
    padding-top: 75px;

    @media (max-width: 950px) {
      padding-top: 25px;
    }
  }

  .content {
    @media (max-width: 667px) {
      padding-top: 20px;
    }
  }

  .hero--sub {
    padding-bottom: 0;
    padding-top: 40px;

    @media (max-width: 950px) {
      padding-top: 0;
    }


  }

  .section7 {
    position: relative;
    min-height: 660px;
    width: 100%;
    @media (max-width: 950px) {
      height: auto;
    }
    svg.svg_line {
      position: absolute;
      top: -120px;
      pointer-events: none;
      .line {
        stroke-dasharray: 2800px;
        stroke-dashoffset: 2800px;
        will-change: stroke-dashoffset;
      }
      @media (min-width: 2200px) {
        display: none;
      }
      @media (max-width: 1800px) {
        top: -150px;
      }
      @media (max-width: 1050px) {
        width: 100%;
        top: -300px;
      }
      @media (max-width: 500px) {
        width: auto;
        top: -120px;
        left: -600px;
      }
    }
    .bg {
      width: 100%;
      height: 100%;
      background-color: #181a1a;
      padding-top: 90px;
      padding-bottom: 100px;

      @media (max-width: 1440px) {
        padding-bottom: 40px;
      }

      @media (max-width: 667px) {
        padding-bottom: 40px;
        padding-top: 60px;
      }

      .recenzie {
        width: 100%;
        position: relative;
        @media (max-width: 950px) {
          width: 100%;
          padding-bottom: 0;
          text-align: center;
        }
        .line-anim {
          animation: lineAnim 2s ease-in-out forwards;
        }
        .line_one {
          position: absolute;
          right: 0;
          top: -300px;
          transform: translate(65%, 0) rotate(50deg);
          z-index: 6000;

          .line_path {
            stroke-dasharray: 1340px;
            stroke-dashoffset: 1340px;
          }
        }
        @media (max-width: 1800px) {
          .line_one {
            width: 450px;
          }
        }

        @media (max-width: 950px) {
          .line_one {
            width: 226px;
          }
        }

        @media (max-width: 667px) {
          .line_one {
            display: none;
          }
        }
        .reveal_wrap {
          width: 65%;
          margin: 0 auto;
          position: relative;

          @media (max-width: 1440px) {
            max-width: 1200px;
            width: 100%;
          }
        }
        .recenzia {
          background: #ffffff08;
          position: relative;
          border-radius: 24px;
          padding: 40px;

          @media (max-width: 667px) {
            padding: 25px 15px;
            text-align: left;
          }
        }
        .recenzia.active {
          width: 100%;
          display: block;
        }
      }
      .swiper-wrapper {
        padding-top: 50px;

        @media (max-width: 667px) {
          padding-top: 25px;
        }
      }
      .swiper-slide {
        opacity: 0.3;
        will-change: opacity;
        transition: opacity 0.35s ease-in-out;
        @media (max-width: 667px) {
          opacity: 1;
        }
      }

      .swiper-slide.swiper-slide-active {
        opacity: 1;
      }
      .columns {
        .is-5 {
          position: relative;
        }
      }
      .header {
        .headline {
          h2 {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.2em;
            text-transform: uppercase;
            color: #fff;
            display: inline-block;
            background: -webkit-linear-gradient(180deg, #f11341, #ffa5b7);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        .title {
          margin-top: 8px;
          margin-bottom: 70px;

          @media (max-width: 992px) {
            margin-bottom: 120px;
          }
          @media (max-width: 667px) {
            margin-bottom: 60px;
          }
          h2 {
            font-style: normal;
            font-weight: 200;
            font-size: 46px;
            line-height: 106.1%;
            color: #ffffff;
          }
        }
        @media (max-width: 1800px) {
          .title {
            h2 {
              font-size: 36px;
              line-height: 106.1%;
            }
          }
        }
        @media (max-width: 600px) {
          .headline {
            h2 {
              display: flex;
              justify-content: center;
            }
          }
          .title {
            h2 {
              display: flex;
              justify-content: center;
            }
          }
        }
      }
      .uvodzovky {
        display: none;
        position: absolute;
        top: -45px;
        left: 50px;
      }
      @media (max-width: 667px) {
        .uvodzovky {
          margin: 30px auto 0px auto;
          max-width: 50px;
          left: 25px;
        }
      }
      .animation_11 {
        transition: all 0.5s ease-in-out;
      }
      p.text {
        opacity: 1;
        width: 100%;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 1.65;
        letter-spacing: -0.015em;
        margin-bottom: 13px;
        color: #fff;

        @media (max-width: 950px) {
          line-height: 2;
        }
      }
      a.z_cele {
        opacity: 1;
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: 24px;
        letter-spacing: -0, 015em;
        text-decoration: underline;
        color: #fff;
      }
      .brandlogo {
        margin-top: 30px;
      }
      .meno {
        font-size: 18px;
        margin-top: 25px;
        font-style: normal;
        font-weight: 800;
        line-height: 24px;
        letter-spacing: -0, 015em;
        color: #fff;
      }
      .veduci {
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;
        letter-spacing: -0, 015em;
        color: #fff;
        opacity: 0.5;
      }
      @media (max-width: 1440px) {
        p.text {
          width: 100%;
          font-size: 20px;
          line-height: 32px;
        }
        a.z_cele {
          font-size: 14px;
          line-height: 20px;
        }
        .meno {
          font-size: 14px;
          line-height: 20px;
        }
        .veduci {
          font-size: 14px;
          line-height: 20px;
        }
      }
      @media (max-width: 1050px) {
        p.text {
          width: auto;
          font-size: 19px;
          line-height: 28px;
        }
      }
      @media (max-width: 600px) {
        p.text {
          width: 98%;
          margin: 0 auto auto;
          font-size: 17px;
          line-height: 28px;
          text-align: left;
        }
        a.z_cele {
          display: flex;
          justify-content: center;
          margin-top: 20px;
          text-align: left;
        }
        .meno {
          margin-top: 20px;
          text-align: left;
        }
        .veduci {
          text-align: left;
        }
      }
      .zisky {
        position: relative;
        width: 100%;
        height: 100%;
        @media (max-width: 950px) {
          height: 300px;
        }
        .zisk0 {
          position: absolute;
          bottom: 0px;
          right: 0;
          height: 217px;
          width: 440px;
          background-image: url("../../images/zisk0.svg");
          background-size: contain;
          background-repeat: no-repeat;
          box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.15);
          border-radius: 3px;
          transform: scale(0);
          @media (max-width: 1280px) {
            & {
              height: 170px;
              width: 335px;
              bottom: 5px;
              right: -40px;
            }
          }
          @media (max-width: 950px) {
            height: 150px;
            width: 80%;
            right: 0;
            bottom: 0;
          }
        }
        .zisk1 {
          bottom: 160px;
          right: 70px;
          position: absolute;
          height: 291px;
          width: 441px;
          background-image: url("../../images/zisk1.svg");
          background-size: contain;
          background-repeat: no-repeat;
          filter: drop-shadow(0px 24px 24px rgba(0, 0, 0, 0.25));
          border-radius: 3px;
          transform: scale(0);
          @media (max-width: 1280px) {
            & {
              top: 55px;
              left: 97px;
              height: 230px;
              width: 338px;
            }
          }
          @media (max-width: 950px) {
            top: 0;
            left: 0;
            right: 0;
            bottom: 40px;
            width: 76%;
            margin: auto;
          }
        }
        .zisk2 {
          right: 300px;
          bottom: 180px;
          position: absolute;
          height: 61px;
          width: 291px;
          background-image: url("../../images/zisk2.svg");
          background-size: contain;
          background-repeat: no-repeat;
          filter: drop-shadow(0px 24px 24px rgba(0, 0, 0, 0.25));
          transform: scale(0);
          border-radius: 3px;
          @media (max-width: 1280px) {
            & {
              left: 4px;
              top: 216px;
              height: 47px;
              width: 223px;
            }
          }
          @media (max-width: 950px) {
            left: 0;
            top: 128px;
            height: inherit;
            width: 180px;
          }
        }
        .zisk3 {
          bottom: 350px;
          right: 46px;
          position: absolute;
          height: 41px;
          width: 65px;
          background-image: url("../../images/zisk3.svg");
          box-shadow: 0px 24px 24px rgba(0, 0, 0, 0.25);
          border-radius: 3px;
          transform: scale(0);
          @media (max-width: 1280px) {
            & {
              top: 106px;
              right: 7px;
            }
          }
          @media (max-width: 1050px) {
            display: none;
          }
        }
        .zisk_animated {
          animation: zisk 0.7s ease-in-out;
          animation-fill-mode: forwards;
        }
      }
      .foto {
        position: relative;
        width: 500px;
        img {
          filter: drop-shadow(0px 24px 24px rgba(0, 0, 0, 0.25));
          transform: scale(0);
        }
        .zisk_animated {
          animation: zisk 0.7s ease-in-out;
          animation-fill-mode: forwards;
        }
      }
    }
    .black_wall {
      background-color: #000807;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      width: 110%;
      margin: auto;
      height: 100%;
      transform: translate(-5%);
      transition: all 1s ease-in-out;
      @media (max-width: 950px) {
        transform: translate(110%);
      }
      @media (max-width: 667px) {
        display: none;
      }
    }
    .black_wall:after {
      content: "";
      position: absolute;
      left: -1px;
      border-left: 150px solid #000;
      border-top: 660px solid transparent;
    }

    .flickity-ready .carousel-cell {
      height: 100%;
    }

    .carousel-cell {
      padding: 60px 0;
      margin: 0 20px;
      width: 25%;
      opacity: 0.3;
      min-height: 100%;
      display: flex;
      transition: opacity 0.5s ease-in-out;

      @media (max-width: 1440px) {
        width: 33%;
      }

      @media (max-width: 992px) {
        width: 52%;
      }

      .uvodzovky.is-active {
        display: none !important;
      }

      &.is-selected .uvodzovky {
        display: none;
      }

      &.is-selected .uvodzovky.is-active {
        display: block !important;
      }

      .text {
        font-size: 18px;
        line-height: 1.5;
      }

      &.is-selected {
        opacity: 1;
      }

      .recenzia {
        height: inherit;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      @media (max-width: 667px) {
        width: 92%;
        margin: 0 10px;
      }
    }
    .flickity-button {
      background: #fff;
      cursor: pointer;
      width: 72px;
      height: 72px;
      margin: 0 5px;
      border-radius: 24px;
      transition: all 0.35s ease-in-out;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        max-width: 15px;
        height: 15px;
        position: static;
        path {
          fill: #363636;
          transition: all 0.35s ease-in-out;
        }
      }

      &:hover,
      &:focus,
      &:active {
        background: #f11341;
        outline: none;
        svg {
          path {
            fill: #fff;
            transition: all 0.35s ease-in-out;
          }
        }
      }

      @media (max-width: 667px) {
        width: 50px;
        height: 50px;
      }
    }

    .flickity-prev-next-button.previous {
      top: -80px;
      left: auto;
      right: 380px;

      @media (max-width: 1024px) {
        right: 400px;
      }

      @media (max-width: 992px) {
        right: auto;
        left: 37%;
        top: -40px;
      }

      @media (max-width: 667px) {
        right: auto;
        top: 0;
        left: 40%;
      }

      @media (max-width: 375px) {
        left: 50%;
        top: 0;
        transform: translate(-120%, -50%);
      }
    }

    .flickity-prev-next-button.next {
      top: -80px;
      left: auto;
      right: 300px;

      @media (max-width: 1024px) {
        right: 315px;
      }

      @media (max-width: 992px) {
        right: auto;
        left: 50%;
        top: -40px;
      }

      @media (max-width: 667px) {
        top: 0;
        left: 50%;
        transform: translate(0, -50%);
      }
    }

    /* OLd code for arrow navigation */
    .reviews-buttons {
      position: absolute;
      top: 20px;
      right: 60px;
      display: flex;

      @media (max-width: 1440px) {
        right: 125px;
      }

      @media (max-width: 667px) {
        position: static;
        margin: 0 auto;
        padding: 20px 0;
        justify-content: center;
      }

      > div,
      button {
        border-radius: 50%;
        background: none;
        cursor: pointer;
        width: 65px;
        height: 65px;
        margin: 0 10px;
        border: 2px solid #363636;
        transition: all 0.35s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          max-width: 15px;
          height: 15px;
          path {
            fill: #363636;
            transition: all 0.35s ease-in-out;
          }
        }

        &:hover,
        &:active {
          background: #3fa9f5;
          border: 2px solid #3fa9f5;

          svg {
            path {
              fill: #fff;
              transition: all 0.35s ease-in-out;
            }
          }
        }
      }
    }
  }

  .hero--services {
    padding-bottom: 200px;

    @media (max-width: 950px) {
      padding-bottom: 100px;
    }
  }

  @media (max-width: 1440px) {
    .container:not(.is-max-desktop):not(.is-max-widescreen) {
      max-width: 1200px;
    }

    .container-block {
      max-width: 1200px;
    }

    .portfolio-actions {
      max-width: 1200px;
    }

    .portfolio-holder {
      max-width: 1200px;
      margin-bottom: 20px;
    }

    .portfolios .portfolio-head h2 {
      margin-top: 30px;
      margin-bottom: 10px;
    }
  }

  @media (max-width: 950px) {
    .container:not(.is-max-desktop):not(.is-max-widescreen) {
      max-width: 680px;
    }

    .portfolios .portfolio-head .head-action {
      margin-right: 0;
    }
  }
}

.hero--services {
  display: flex;
  justify-content: space-between;
  column-gap: 30px;
  overflow: hidden;

  @media (max-width: 950px) {
    .row {
      display: block;
    }
  }

  @media (max-width: 667px) {
    > span {
        display: none;
    }
}

  .service-status {
    font-weight: 600;
    font-size: 16px;
    position: relative;
    line-height: 1;
    padding-left: 15px;
    display: inline-block;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 6px;
      background: #00ff75;
      border-radius: 50%;
      width: 6px;
      height: 6px;
    }

    &--available {
      color: #00ff75;
    }
  }

  .hero__left,
  .hero__right {
    flex: 0 0 50%;
  }

  .hero__left {
    text-align: left;
    position: relative;
    z-index: 2000;

    @media (max-width: 950px) {
      text-align: center;
      margin-bottom: 50px;
    }

    opacity: 0;
    transform: translate(-50px, 0);
    transition: all 0.5s ease-in-out;

    h1 {
      font-size: 74px;
      line-height: 1;
      margin-bottom: 40px;

      @media (max-width: 1440px) {
        font-size: 62px;
        max-width: 80%;
        margin-bottom: 30px;
      }

      @media (max-width: 950px) {
        max-width: 100%;
      }

      @media (max-width: 667px) {
        font-size: 42px;
      }
    }

    p {
      line-height: 1.5;
      margin-bottom: 50px;

      @media (max-width: 1440px) {
        font-size: 18px;
        max-width: 95%;
        margin-left: 0;
        line-height: 1.65;
        margin-bottom: 30px;
      }

      @media (max-width: 950px) {
        max-width: 100%;
      }

      @media (max-width: 667px) {
        font-size: 16px;
      }
    }
  }

  .btn--cta {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;

    color: #fff;
    font-size: 16px;
    letter-spacing: 0.4px;
    font-weight: 700;
    text-transform: uppercase;
    background: #f4335b;
    padding: 25px 30px;
    line-height: 1;
    border-radius: 22px;

    @media (max-width: 1440px) {
      margin-top: 40px;
    }

    @media (max-width: 667px) {
      font-size: 14px;
    }

    span {
      transition: 0.35s ease-in-out;
      display: inline-block;
      margin-left: 10px;
      position: relative;
      z-index: 10;
      top: -4px;
      font-size: 21px;
    }

    &:hover {
      span {
        transform: rotate(45deg);
      }
    }
  }
}

.carousel--services {
  position: relative;
  z-index: 1;
  height: 520px;

  @media (max-width: 1440px) {
    height: 420px;
  }

  @media (max-width: 667px) {
    height: 320px;
  }

  .screen {
    opacity: 0;
    transform: translate(0, 100px);

    @media (max-width: 1440px) {
      max-width: 450px;
    }

    @media (max-width: 667px) {
      max-width: 320px;
    }
  }

  .carousel-service__content {
    opacity: 0;
  }

  .carousel-service {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;

    &.active {
      z-index: 5000;
      opacity: 1;
    }

    .graphics {
      position: absolute;
      will-change: transform;
    }

    &.le-coq {
      .watermelon-01 {
        top: 10%;
        right: -45%;
        transform: scale(0);
      }

      .watermelon-02 {
        top: 30%;
        right: 3%;
        transform: scale(0);
      }

      .watermelon-03 {
        bottom: -20%;
        right: -15%;
        transform: scale(0);

        @media (max-width: 667px) {
          max-width: 80px;
          right: -5%;
          bottom: 0;
        }
      }

      .watermelon-04 {
        top: 3%;
        left: 5%;
        transform: scale(0);
      }

      .pineapple-01 {
        top: 40%;
        right: -13%;
        transform: scale(0);

        @media (max-width: 667px) {
          max-width: 40px;
          top: 50%;
          right: 0;
        }
      }

      .lemon-01 {
        top: -70px;
        right: -10%;
        transform: scale(0);

        @media (max-width: 667px) {
          max-width: 30px;
          top: -10px;
          right: 0;
        }
      }

      .lemon-02 {
        right: -45%;
        bottom: -5%;
        transform: scale(0);
      }

      .lemon-03 {
        left: 40%;
        top: -20px;
        transform: scale(0);
      }
    }

    &.mnam {
      .pattern {
        right: -30%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;

        opacity: 0;
      }

      .stick {
        width: 168px;
        height: 183px;
        left: 54%;
        top: -75px;

        @media (max-width: 1440px) {
          left: 52%;
        }

        @media (max-width: 667px) {
          width: 98px;
          height: 113px;
          top: -40px;
          left: 55%;
        }

        transform: rotate(45deg);
      }
    }

    &.doffero {
      .screen {
        z-index: 150;
        position: relative;
      }
      .graphics {
        z-index: 149;
      }
      .line-01 {
        background: #f93c34;
        height: 90px;
        width: 180px;
        border-radius: 45px;
        right: -55%;
        top: 0;

        @media (max-width: 1440px) {
          right: -30%;
        }

        @media (max-width: 667px) {
          top: -15px;
        }

        transform: translate(-30px, 0);
      }
      .line-02 {
        background: rgba(#fff, 0.1);
        height: 90px;
        width: 119px;
        border-radius: 45px;
        right: -38%;
        top: 120px;
        transform: translate(30px, 0);

        @media (max-width: 1440px) {
          right: -30%;
        }
      }
      .line-03 {
        background: #f93c34;
        height: 90px;
        width: 180px;
        border-radius: 45px;
        left: 75%;
        bottom: 10%;

        transform: translate(50px, 0);
      }
      .line-04 {
        background: rgba(#fff, 0.1);
        height: 90px;
        width: 119px;
        border-radius: 45px;
        left: 93%;
        bottom: -12%;

        transform: translate(-100px, 0);
      }

      .line-01,
      .line-02,
      .line-03,
      .line-04 {
        opacity: 0;

        @media (max-width: 667px) {
          height: 60px;
        }
      }
    }

    &.ehm {
      .screen {
        position: relative;
        z-index: 100;
      }
      .check-graphics {
        top: 12%;
        right: -50%;

        @media (max-width: 1440px) {
          top: 0;
        }

        opacity: 0;
        transform: translate(-400px, 0);
      }
    }

    &.bonitax {
      .screen {
        position: relative;
        z-index: 100;
      }
      .line-chart {
        max-width: 1250px;
        bottom: -48%;
        right: -40%;
        height: 420px;
        object-fit: cover;
        object-position: right top;
        transform: scale(1.1);

        @media (max-width: 1440px) {
          bottom: -60%;
        }

        @media (max-width: 667px) {
          transform: scale(1);
          height: 320px;
        }

        opacity: 0;
        transform: translate(0, 200px);
      }
    }
  }
}

.carousel-pagination {
  margin-top: 40px;
  position: relative;
  z-index: 1500;

  @media (max-width: 667px) {
    margin-top: 20px;
  }
  ul {
    display: flex;
    justify-content: center;

    li {
      margin: 0 5px;

      &.active {
        button {
          background: #f11341;
        }
      }

      button {
        border: none;
        border-radius: 50%;
        width: 8px;
        height: 8px;
        padding: 0;
        background: #4a4a4a;
        cursor: pointer;
        transition: all 0.35s ease-in-out;

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
}

.content.content--services {
  padding-bottom: 0;

  @media (max-width: 667px) {
    padding-top: 60px;
  }

  .btn--cta {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    color: #fff;
    font-size: 16px;
    letter-spacing: 4%;
    font-weight: 700;
    text-transform: uppercase;
    background: #f4335b;
    padding: 25px 30px;
    line-height: 1;
    border-radius: 22px;

    @media (max-width: 667px) {
      font-size: 14px;
    }

    span {
      transition: 0.35s ease-in-out;
      display: inline-block;
      margin-left: 10px;
      position: relative;
      z-index: 10;
      top: -4px;
      font-size: 21px;
    }

    &:hover {
      span {
        transform: rotate(45deg);
      }
    }
  }

  .btn--bordered {
    background: transparent;
    border: 2px solid #fff;
    color: #fff;
    border-radius: 24px;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 18px 30px;
    line-height: 1;
    transition: 0.35s ease-in-out;

    &:hover {
      background: #fff;
      color: #181a1a;
    }
  }

  .btn--submit {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: transparent;
    height: 66px;
    margin: 15px 0;
    border-radius: 22px;
    font-weight: 600;
    border: 3px solid #f11341;
    text-transform: uppercase;
    transition: 0.35s ease-in-out;

    svg {
      margin-left: 10px;
      path {
        transition: 0.35s ease-in-out;
      }
    }

    &:hover {
      background: #f11341;
      color: #fff;

      svg path {
        fill: #fff;
      }
    }
  }

  .block-head {
    margin-bottom: 83px;
    text-align: center;

    @media (max-width: 667px) {
      margin-bottom: 40px;
    }

    &__subtitle {
      color: #f11341;
      text-transform: uppercase;
      margin-bottom: 15px;
      font-weight: 700;
      line-height: 1;
      letter-spacing: 1px;

      @media (max-width: 667px) {
        font-size: 12px;
        margin-bottom: 5px;
      }
    }

    &__title {
      color: #131414;
      font-size: 48px;
      line-height: 1.2;
      font-weight: 200;
      margin-top: 0;

      @media (max-width: 667px) {
        font-size: 34px;
      }

      &--light {
        color: #fff;
      }
    }

    &__text {
      color: #fff;
      margin-bottom: 0;
      font-size: 18px;
      font-weight: 300;

      @media (max-width: 950px) {
        margin-top: 30px;
        font-size: 17px;
      }
    }

    &--alignLeft {
      text-align: left;
    }
  }

  .block--services-items {
    padding-bottom: 40px;

    .services-items {
      display: flex;
      column-gap: 12px;
      margin-bottom: 48px;

      @media (max-width: 950px) {
        flex-wrap: wrap;
        justify-content: space-between;
      }

      @media (max-width: 667px) {
        margin-bottom: 20px;
      }

      .service-item {
        flex: 1;
        border: 1px solid #ddd;
        border-radius: 24px;
        padding: 36px;
        transition: all 0.35s ease-in-out;

        @media (max-width: 1440px) {
          padding: 30px 24px;
        }

        @media (max-width: 950px) {
          flex: 0 0 48%;
          margin-bottom: 20px;
        }

        @media (max-width: 667px) {
          flex: 0 0 100%;
        }

        .img-holder {
          padding: 20px;
          background: #f7f7f7;
          border-radius: 16px;
          display: inline-block;

          @media (max-width: 1440px) {
            padding: 16px;
            img {
              max-width: 85%;
            }
          }
        }

        &__title {
          font-size: 21px;
          color: #131414;
          margin-bottom: 12px;
          margin-top: 40px;
        }

        p {
          color: #8e8e8e;
          font-weight: 200;
          margin-bottom: 0;
        }

        &:hover {
          box-shadow: 0 5px 25px rgba(0, 0, 0, 0.15);
          border-color: transparent;

          img {
            opacity: 1;
          }
        }
      }
    }
  }

  .block--steps {
    background: #131414;
    padding: 78px 0 120px 0;

    @media (max-width: 667px) {
      padding-bottom: 40px;
    }

    .block-head {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      column-gap: 150px;

      @media (max-width: 950px) {
        display: block;
      }

      .head__left {
        flex: 0 0 420px;
      }
      .head__right {
        flex: 1;
      }

      &__title {
        margin-bottom: 0;
      }
    }

    .steps {
      display: flex;
      column-gap: 50px;

      @media (max-width: 950px) {
        flex-wrap: wrap;
        justify-content: space-between;
        column-gap: 0;
      }

      @media (max-width: 667px) {
        display: block;
      }

      &__item {
        flex: 0 0 22%;
        @media (max-width: 950px) {
          flex: 0 0 48%;
        }

        @media (max-width: 667px) {
          margin-bottom: 30px;
        }

        .item-number {
          color: rgba(255, 255, 255, 0.1);
          font-size: 104px;
          line-height: 1;
          font-weight: 700;
          margin-bottom: -70px;
          position: relative;
          z-index: -1;
        }

        .item-title {
          color: #f11341;
          font-weight: 500;
          line-height: 1.25;
          font-size: 20px;
          max-width: 90%;
        }

        .item-text {
          font-size: 16px;
          font-weight: 300;
          line-height: 1.5;
          color: #fff;
          margin-bottom: 0;
        }
      }
    }
  }

  .block--references {
    background: #181a1a;

    @media only screen and (-webkit-min-device-pixel-ratio: 2),
      only screen and (min--moz-device-pixel-ratio: 2),
      only screen and (-o-min-device-pixel-ratio: 2/1),
      only screen and (min-device-pixel-ratio: 2),
      only screen and (min-resolution: 192dpi),
      only screen and (min-resolution: 2dppx) {
      background: #191c1b;
    }

    .portfolios {
      padding-bottom: 60px;

      .btn--primary {
        border-radius: 22px;
      }

      .portfolio-actions .portfolio-buttons .portfolio__btn {
        border-radius: 22px;
      }
    }
  }

  .block--faq {
    background: #181a1a;
    padding: 60px 0 140px 0;

    @media only screen and (-webkit-min-device-pixel-ratio: 2),
      only screen and (min--moz-device-pixel-ratio: 2),
      only screen and (-o-min-device-pixel-ratio: 2/1),
      only screen and (min-device-pixel-ratio: 2),
      only screen and (min-resolution: 192dpi),
      only screen and (min-resolution: 2dppx) {
      background: #191c1b;
    }

    @media (max-width: 950px) {
      padding-bottom: 100px;
      .row {
        display: block;
      }
    }

    @media (max-width: 667px) {
      padding-bottom: 40px;
    }

    .block-head {
      margin-bottom: 48px;

      @media (max-width: 667px) {
        margin-bottom: 32x;
      }
    }

    .faq {
      &__item {
        margin-bottom: 24px;
      }

      .item-question {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding-bottom: 24px;
        color: #fff;
        font-size: 18px;
        font-weight: 300;
        line-height: 1.5;
        border-bottom: 1px solid #474747;
        position: relative;

        &:after {
          content: "";
          position: absolute;
          left: 0;
          bottom: -1px;
          width: 0;
          background: #f11341;
          height: 1px;
          transition: 0.35s ease-in-out;
        }

        &:hover {
          &:after {
            width: 100%;
          }

          .item-counter {
            color: #f11341;
          }
        }

        &.is-toggled {
          &:after {
            width: 100%;
          }

          .item-counter {
            color: #f11341;
          }
        }

        .item-counter {
          color: #474747;
          font-weight: 700;
          margin-right: 24px;
          font-size: 24px;
          transition: 0.35s ease-in-out;
        }
      }

      .item-answer {
        display: none;
        p {
          color: rgba(#fff, 0.5);
          font-weight: 300;
        }
      }
    }

    .column {
      position: relative;

      &:nth-child(1) {
        z-index: 1000;
      }

      @media (max-width: 950px) {
        &:nth-child(2) {
          display: none;
        }
      }
    }

    #heroVideo {
      max-width: 720px;
      background: transparent;
      display: block;
      transform: scale(1.15);
      position: absolute;
      left: 0;
      top: -20px;
    }
  }

  .block--contact {
    padding: 126px 0;
    background: url("../../images/services/web/contact-bg.png") no-repeat;
    background-position: left top;
    background-size: 50vw 100%;
    position: relative;

    @media (max-width: 950px) {
      background-size: 100% 46%;
      padding: 60px 0;
    }

    @media (max-width: 667px) {
      padding: 40px 0;
    }

    &:after {
      content: "";
      width: 50vw;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: linear-gradient(
        to right,
        rgba(242, 20, 64, 0.91) 0%,
        rgba(254, 159, 178, 0.37) 100%
      );

      @media (max-width: 950px) {
        width: 100%;
        height: 46%;
      }

      @media (max-width: 667px) {
        height: 50%;
      }
    }

    .row {
      align-items: center;

      @media (max-width: 950px) {
        display: block;
      }
    }

    > div {
      position: relative;
      z-index: 100;
    }

    .column--left {
      padding-right: 100px;

      @media (max-width: 667px) {
        padding-right: 15px;
      }
    }

    .column--right {
      padding-left: 100px;

      @media (max-width: 950px) {
        padding-left: 15px;
        padding-top: 60px;
      }
    }

    .contact-box {
      &__title {
        color: #fff;
        margin-bottom: 20px;
        margin-top: 0;
        line-height: 1.25;
        font-size: 40px;

        @media (max-width: 667px) {
          font-size: 30px;
        }
      }

      &__text {
        font-weight: 300;
        color: #fff;
        font-size: 24px;
      }

      &__data {
        border-top: 1px solid #fff;
        padding-top: 40px;
      }

      .data-ceo {
        display: flex;
        align-items: center;

        @media (max-width: 667px) {
          display: block;
          text-align: center;
        }

        .ceo-data {
          padding-left: 32px;
          color: #fff;

          @media (max-width: 667px) {
            padding-left: 0;
            margin-top: 25px;
          }
        }

        .ceo-name {
          line-height: 1;
          font-size: 24px;
          margin-bottom: 15px;
        }

        .ceo-contact {
          display: flex;
          align-items: center;

          a {
            color: #fff;
            &:hover {
              text-decoration: underline;
            }
          }

          @media (max-width: 667px) {
            justify-content: center;
          }
        }

        .separator {
          margin: 0 22px;
          width: 6px;
          height: 6px;
          background: rgba(#fff, 0.5);
          border-radius: 50%;
          display: inline-block;
        }
      }

      .data-text {
        margin-top: 24px;
        font-size: 16px;
        font-weight: 300;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 31px;

        @media (max-width: 667px) {
          display: block;
          text-align: center;

          .btn--bordered {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        p {
          flex: 1;
        }

        .btn--bordered {
          flex: 0 0 auto;
        }
      }
    }

    .contact-form {
      h2 {
        color: #181a1a;
        font-size: 32px;
        margin-bottom: 30px;
      }

      .form--contact {
        max-width: 530px;
      }

      .form-input {
        width: 100%;
        border: 1px solid #d6d6d6;
        height: 70px;
        border-radius: 22px;
        padding: 0 22px;
        color: #181a1a;
        font-weight: 400;

        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: #b9b9b9;
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          color: #b9b9b9;
        }
        &:-ms-input-placeholder {
          /* IE 10+ */
          color: #b9b9b9;
        }
        &:-moz-placeholder {
          /* Firefox 18- */
          color: #b9b9b9;
        }
      }

      textarea.form-input {
        padding-top: 23px;
        min-height: 190px;
      }
    }
  }
}
