.main-content {
  background: #131414;
  padding: 132px 0 150px 0;

  @media (max-width: 667px) {
    padding: 40px 0 50px 0;
  }

  .container {
    max-width: 1200px;
    padding: 0 15px;
  }

  .row {
    display: flex;
  }
}

.hero {
  margin: 100px 0;
  padding: 0 20px;
  text-align: center;

  h1 {
    font-size: 74px;
    color: #fff;

    @media (max-width: 667px) {
      font-size: 42px;
      margin-bottom: 20px;
    }
  }

  p {
    color: rgba(255, 255, 255, 0.4);
    font-size: 30px;

    @media (max-width: 667px) {
      font-size: 18px;
    }
  }
}

.page-contact {
  overflow: hidden;
  header {
    .AU_animation1 {
      transform: translateX(-650px);
    }
    .AU_animation2 {
      transition: all 2s ease-in-out;
      opacity: 0;
    }
    position: relative;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 82px 0 0 0;
      width: 1700px;
      margin: 0 auto;
      position: relative;
      z-index: 2000;
      .text,
      a {
        transition: 0.5s ease-in-out all;

        @media (max-width: 667px) {
          img {
            max-width: 125px;
          }
        }
      }
      @media (max-width: 1700px) {
        width: 90%;
      }
      @media (max-width: 1300px) {
        width: 90%;
        padding-top: 45px;
      }
      @media (max-width: 900px) {
        width: 90%;
        padding: 30px 0 30px 0;
      }
      @media (max-width: 768px) {
        .line_one {
          width: 450px;
        }
      }
      @media (max-width: 720px) {
        width: 100%;
      }
      @media (max-width: 500px) {
        display: block;
      }
      .left {
        color: #fff;
        font-weight: bold;
        height: 50px;
        cursor: pointer;
        @media (max-width: 992px) {
          padding-left: 15px;
        }
        span {
          font-weight: 200;
          display: block;
        }
      }
      .right {
        color: #fff;
        font-weight: bold;
        cursor: pointer;
        @media (max-width: 500px) {
          margin-top: 20px;
        }
      }
    }
    .header-container {
      width: 1240px;
      margin: 90px auto 0 auto;
      @media (max-width: 1450px) {
        width: 1000px;
      }
      @media (max-width: 1240px) {
        width: 85%;
      }
      @media (max-width: 1050px) {
        margin-top: 50px;
      }
      @media (max-width: 900px) {
        width: 90%;
      }

      @media (max-width: 950px) {
        & {
          margin: 0 auto;
          text-align: center;
          width: 95%;
          padding-left: 0px;
        }
      }
      .service {
        color: $headline;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        font: {
          style: normal;
          weight: 600;
          size: 14px;
        }
        line-height: 18px;
      }
      .text {
        width: 638px;
        @media (max-width: 900px) {
          width: 90%;
          margin: 15px auto 0 auto;
        }
        h1 {
          margin: {
            top: 15px;
          }
          color: #fff;
          font: {
            style: normal;
            weight: 600;
            size: 52px;
          }
          line-height: 59px;
        }
      }
      @media (max-width: 1440px) {
        .text h1 {
          font: {
            size: 32px;
          }
          line-height: 39px;
        }
      }
      @media (max-width: 950px) {
      }
    }
    .header-container_2 {
      margin: {
        top: 92px;
        left: auto;
        right: auto;
      }
      width: 1240px;
      @media (max-width: 1450px) {
        width: 1000px;
      }
      @media (max-width: 1240px) {
        width: 85%;
      }
      @media (max-width: 900px) {
        width: 90%;
      }
      .grid {
        display: flex;
        @media (max-width: 720px) {
          display: block;
        }
        .header_box {
          width: 390px;
          margin-right: 250px;
          margin-bottom: 49px;
          @media (max-width: 1050px) {
            margin-right: 80px;
          }
          @media (max-width: 720px) {
            margin: 60px auto 20px auto;
            width: 90%;
          }
          h3 {
            margin: {
              bottom: 25px;
            }
            width: 275px;
            color: #fff;
            font: {
              style: normal;
              weight: 600;
              size: 30px;
            }
            line-height: 38px;
            @media (max-width: 720px) {
              margin-bottom: 15px;
              width: 100%;
            }
          }
          p {
            @media (max-width: 900px) {
              width: 95%;
              margin: 0 auto;
            }
            color: #d1d5db;
            font: {
              style: normal;
              weight: 400;
              size: 16px;
            }
            line-height: 29px;
          }
        }
      }
      @media (max-width: 720px) {
        text-align: center;
      }
    }
    svg.header_line {
      position: absolute;
      top: -50px;
      z-index: 1;
      .line {
        stroke-dasharray: 8500px;
        stroke-dashoffset: 8500px;
      }
      @media (max-width: 1700px) {
        bottom: -410px;
        left: -40px;
      }
      @media (max-width: 1500px) {
        width: 15%;
        bottom: -437px;
      }
      @media (max-width: 1100px) {
        width: 23%;
      }
      @media (max-width: 600px) {
        width: 34%;
      }
      @media (max-width: 330px) {
        width: 50%;
      }
    }
    svg.header_line.right {
      position: absolute;
      top: 50px;
      right: 0px;
      transform-origin: 50% 50%;
      transform: rotate(-180deg);
      left: auto;
      z-index: 1;
      .line {
        stroke-dasharray: 8500px;
        stroke-dashoffset: 8500px;
      }

      @media (max-width: 1100px) {
        width: 23%;
      }
      @media (max-width: 600px) {
        width: 34%;
        top: 0;
      }
      @media (max-width: 330px) {
        width: 50%;
      }
    }
  }

  .section2 {
    background-color: #f8f8f8;
    padding: 69px 0;
    position: relative;
    z-index: 2;
    @media (max-width: 620px) {
      padding: 25px 0;
    }
    .wall {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000807;
      z-index: 5;
      transform: translateX(0);
      transition: all 2s ease-in-out;
      &:after {
        content: "";
        position: absolute;
        left: -179px;
        border-right: 180px solid #000807;
        border-top: 300px solid transparent;
        @media (max-width: 721px) {
          left: 0px;
        }
      }
    }
    .brands-container {
      width: 1100px;
      margin: 0 auto;
      @media (max-width: 1100px) {
        width: 85%;
      }
      @media (max-width: 900px) {
        width: 90%;
      }
      .first_img_col {
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
          margin-bottom: 50px;
          @media (max-width: 720px) {
            width: 150px;
          }
        }
        @media (max-width: 720px) {
          display: grid;
          grid-template-columns: 2fr 2fr;
          grid-gap: 31px;
          margin: 0 auto;
          align-items: center;
          text-align: center;
          width: 100%;
        }
      }
      .second_img_col {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 40px;
        img {
          @media (max-width: 720px) {
            width: 150px;
          }
        }
        @media (max-width: 720px) {
          display: grid;
          grid-template-columns: 2fr 2fr;
          grid-gap: 60px;
          margin: 0 auto;
          align-items: center;
          text-align: center;
          width: 100%;
        }
      }
      @media (max-width: 720px) {
        padding: 10px;
        margin: 0 auto;
      }
    }
  }
  .section3 {
    .AU_animation3 {
      transition: all 1.5s ease-in-out;
      transform: translateX(-1500px);
    }
    padding-top: 81px;
    padding-bottom: 129px;
    background-color: #ffffff;
    position: relative;
    @media (max-width: 900px) {
      padding-bottom: 40px;
    }
    .aboutus-container {
      width: 1240px;
      margin: 0 auto;
      @media (max-width: 1300px) {
        width: 85%;
      }
      @media (max-width: 950px) {
        margin: 0 auto;
        text-align: center;
        width: 95%;
        padding-left: 0px;
      }
      @media (max-width: 900px) {
        width: 90%;
      }
      .service {
        color: $headline;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        font: {
          style: normal;
          weight: 600;
          size: 14px;
        }
        line-height: 18px;
      }
      .text {
        width: 638px;
        @media (max-width: 900px) {
          width: 95%;
          margin: 15px auto 0 auto;
        }
        h1 {
          margin: {
            top: 15px;
            bottom: 40px;
          }
          color: #333333;
          font: {
            style: normal;
            weight: 400;
            size: 46px;
          }
          line-height: 59px;
        }
      }
      @media (max-width: 1440px) {
        .text h1 {
          font: {
            size: 32px;
          }
          line-height: 39px;
        }
      }
      @media (max-width: 950px) {
        .text h1 {
          margin-top: 3px;
          font-size: 22px;
          line-height: 26px;
        }
      }
    }
    .first-p {
      width: 1240px;
      color: #333333;
      margin: 0 auto;
      @media (max-width: 1300px) {
        width: 85%;
      }
      @media (max-width: 950px) {
        margin: 0 auto;
        margin-top: 10px;
        width: 80%;
      }
      p {
        width: 600px;
        font: {
          style: normal;
          weight: 300;
          size: 20px;
        }
        line-height: 31px;
        @media (max-width: 950px) {
          & {
            margin: 0 auto;
            text-align: center;
            width: 100%;
          }
        }
      }
    }
    .second-p {
      width: 1240px;
      margin: 25px auto 61px auto;
      color: #333333;
      @media (max-width: 1300px) {
        width: 85%;
      }
      @media (max-width: 950px) {
        margin: 25px auto 60px auto;
        width: 85%;
      }
      p {
        span {
          font: {
            style: normal;
            weight: 900;
            size: 20px;
          }
        }
        line-height: 31px;
        width: 600px;
        font: {
          style: normal;
          weight: 300;
          size: 20px;
        }
        @media (max-width: 950px) {
          & {
            margin: 0 auto;
            text-align: center;
            width: 100%;
          }
        }
      }
    }
    .heading-what {
      width: 1240px;
      margin: 0 auto;
      @media (max-width: 1300px) {
        width: 85%;
      }
      @media (max-width: 900px) {
        width: 90%;
      }
      h3 {
        color: #333333;
        font: {
          style: normal;
          weight: 800;
          size: 24px;
        }
        line-height: 31px;
        @media (max-width: 950px) {
          & {
            margin: 0 auto;
            text-align: center;
            width: 100%;
          }
        }
      }
    }
    .box-container {
      display: flex;
      width: 1240px;
      margin: 0 auto;
      margin-top: 15px;
      @media (max-width: 1300px) {
        width: 85%;
      }
      @media (max-width: 900px) {
        width: 350px;
      }
      @media (max-width: 330px) {
        width: 290px;
      }
      .boxes {
        transform: translateY(500px);
        padding: 32px;
        width: 386px;
        margin-right: 32px;
        border: 1px solid #f2f2f2;
        box-sizing: border-box;
        border-radius: 6px;
        .text {
          margin-top: 21px;
          font: {
            style: normal;
            weight: 300;
            size: 16px;
          }
          line-height: 28px;
        }
        .icon {
          img {
            width: 32px;
            max-width: 32px;
          }
        }
        @media (max-width: 1300px) {
          width: 300px;
        }
        @media (max-width: 950px) {
          width: 95%;
          text-align: center;
          margin: 0 auto;
          padding: 20px 25px;
        }
        @media (max-width: 900px) {
          margin-bottom: 30px;
        }
      }
      @media (max-width: 950px) {
        display: block;
      }
    }
    svg.aboutus_line {
      position: absolute;
      bottom: -500px;
      right: 0;
      z-index: 1;
      .line {
        stroke-dasharray: 8500px;
        stroke-dashoffset: 8500px;
      }
      @media (max-width: 1700px) {
        right: -130px;
      }
      @media (max-width: 1500px) {
        right: -180px;
      }
      @media (max-width: 1300px) {
        bottom: -740px;
      }
      @media (max-width: 1050px) {
        bottom: -600px;
      }
      @media (max-width: 500px) {
        width: 145%;
        bottom: -757px;
      }
    }
  }
  .section7 {
    position: relative;
    min-height: 660px;
    width: 100%;
    @media (max-width: 950px) {
      height: auto;
    }
    .bg {
      width: 100%;
      height: 100%;
      background-color: #181a1a;
      padding-top: 90px;
      padding-bottom: 150px;

      @media (max-width: 667px) {
        padding-bottom: 40px;
      }

      .recenzie {
        width: 100%;
        @media (max-width: 950px) {
          width: 100%;
          padding-bottom: 80px;
          text-align: center;
        }
        .reveal_wrap {
          width: 65%;
          margin: 0 auto;
          position: relative;
        }
        .recenzia {
          background: rgba(255, 255, 255, 0.03);
          position: relative;
          padding: 62px 60px;

          @media (max-width: 667px) {
            padding: 25px 15px;
            text-align: left;
          }
        }
        .recenzia.active {
          width: 100%;
          display: block;
        }
      }
      .swiper-wrapper {
        padding-top: 50px;

        @media (max-width: 667px) {
          padding-top: 25px;
        }
      }
      .swiper-slide {
        opacity: 0.3;
        will-change: opacity;
        transition: opacity 0.35s ease-in-out;
        @media (max-width: 667px) {
          opacity: 1;
        }
      }

      .swiper-slide.swiper-slide-active {
        opacity: 1;
      }
      .columns {
        .is-5 {
          position: relative;
        }
      }
      .header {
        .headline {
          h2 {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.2em;
            text-transform: uppercase;
            color: #fff;
          }
        }
        .title {
          margin-top: 8px;
          margin-bottom: 70px;

          @media (max-width: 992px) {
            margin-bottom: 120px;
          }
          @media (max-width: 667px) {
            margin-bottom: 60px;
          }
          h2 {
            font-style: normal;
            font-weight: 200;
            font-size: 46px;
            line-height: 106.1%;
            color: #ffffff;
          }
        }
        @media (max-width: 1800px) {
          .title {
            h2 {
              font-size: 36px;
              line-height: 106.1%;
            }
          }
        }
        @media (max-width: 600px) {
          .headline {
            h2 {
              display: flex;
              justify-content: center;
            }
          }
          .title {
            h2 {
              display: flex;
              justify-content: center;
            }
          }
        }
      }
      .uvodzovky {
        display: none;
        position: absolute;
        top: -45px;
        left: 50px;
      }
      @media (max-width: 667px) {
        .uvodzovky {
          margin: 30px auto 0px auto;
          max-width: 50px;
          left: 25px;
        }
      }
      .animation_11 {
        transition: all 0.5s ease-in-out;
      }
      p.text {
        opacity: 1;
        width: 100%;
        font-size: 24px;
        font-style: normal;
        font-weight: 200;
        line-height: 44px;
        letter-spacing: -0, 015em;
        margin-bottom: 13px;
        margin-top: 11px;
        color: #fff;
      }
      a.z_cele {
        opacity: 1;
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: 24px;
        letter-spacing: -0, 015em;
        text-decoration: underline;
        color: #fff;
      }
      .brandlogo {
        margin-top: 30px;
      }
      .meno {
        font-size: 18px;
        margin-top: 25px;
        font-style: normal;
        font-weight: 800;
        line-height: 24px;
        letter-spacing: -0, 015em;
        color: #fff;
      }
      .veduci {
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;
        letter-spacing: -0, 015em;
        color: #fff;
        opacity: 0.5;
      }
      @media (max-width: 1440px) {
        p.text {
          width: 100%;
          font-size: 22px;
          line-height: 36px;
        }
        a.z_cele {
          font-size: 14px;
          line-height: 20px;
        }
        .meno {
          font-size: 14px;
          line-height: 20px;
        }
        .veduci {
          font-size: 14px;
          line-height: 20px;
        }
      }
      @media (max-width: 1050px) {
        p.text {
          width: auto;
          font-size: 19px;
          line-height: 28px;
        }
      }
      @media (max-width: 600px) {
        p.text {
          width: 98%;
          margin: 0 auto auto;
          font-size: 17px;
          line-height: 28px;
          text-align: left;
        }
        a.z_cele {
          display: flex;
          justify-content: center;
          margin-top: 20px;
          text-align: left;
        }
        .meno {
          margin-top: 20px;
          text-align: left;
        }
        .veduci {
          text-align: left;
        }
      }
      .zisky {
        position: relative;
        width: 100%;
        height: 100%;
        @media (max-width: 950px) {
          height: 300px;
        }
        .zisk0 {
          position: absolute;
          bottom: 0px;
          right: 0;
          height: 217px;
          width: 440px;
          background-image: url("../../images/zisk0.svg");
          background-size: contain;
          background-repeat: no-repeat;
          box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.15);
          border-radius: 3px;
          transform: scale(0);
          @media (max-width: 1280px) {
            & {
              height: 170px;
              width: 335px;
              bottom: 5px;
              right: -40px;
            }
          }
          @media (max-width: 950px) {
            height: 150px;
            width: 80%;
            right: 0;
            bottom: 0;
          }
        }
        .zisk1 {
          bottom: 160px;
          right: 70px;
          position: absolute;
          height: 291px;
          width: 441px;
          background-image: url("../../images/zisk1.svg");
          background-size: contain;
          background-repeat: no-repeat;
          filter: drop-shadow(0px 24px 24px rgba(0, 0, 0, 0.25));
          border-radius: 3px;
          transform: scale(0);
          @media (max-width: 1280px) {
            & {
              top: 55px;
              left: 97px;
              height: 230px;
              width: 338px;
            }
          }
          @media (max-width: 950px) {
            top: 0;
            left: 0;
            right: 0;
            bottom: 40px;
            width: 76%;
            margin: auto;
          }
        }
        .zisk2 {
          right: 300px;
          bottom: 180px;
          position: absolute;
          height: 61px;
          width: 291px;
          background-image: url("../../images/zisk2.svg");
          background-size: contain;
          background-repeat: no-repeat;
          filter: drop-shadow(0px 24px 24px rgba(0, 0, 0, 0.25));
          transform: scale(0);
          border-radius: 3px;
          @media (max-width: 1280px) {
            & {
              left: 4px;
              top: 216px;
              height: 47px;
              width: 223px;
            }
          }
          @media (max-width: 950px) {
            left: 0;
            top: 128px;
            height: inherit;
            width: 180px;
          }
        }
        .zisk3 {
          bottom: 350px;
          right: 46px;
          position: absolute;
          height: 41px;
          width: 65px;
          background-image: url("../../images/zisk3.svg");
          box-shadow: 0px 24px 24px rgba(0, 0, 0, 0.25);
          border-radius: 3px;
          transform: scale(0);
          @media (max-width: 1280px) {
            & {
              top: 106px;
              right: 7px;
            }
          }
          @media (max-width: 1050px) {
            display: none;
          }
        }
        .zisk_animated {
          animation: zisk 0.7s ease-in-out;
          animation-fill-mode: forwards;
        }
      }
      .foto {
        position: relative;
        width: 500px;
        img {
          filter: drop-shadow(0px 24px 24px rgba(0, 0, 0, 0.25));
          transform: scale(0);
        }
        .zisk_animated {
          animation: zisk 0.7s ease-in-out;
          animation-fill-mode: forwards;
        }
      }
    }
    .black_wall {
      background-color: #000807;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      width: 110%;
      margin: auto;
      height: 100%;
      transform: translate(-5%);
      transition: all 1s ease-in-out;
      @media (max-width: 950px) {
        transform: translate(110%);
      }
      @media (max-width: 667px) {
        display: none;
      }
    }
    .black_wall:after {
      content: "";
      position: absolute;
      left: -1px;
      border-left: 150px solid #000;
      border-top: 660px solid transparent;
    }

    .carousel-cell {
      padding: 60px 0;
      margin: 0 20px;
      width: 50%;

      .uvodzovky.is-active {
        display: none !important;
      }

      &.is-selected .uvodzovky {
        display: none;
      }

      &.is-selected .uvodzovky.is-active {
        display: block !important;
      }

      @media (max-width: 667px) {
        width: 92%;
        margin: 0 10px;
      }
    }
    .flickity-button {
      border-radius: 50%;
      background: none;
      cursor: pointer;
      width: 65px;
      height: 65px;
      margin: 0 10px;
      border: 2px solid #363636;
      transition: all 0.35s ease-in-out;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        max-width: 15px;
        height: 15px;
        position: static;
        path {
          fill: #363636;
          transition: all 0.35s ease-in-out;
        }
      }

      &:hover,
      &:active {
        background: #3fa9f5;
        border: 2px solid #3fa9f5;
        box-shadow: 0px 20px 34px rgba(63, 169, 245, 0.3);

        @media (max-width: 667px) {
          box-shadow: 0px 5px 20px rgba(63, 169, 245, 0.15);
        }

        svg {
          path {
            fill: #fff;
            transition: all 0.35s ease-in-out;
          }
        }
      }

      @media (max-width: 667px) {
        width: 50px;
        height: 50px;
      }
    }

    .flickity-prev-next-button.previous {
      top: -80px;
      left: auto;
      right: 500px;

      @media (max-width: 1024px) {
        right: 400px;
      }

      @media (max-width: 992px) {
        right: auto;
        left: 40%;
        top: -40px;
      }

      @media (max-width: 667px) {
        right: auto;
        top: 0;
        left: 31%;
      }
    }

    .flickity-prev-next-button.next {
      top: -80px;
      left: auto;
      right: 415px;

      @media (max-width: 1024px) {
        right: 315px;
      }

      @media (max-width: 992px) {
        right: auto;
        left: 50%;
        top: -40px;
      }

      @media (max-width: 667px) {
        top: 0;
        right: auto;
        left: 51%;
      }
    }

    /* OLd code for arrow navigation */
    .reviews-buttons {
      position: absolute;
      top: 20px;
      right: 60px;
      display: flex;

      @media (max-width: 1440px) {
        right: 125px;
      }

      @media (max-width: 667px) {
        position: static;
        margin: 0 auto;
        padding: 20px 0;
        justify-content: center;
      }

      > div,
      button {
        border-radius: 50%;
        background: none;
        cursor: pointer;
        width: 65px;
        height: 65px;
        margin: 0 10px;
        border: 2px solid #363636;
        transition: all 0.35s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          max-width: 15px;
          height: 15px;
          path {
            fill: #363636;
            transition: all 0.35s ease-in-out;
          }
        }

        &:hover,
        &:active {
          background: #3fa9f5;
          border: 2px solid #3fa9f5;
          box-shadow: 0px 20px 34px rgba(63, 169, 245, 0.3);

          @media (max-width: 667px) {
            box-shadow: 0px 5px 20px rgba(63, 169, 245, 0.15);
          }

          svg {
            path {
              fill: #fff;
              transition: all 0.35s ease-in-out;
            }
          }
        }
      }
    }
  }

  .contact-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1130px;
    margin: 0 auto;

    > div {
      flex: 1;
    }
  }

  .contact-box {
    padding: 40px;
    background: rgba(#fff, 0.03);
    color: #fff;
    max-width: 392px;

    @media (max-width: 667px) {
      margin-bottom: 40px;
    }

    .person {
      .name {
        font-size: 24px;
        font-weight: 600;
        display: flex;
        align-items: center;
      }
      .position {
        background: -webkit-linear-gradient(180deg, #f11341, #ffa5b7);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 16px;
        font-weight: 600;
      }
      .divider {
        font-weight: 400;
        margin: 0 15px;
        color: #464646;
      }
    }

    .person-contact {
      margin: 12px 0 22px 0;

      a {
        display: flex;
        align-items: center;
        font-size: 18px;
        color: #fff;
        line-height: 2;
        transition: all 0.35s ease-in-out;

        svg {
          margin-right: 10px;
        }
        &:hover {
          color: #ef3232;
        }
      }
    }

    .company-info {
      p {
        line-height: 1.6;
        font-size: 16px;
        &:first-child {
          margin-bottom: 20px;
        }
        strong {
          color: #fff;
        }
      }
    }

    .social-list {
      display: flex;
      margin-top: 22px;
      li {
        margin-right: 16px;
      }
    }
  }
} // ./contact

.form--contact {
  max-width: 604px;
  width: 100%;

  input[type="checkbox"],
  input[type="radio"] {
    -webkit-appearance: none;
    appearance: none;
    visibility: hidden;
    height: 0;
    width: 0;
    opacity: 0;
    padding: 0;
    margin: 0;
  }

  input[type="checkbox"] + label,
  input[type="radio"] + label {
    line-height: 1;
    cursor: pointer;
  }

  input[type="checkbox"] + label:before {
    content: "";
    border-radius: 3px;
    display: inline-block;
    border: 1px solid rgba(255, 255, 255, 0.2);
    width: 21px;
    height: 21px;
    margin-right: 15px;
    position: relative;
    top: 5px;
  }

  input[type="checkbox"]:checked + label:after {
    content: "";
    width: 12px;
    height: 12px;
    display: block;
    background: #ef3232;
    position: absolute;
    z-index: 10;
    left: 5px;
    top: 10px;
  }

  input[type="radio"] + label:before {
    content: "";
    border-radius: 50px;
    display: inline-block;
    border: 1px solid rgba(255, 255, 255, 0.2);
    width: 30px;
    height: 30px;
    margin-right: 15px;
    position: relative;
    top: 9px;
  }

  input[type="radio"]:checked + label:after {
    content: "";
    width: 14px;
    height: 14px;
    display: block;
    border-radius: 50%;
    background: #ef3232;
    position: absolute;
    z-index: 10;
    left: 8px;
    top: 17px;
  }

  .radio-button,
  .checkbox-holder {
    display: flex;
    align-items: center;
    position: relative;

    @media (max-width: 667px) {
      flex-wrap: wrap;
    }
  }

  .input-holder {
    position: relative;
  }

  .form-label {
    color: #fff;
    position: absolute;
    margin: 24px;
    z-index: -1;
    transform-origin: 0 0;
    transition: all 0.35s ease-in-out;

    @media (max-width: 667px) {
      margin: 17px;
    }

    small {
      color: rgba(207, 211, 219, 0.2);
    }

    &.is-smaller {
      transform: translate(-15px, -20px) scale(0.7);

      @media (max-width: 667px) {
        transform: translate(-10px, -14px) scale(0.65);
      }
    }
  }

  .red-sign {
    color: #ef3232;
  }

  .form-label--base {
    color: #fff;
    white-space: nowrap;
  }

  a {
    display: inline-block;
    line-height: 1.2;
    color: #ef3232;
    text-decoration: underline;
    transition: all 0.35s ease-in-out;

    &:hover {
      text-decoration: none;
    }
  }

  .checkbox-holder a {
    margin-left: 5px;
    margin-top: 5px;
    @media (max-width: 667px) {
      display: block;
      margin-top: 10px;
    }
  }

  .form-group {
    margin-bottom: 14px;
  }

  .form-row {
    display: flex;
    align-items: center;
    margin: 5px 0 35px 0;

    @media (max-width: 667px) {
      display: block;
    }
  }

  .form-control {
    background: transparent;
    color: #fff;
    width: 100%;
    padding: 0 24px;
    height: 70px;
    border-radius: 3px;
    border: 1px solid rgba(255, 255, 255, 0.2);

    @media (max-width: 667px) {
      padding: 0 15px;
      height: 60px;
    }
  }

  textarea.form-control {
    min-height: 226px;
    padding: 24px;

    @media (max-width: 667px) {
      padding: 15px;
    }
  }

  .radio-button {
    margin-right: 30px;
    min-height: 30px;

    @media (max-width: 667px) {
      margin-bottom: 15px;
      margin-right: 0;
    }

    label {
      color: #fff;
    }
  }

  .btn-send {
    width: 100%;
    border: none;
    color: #fff;
    text-transform: uppercase;
    height: 66px;
    position: relative;
    margin-top: 15px;
    background: linear-gradient(
      to right,
      rgba(255, 165, 183, 1) 0%,
      rgba(241, 19, 63, 1) 100%
    );

    &:hover:before {
      opacity: 0;
    }

    span {
      position: relative;
      z-index: 100;
    }

    &:before {
      content: "";
      position: absolute;
      width: calc(100% - 6px);
      height: calc(100% - 6px);
      left: 3px;
      top: 3px;
      background: #131414;
      transition: all 0.35s ease-in-out;
    }
  }
}
